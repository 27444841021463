import React, { useState } from 'react';
import { useMaterialTailwindController } from '../../../context';
import { Dialog, DialogContent } from '@mui/material';
import { Button, DialogFooter, DialogHeader } from '@material-tailwind/react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getAdmins } from '../../../state/app.state';
import $service from '../../../services/app.service';
import { FileInput, Label } from 'flowbite-react';

function AddPartner() {
  const { discharge, controller } = useMaterialTailwindController();
  const { importPartner } = controller;
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  let payload: Array<Partial<any>> = [];

  const onSubmit = async () => {
    try {
      if (payload.length <= 0) {
        throw Error('Please import csv file');
      }
      setLoading(true);
      const response = await $service.partnerBulk(payload);
      console.log(payload);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      discharge({ type: 'IMPORT_PARTNER', importPartner: false });
      toast(response.message);
      dispatch(getAdmins());
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };



  const upload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.value = '';
    input.onchange = (e) => {
      const file = (e.target as any)?.files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        try {
          const _p = [
            'first_name',
            'last_name',
            'mobile',
            'company_name',
            'region',
            'district',
            'digital_address',
            'locationAddress',
          ];
          const res = fileReader.result as string;
          const data: any = res
            .split('\n')
            .map((d) => {
              const values = [];
              let inQuote = false;
              let currentValue = '';

              for (const char of d) {
                if (char === ',' && !inQuote) {
                  values.push(currentValue.trim());
                  currentValue = '';
                } else if (char === '"') {
                  inQuote = !inQuote;
                } else {
                  currentValue += char;
                }
              }

              if (currentValue.trim()) {
                values.push(currentValue.trim());
              }

              return values;
            })
            .filter((_, i) => i > 0)
            .filter((d) => d.length > 1);

          data.forEach((d: any[]) => {
            let partner: any = {};
            d.forEach((_d, i) => {
              _d = (_d as string)?.replace(/['"]+/g, '');
              partner[_p[i]] = _d;
            });

            payload.push(partner);
          });
        } catch (err) {
          console.error(err);
        }
      };
      fileReader.readAsText(file);
    };

 // Append the input to the body and trigger a click
 document.body.appendChild(input);
 input.click();

 // Remove the input from the body after the click
 document.body.removeChild(input);
  };

  const handleOpen = () =>
    discharge({ type: 'IMPORT_PARTNER', importPartner: false });

  return (
    <Dialog open={importPartner} onClose={handleOpen}>
      <DialogHeader className='text-lg'>Import Partners using CSV</DialogHeader>
      <DialogContent sx={{ width: '500px' }}>
        <div
          className='flex items-center justify-center w-full'
          onClick={upload}
        >
          <div className='max-w-md' id='fileUpload'>
            <div className='mb-2 block'>
              <Label htmlFor='file' value='Upload file' />
            </div>
            <FileInput
              helperText='A profile picture is useful to confirm your are logged into your account'
              id='file'
            />
          </div>

          {/* <label
            id="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6"   >
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
               
              </p>
            </div>
          </label>
          <input
                        id='dropzone-file'
                        type='file'
                        className='hidden'
                        onChange={(e) => e.target.files && upload(e.target.files[0])}
                      /> */}
        </div>
      </DialogContent>
      <DialogFooter>
        <Button
          variant='filled'
          color='blue-gray'
          className='mx-1'
          disabled={loading}
          type='submit'
          onClick={onSubmit}
        >
          Submit
        </Button>

        <Button
          variant='text'
          color='red'
          onClick={handleOpen}
          className='mr-1'
        >
          Cancel
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default AddPartner;

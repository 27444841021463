import {
  Button,
  DialogFooter,
  DialogHeader,
  Input,
  Option,
  Typography,
} from '@material-tailwind/react';
import React, { useState, useEffect } from 'react';
import { useMaterialTailwindController } from '../../../context';
import { Dialog, DialogContent } from '@mui/material';
import $service from '../../../services/app.service';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import {  IProductsShop } from '../../../models/app.model';
import {  getShops } from '../../../state/app.state';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../state/app.reducer';

const AddProductShop = ({selectedProductIds}:any) => {
  const { discharge, controller } = useMaterialTailwindController();
  const [loading, setLoading] = useState<boolean>(false);
  const { addProductToShop } = controller;
  const dispatch = useDispatch();
  const { shops, products } = useSelector((reducer: IStore) => reducer.state);
  const handleOpen = () =>
    discharge({ type: 'ADD_PRODUCT_SHOP', addProductToShop: false });

  useEffect(() => {
      dispatch(getShops());
  },[dispatch]);

  const onSubmit = async (data: IProductsShop) => {
    try {
      if ( !data.shopId || !data.quantity) {
        throw Error('Please fill the form completely');
      }


      const payload: Partial<IProductsShop> = {
        products: selectedProductIds,
        shopId: data.shopId,
        quantity: data.quantity,
      };

      setLoading(true);
      const response = await $service.addShopProduct!(payload);
      console.log(payload);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      discharge({ type: 'ADD_PRODUCT_SHOP', addProductToShop: false });
      toast.success(response.message);
    } catch (err) {
      setLoading(false);
      toast.error((err as Error).message);
    }
  };

  const { register, handleSubmit, watch } = useForm<IProductsShop>();
  // const type = watch("type");
  return (
    <Dialog open={addProductToShop} onClose={handleOpen} sx={{ width: '100%' }}>
      <DialogHeader className='text-lg'>Create a shop</DialogHeader>
      <DialogContent sx={{ width: '500px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="mb-2">
        <Typography className="text-sm font-semibold">Type</Typography>
        <select
            className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
              id='default'
              {...register("prodducts", {
                required: true,
              })}>
                  <option value='PARTNER'>PARTNERS</option>
                  <option value='KAT'>KAT</option>
              </select>
      </div> */}
          <div className='mb-2'>
            <Typography className='text-sm font-semibold'>Shops</Typography>
            <select
              id='default'
              {...register('shopId')}
              className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
            >
              <option value=''> Choose Shop</option>
              {shops?.map((shop: any) => (
                <option key={shop?._id} value={shop?._id}>
                  {' '}
                  {shop?.name}
                </option>
              ))}
            </select>
          </div>
          <div className='mb-2'>
            <Typography className='text-sm font-semibold'>Quantity</Typography>
            <Input
              type='number'
              {...register('quantity')}
            />
          </div>
          <Button color='green' type='submit' className='w-full'>
            Submit
          </Button>
        </form>
      </DialogContent>
      <DialogFooter>
        <Button
          variant='filled'
          color='red'
          onClick={handleOpen}
          className='ml-3'
        >
          Cancel
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default AddProductShop;

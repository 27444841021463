import React, { useState } from 'react'
import { useMaterialTailwindController } from '../../context'
import { Dialog, DialogContent, TextField, Autocomplete } from '@mui/material'
import { Button, DialogFooter, DialogHeader } from '@material-tailwind/react'


const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
]

function AssignDeliveryModal() {
    const { discharge, controller } = useMaterialTailwindController()
    const { assignDelivery} = controller
    const [loading, setLoading] = useState<boolean>(false);


const handleOpen = () => discharge({type: "ASSIGN_DELIVERY",  assignDelivery: false })
  return (
    <Dialog open={assignDelivery} onClose={handleOpen}>
      <DialogHeader className="text-lg">Assign Order to a delivery person</DialogHeader>
      <DialogContent sx={{ width: "500px" }}>
        <div
          className="flex items-center justify-center w-full" >
            <>
            <Autocomplete
  disablePortal
  id="combo-box-demo"
  options={top100Films}
  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label="Select Delivery Person" />}
/>
        </>
      </div>
    </DialogContent><DialogFooter>
        <Button
          variant="filled"
          color="blue-gray"
          className="mx-1"
          disabled={loading}
        >
          Submit
        </Button>

        <Button
          variant="text"
          color="red"
          onClick={handleOpen}
          className="mr-1"
        >
          Cancel
        </Button>
      </DialogFooter>
        </Dialog>
        
  )
}

export default AssignDeliveryModal

import React, { useState } from "react";
import backImg from "../Assets/images/IMG_4296.jpg";
import { Link, useNavigate } from "react-router-dom";
import $service from "../services/app.service";
import { useDispatch } from "react-redux";
import { setRefreshToken, setRole, setUser } from "../state/app.state";
import { ToastContainer,toast } from "react-toastify";
import Logo from "../Assets/images/KATCarelogo.png"


const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<{ email: string; password: string }>({
    email: "",
    password: "",
  });

  const setFormValue = (key: string, value: string) => {
    setForm({ ...form, [key]: value });
  };

  const submit = async () => {
    try {
      const { email, password } = form;
      if (email === "" || password === "") {
        throw Error("Password and Email required");
      }
      setLoading(true);
      const response = await $service.login!({ email, password });
      if (!response.success) {
        throw Error(response.message);
      }
      const { _id, resetRequired, role } = response.data;
      if (typeof resetRequired === "boolean" && resetRequired === true) {
        await $service.sendOtp!(email);
        setLoading(false);
        return navigate("/otp", { state: { email } });
      }

      setLoading(false);
      const token = response.token;
      const refreshToken = response.refreshToken;
      localStorage.setItem("kat:user:id", _id);
      localStorage.setItem("kat:refresh:token", refreshToken!);
      $service.setToken!(token!);
      dispatch(setRole(role));
      dispatch(setUser({ _id }));
      dispatch(setRefreshToken(refreshToken!));
    } catch (err) {
      setLoading(false);
      toast((err as Error).message);
    }
  };

  return (
    <section
      className=" bg-cover  bg-no-repeat relative"
      style={{ backgroundImage: `url(${backImg})` }}
    >
      <ToastContainer />
      <div className="absolute w-screen h-screen bg-gradient-to-r from-gray-700 to-blue-gray-700 opacity-70"></div>
      <div className="flex z-40 relative flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 "
        >
          <img src={Logo} className=" h-16 cover sm:h-32" alt="Katcare Logo" />
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <div className="space-y-4 md:space-y-6">
              <div>
                <label
                  id="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  disabled={loading}
                  onChange={(e) => setFormValue("email", e.target.value.trim())}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                />
              </div>
              <div>
                <label
                  id="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  disabled={loading}
                  onChange={(e) => setFormValue("password", e.target.value)}
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      id="remember"
                      className="text-gray-500 dark:text-gray-300"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <Link
                  to="/reset-password"
                  className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Forgot password?
                </Link>
              </div>
              <button
                type="submit"
                onClick={submit}
                className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Sign in
              </button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Reset Your Account Credentials?{" "}
                <Link
                  to="/reset-password"
                  className="font-medium text-blue-600 hover:underline "
                >
                  Reset Password
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;

import { Card } from "@material-tailwind/react";
import CustomerJobCardRequestChart from "../Charts/Requests/Customer/CustomerJobCardRequestChart";

const CustomerAreaJobCardRequestChart = () => {
  return (
    <Card className="relative">
      <Card className="absolute top-[-30px] left-[20px] px-[8px] py-[8px] bg-[#1e88e5]  shadow-md">
        <p className="text-[#FFFFFF]">Accessory Requests</p>
      </Card>
      <CustomerJobCardRequestChart />
    </Card>
  );
};

export default CustomerAreaJobCardRequestChart;

"use client";
import { Fragment, useEffect, useState } from "react";
import { useMaterialTailwindController } from "../../../context";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { ToastContainer, toast } from "react-toastify";
import { DialogFooter, DialogHeader, Button } from "@material-tailwind/react";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../state/app.reducer";
import {
  getCustomerDevices,
  getCustomers,
  getJobcards,
  setLoading,
} from "../../../state/app.state";
import $service from "../../../services/app.service";
import { Link } from "react-router-dom";

interface IJobcardRequestPayload {
  description: string;
  deviceId: string;
  createdBy: string;
  createdByRef: "admin";
  customerId: string;
}

const AddJobCard = ({ officer }: any) => {
  const _dispatch = useDispatch();
  const { loading, user, customers, customerDevices,devices } = useSelector(
    (reducer: IStore) => reducer.state
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const { controller, discharge } = useMaterialTailwindController();
  const [form, setForm] = useState<IJobcardRequestPayload>({
    description: "",
    deviceId: "",
    createdBy: "",
    createdByRef: "admin",
    customerId: "",
  });

  const setFormValue = (key: string, value: string) => {
    return setForm({ ...form, [key]: value });
  };

  const { jobModal } = controller;

  useEffect(() => {
    _dispatch(getCustomers());
  }, []);

  const onSubmit = async () => {
    try {
      const { customerId, deviceId, description } = form;
      if (customerId === "" || deviceId === "" || description === "") {
        throw Error("Please complete the form");
      }
      const data: IJobcardRequestPayload = {
        createdBy: user._id!,
        createdByRef: form.createdByRef,
        description,
        customerId,
        deviceId,
      };
      setLoading(true);
      const response = await $service.requestJobcard!(data);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      discharge({ type: "JOBS_MODAL", jobModal: false });
      toast("Jobcard added successfully");
      _dispatch(getJobcards());
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const handleOpen = () => discharge({ type: "JOBS_MODAL", jobModal: false });

  return (
    <Fragment>
      <ToastContainer />
      <Dialog
        open={jobModal}
        onClose={handleOpen}
        className=" relative w-screen"
        sx={{ width: "100%" }}
      >
        <DialogHeader className="text-lg">Create Job Card</DialogHeader>
        <DialogContent sx={{ width: "500px" }}>
          <div className="max-w-md" id="select">
            {customers.length <= 0 ? (
              <>
                <Skeleton
                  variant="rectangular"
                  className="w-full h-[1.5rem] mb-[2rem]"
                />
              </>
            ) : (
              <Autocomplete
                className="mb-[1.5rem]"
                disablePortal
                id="combo-box-demo"
                disableClearable
                options={customers.map((customer) => ({
                  label: `${customer.firstName} ${customer.lastName}`,
                  id: customer._id,
                }))}
                onChange={(_, { id }: any) => {
                  setFormValue("customerId", id);
                  _dispatch(getCustomerDevices(id));
                }}
                renderInput={(params) => {
                  return <TextField {...params} label="Select Customer" />;
                }}
              />
            )}
            {form.customerId && form.customerId.length > 0  && customerDevices && customerDevices.length > 0 ? (
              <>
              
                <Autocomplete
                  disablePortal
                  className="mb-[1.5rem]"
                  id="combo-box-demo"
                  disableClearable
                  options={customerDevices?.map((device) => ({
                    label: `${device.deviceId?.model} - ${device.deviceId?.serialNumber}`,
                    id: device?.deviceId?._id,
                  }))}
                  onChange={(_, { id }: any) => {
                    setFormValue("deviceId", id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Customer's Store Item"
                    />
                  )}
                />
                <TextField
                  id="outlined-basic"
                  label="Enter Description"
                  variant="outlined"
                  className="w-full mb-[1.5rem]"
                  onChange={(e) => setFormValue("description", e.target.value)}
                />
              </>
            ) : form.customerId && form.customerId.length > 0 ? (
              <div>
                  <p className="text-blue-grey-500 text-sm text-center">No customer devices found? Add a new customer device <Link to={`/customer-details/${form.customerId }`}className="text-blue-500 cursor-pointer uppercase">here</Link></p>
            </div>
            ): null}
          </div>
        </DialogContent>

        <DialogFooter>
          {isLoading ? (
            <>
              <Button variant="filled" color="blue-gray">
                Loading...
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="filled"
                color="blue-gray"
                onClick={async () => await onSubmit()}
              >
                Create
              </Button>
            </>
          )}
          <Button color="red" onClick={handleOpen} className="mx-3">
            Cancel
          </Button>

        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default AddJobCard;

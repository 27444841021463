export const CustomerJobCardRequestData:any = {
    options: {
        chart: {
          id: "basic-bar",
          toolbar:{
            show: false
          }
        },
        stroke: {
            curve: 'smooth',
        },
        dataLabels: {
            enabled: false
          },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept","Oct","Nov","Dec"]
        },
        legend:{
          show: false
        }
      },
      series: [
        {
          name: "Customer Job Card Requests",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        },
        {
          name: "Partner Job Card Requests",
          data: [20, 44, 30, 23, 27, 33, 44, 91]
        }
      ],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100]
        }
      },
      responsive: [
        {
          breakpoint: 500,
          options: {
            plotOptions: {
              bar: {
                horizontal: false
              }
            },
            legend: { 
              position: "bottom"
            }
          }
        }
      ]
}
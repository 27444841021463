import { Fragment } from "react";
import { Button, Dialog,  DialogBody, DialogFooter,    Typography, } from "@material-tailwind/react";
import { useMaterialTailwindController } from "../../../context";


const AddDiagnosis = ({opened, setOpened}:any) => {
    const { controller, discharge } = useMaterialTailwindController();
    const { openModal } = controller;
  
    const handleOpen = () => discharge({ type: "OPEN_MODAL", openModal: false });
    return (
      <Fragment>
        <Dialog open={openModal} handler={handleOpen}>
          <DialogBody>
            <Fragment>
                <div className="mb-2 ">
                  <Typography className="text-md font-semibold">
                  Are you sure you want to approve this job card
                  </Typography>     
                </div>
                <Button
                  type="submit"
                  variant="filled"
                  color="green"
                  className="w-full mt-2"
                  onClick={() => {
                    setOpened(!opened);
                    handleOpen();
                  }}
                >
                  Approve
                </Button>
            </Fragment>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-1"
            >
              Cancel
            </Button>    
          </DialogFooter>
        </Dialog>
      </Fragment>
    );
}

export default AddDiagnosis
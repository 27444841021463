const formatMetrics = (metrics: { [x: string]: Array<{ count?: number }> }) => {
  let mtx: any = {};
  metrics = Array.isArray(metrics) ? metrics[0] : metrics;
  Object.keys(metrics).map((k: string) => {
    const m = metrics[k];
    if (Array.isArray(m) && m.length > 0 && m[0].count) {
      mtx[k] = m[0].count;
    } else {
      mtx[k] = 0;
    }
  });
  return mtx;
};

export default { formatMetrics };

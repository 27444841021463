import  { routex } from "../Routes/routes";
import {  useParams} from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Typography,
  Progress,
} from "@material-tailwind/react";
import DetailPageSkeleton from "../component/layout/DetailPageSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  IAccessoryRequestDevices,
  IAccessoryRequestTypes,
} from "../models/app.model";
import $service from "../services/app.service";
import { getApprovalStatus, getAccessoryRequest } from "../state/app.state";
import { toast } from "react-toastify";
import WithFunctionGuard from "../guards/FunctionGuard";
import DashboardLayout from "../component/layout/DashboardLayout";

const AccessoryRequestDetails = () => {
  const { _id } = useParams<string>();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [accessory, setRequest] = useState<Partial<IAccessoryRequestTypes>>({});
  const [devices, setDevices] = useState<IAccessoryRequestDevices[]>([]);
  const { roles, status } = useSelector((reducer: any) => reducer.state);

  useEffect(() => {
    getRequest();
    dispatch(getApprovalStatus());
  }, [_id]);


  const reject = async () => {
    try {
      setLoading(true);
      const response = await $service.transferAccessoryRequest({
        requestId: _id,
        statusId: "64c006ea3a74e757cd8ce57d",
      });
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast("Request rejected successfully");
      dispatch(getAccessoryRequest());
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const transfer = async () => {
    try {
      setLoading(true);
      const nxt = status.find((s: any) => roles.approves.includes(s.name));
      const response = await $service.transferAccessoryRequest({
        requestId: _id,
        statusId: nxt._id,
      });
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast("Request approves successfully");
      dispatch(getAccessoryRequest());
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const getRequest = async () => {
    try {
      let response = await $service.getAccessoryRequestById(_id!);
      if (!response.success) {
        throw Error(response.message);
      }
      setRequest(response.data);
      response = await $service.getAccessoryDevices(_id!);
      if (response.success) {
        setDevices(response.data);
      }
    } catch (err) {}
  };



  const AcceptAccessoryButton = WithFunctionGuard(() => (
    <Button 
    onClick={transfer} 
    color="green" key={"accept-accessory"} 
    disabled={
      accessory?.status === "ACCESSORY-APPROVED" ? false:
      accessory?.status === "KATCARE-GM-APPROVAL" ? false : 
      accessory?.status === "PRODUCTION-MANAGER-APPROVAL" ? false :  true}>
    {" "}
    Approve{" "}
  </Button>
  ))

  const RejectAccessoryButton = WithFunctionGuard(() => (
    <Button onClick={reject} color="red" key={"reject-accesory"} disabled={accessory?.status === "ACCESSORY-REJECTED"}>
                        {" "}
                        Reject{" "}
                      </Button>
  ))

  return (
    <DashboardLayout>
        <div className="my-12">
          {isLoading ? (
            <DetailPageSkeleton />
          ) : (
            <div>
              <Card
                className="w-full h-56 relative mb-24"
                color="blue"
                variant="gradient"
              >
                <CardHeader className="h-64 relative left-0 top-40">
                  <div className="flex flex-row justify-between p-3 items-center gap-5">
                    <div className="flex flex-row gap-5 items-center py-3">
                      <div className="mt-3">
                        <Typography
                          variant="h5"
                          color="blue-gray"
                          className="mb-2 "
                        >
                           #{accessory?.jobcardId?.jobcardNumber}
                        </Typography>
                        <Typography
                          variant="paragraph"
                          color="blue-gray"
                          className="mb-2 text-md"
                          textGradient
                        >
                          Number of Accessories:{" "}
                          <span className="text-md text-gray-700">
                            {devices?.length}
                          </span>
                        </Typography>
                        <Typography
                          variant="paragraph"
                          color="blue-gray"
                          className="mb-2 text-md"
                          textGradient
                        >
                          Date of request:{" "}
                          <span className="text-md text-gray-700">
                            {new Date(accessory?.createdAt).toDateString()}
                          </span>
                        </Typography>
                        <Typography
                          variant="paragraph"
                          color="blue-gray"
                          className="mb-2 text-md"
                          textGradient
                        >
                          Status:{" "}
                          <span className="text-md text-gray-700">
                            {accessory?.status}
                          </span>
                        </Typography>
                      
                        <div className="flex gap-2 mb-4 items-baseline">
                          <Typography
                            variant="paragraph"
                            color="blue-gray"
                            className="mb-2 text-md "
                            textGradient
                          >
                            Reason:
                          </Typography>
                          <span className="text-md text-gray-700 ">
                            {accessory?.reason}
                          </span>

                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between gap-3">
                      <AcceptAccessoryButton />
                     <RejectAccessoryButton />
                    </div>
                  </div>
                </CardHeader>
              </Card>
         <div className="grid grid-cols-2 my-8 gap-8 mt-52">
                <Card className="w-full">
                  <CardBody>
                    <div className="">
                      <Typography
                        variant="h5"
                        className="text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white"
                      >
                        Job Card Details
                      </Typography>
                      <ul className="list-none">
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Job Name:
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              #{accessory?.jobcardId?.jobcardNumber}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Description:
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {accessory?.jobcardId?.description}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Device Progress:
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              <Progress
                                value={accessory?.jobcardId?.progress}
                                color={
                                  accessory?.jobcardId &&
                                  accessory?.jobcardId?.progress <= 50
                                    ? "red"
                                    : "green"
                                }
                                label={true}
                              />
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              JobCard Status:
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {accessory?.jobcardId?.status}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div className="">
                      <Typography
                        variant="h5"
                        className="text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white"
                      >
                        Technician
                      </Typography>
                      <ul className="list-none">
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Full Name:
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {accessory?.adminId?.first_name}{" "}
                              {accessory?.adminId?.last_name}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">Email:</h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {accessory?.adminId?.email}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Company:
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {accessory?.adminId?.company_name}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">Role:</h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {accessory?.adminId?.role}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </div>

              {/* Customer Job Cards */}
              {/* <>
                {devices && devices.length === 0 ? (
                  <div className="flex flex-col items-center py-4 justify-center">
                    <NoSymbolIcon className="w-8 h-8 text-gray-600" />
                    <p className="text-lg mt-4 text-gray-600">
                      No customer job cards available
                    </p>
                  </div>
                ) : (
                  <Card shadow={false}>
                    <CardBody>
                      <div className="py-2 pb-3">
                        <h5 className="font-bold text-lg  text-gray-900">
                          Store Items
                        </h5>
                      </div>
                      <div className="relative overflow-x-auto shadow-sm sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Device Name
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Serial Number
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Category
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Type
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Price
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Status
                              </th>
                              <th scope="col" className="px-6 py-3">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>

                          {devices && devices.map(
                            (storeItem) => (
                              <tbody key={storeItem?._id}>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                  <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                  >
                                    {storeItem?.deviceId?.model}
                                  </th>
                                  <td className="px-6 py-4">
                                    {storeItem?.deviceId?.serialNumber}
                                  </td>
                                  <td className="px-6 py-4 text-teal-600">
                                    {storeItem?.deviceId?.category}
                                  </td>
                                  <td className="px-6 py-4">
                                    {storeItem?.deviceId?.color}
                                  </td>
                                  <td className="px-6 py-4">
                                    {storeItem?.deviceId?.price}
                                  </td>

                                  <td className="px-6 py-4">
                                    {storeItem?.deviceId?.sold ? "sold"
                                      : "not sold"}
                                  </td>

                                  <td className="px-6 py-4 text-right">
                                    <Link
                                      to={`/stock-details/${storeItem.deviceId?._id}`}
                                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                      View
                                    </Link>
                                  </td>
                                </tr>
                              </tbody>
                            )
                          )}
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </> */}
            </div>
          )}
        </div>
</DashboardLayout>
  );
};

export default AccessoryRequestDetails;

import React, { useEffect, useRef, useState } from "react";
import $service from "../services/app.service";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../Assets/images/logo.png";
import { routex } from "../Routes/routes";
import Sidenav from "../component/layout/Sidenav";
import DashboardNavbar from "../component/layout/DashboardNavbar";
import { Route, Routes } from "react-router-dom";
import {
  Card,
  CardBody,
  Input,
  Typography,
  Button,
} from "@material-tailwind/react";
import { Autocomplete, TextField } from "@mui/material";
import { getInventory, getProducts } from "../state/app.state";
import { toast } from "react-toastify";

function SaleCheckOut() {
  const { roles, inventory, products } = useSelector((reducer: any) => reducer.state);
  const [error, setError] = useState("");
  const [quantity, setQuantity] = useState<number>(1);
  const [selectedDevices, setSelectedDevices] = useState<any>([]);
  const [deleteIds, setDeleteIds] = useState<string[]>([]);
  const [deviceId, setDeviceId] = useState<{
    label: string;
    deviceId: string;
    price: any;
  }>({ label: "", deviceId: "", price: null });
  const [formValid, setFormValid] = useState(false);
  const [customerInformation, setCustomerInformation] = useState<any>({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [withMobileMoney, setWithMobileMoney] = useState(false);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [loading, setLoading] = useState<any>()

  let data = {
    adminId: localStorage.getItem("kat:user:id"),
    cart: selectedDevices.map((selectedDevice: any) => {
      return {
        device: selectedDevice.device?.deviceId,
        quantity: selectedDevice.quantity,
        price: selectedDevice.totalPrice,
      };
    }),
    customerDetails: {
      firstName: customerInformation["First Name"],
      lastName: customerInformation["Last Name"],
      phone: customerInformation["Mobile Number"],
      email: customerInformation["Email"],
    },
    deliveryDetails: {
      fullname: customerInformation["Full Name"],
      region: customerInformation["Region"],
      city: customerInformation["City"],
      address: customerInformation["Address"],
      phone: customerInformation["Delivery Phone"],
    },
    paymentType: {
      accountType: customerInformation.payment_method,
      accountNumber: customerInformation["Account Number"],
      accountIssuer: customerInformation["Account Issuer"],
    },
    inventory: customerInformation["Inventory"],
  };

  let TotalPrice = useRef<any>(null)
  let devicesArray = useRef<any>([]);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const getMenu = () => {
    try {
      return roles.menu ? routex[roles.menu] : [];
    } catch (err) {
      return [];
    }
  };

  const handleSelectedDeviceChange = (_: any, v: any, e: any) => {
    setDeviceId({ deviceId: v?.id, label: v?.label, price: v?.price });
    setMaxQuantity(v?.quantity);
  };

  const handleNumberChange = (e: any) => {
    const inputValue = parseInt(e.target.value);

    // Validate minimum and maximum values
    const minNumber = 1;
    const maxNumber = maxQuantity;

    if (isNaN(inputValue)) {
      setFormValid(false);
      setError("Please enter a valid number");
    } else if (inputValue < minNumber || inputValue > maxNumber) {
      setFormValid(false);
      setError(`Number must be between ${minNumber} and ${maxNumber}`);
    } else {
      if (deviceId?.deviceId.length > 0) {
        setQuantity(inputValue);
        setFormValid(true);
        setError("");
      }
    }
  };

  const addDevice = () => {
    if (deviceId?.deviceId.length > 0) {
      let deviceInTheList = selectedDevices.find(
        (item: any) => item.device.deviceId === deviceId.deviceId
      );
      if (deviceInTheList) {
        let indexOfSelectedDevice = selectedDevices.indexOf(deviceInTheList);
        let newSelectedDevices = [...selectedDevices];
        newSelectedDevices[indexOfSelectedDevice].quantity += quantity;
        newSelectedDevices[indexOfSelectedDevice].totalPrice +=
          quantity * deviceId?.price;

        setSelectedDevices(newSelectedDevices);
        devicesArray.current = newSelectedDevices
        updateTotalPrice();
      } else {
        setSelectedDevices([
          ...selectedDevices,
          {
            device: deviceId,
            quantity: quantity,
            totalPrice: quantity * deviceId.price,
          },
        ]);

        devicesArray.current = [
          ...selectedDevices,
          {
            device: deviceId,
            quantity: quantity,
            totalPrice: quantity * deviceId.price,
          },
        ];
        updateTotalPrice();
      }
    } else {
      throw Error("Please choose device");
    }
  };

  const removeDevice = () => {
    if (deleteIds.length <= 0) return;
    const updateDevice = selectedDevices.filter(
      ({ device }: any) => !deleteIds.includes(device?.deviceId)
    );
    setSelectedDevices(updateDevice);
    updateTotalPrice();
    setDeleteIds([]);
  };

  const onDelete = (_id: string, checked: boolean) => {
    let ds: string[] = [...deleteIds];
    switch (typeof checked === "boolean" && checked === true) {
      case true:
        ds.push(_id);
        break;
      default:
        ds = ds.filter((id) => id !== _id);
        break;
    }
    setDeleteIds(ds);
  };

  const updateTotalPrice = () => {
    let newPrice = 0;
    devicesArray.current.forEach((device: any) => {
      if (devicesArray.current.length <= 0) {
        TotalPrice.current = newPrice;
        return
      } else {
        newPrice += device.totalPrice;
      }
    });
    TotalPrice.current = newPrice;
  };

  const handleCustomerInformation = (event: any) => {
    setCustomerInformation({
      ...customerInformation,
      [event.target.name]: event.target.value,
    });
  };

  const checkFormValidity = () => {};

  const submit = () => {
    data = {
      adminId: localStorage.getItem("kat:user:id"),
      cart: selectedDevices.map((selectedDevice: any) => {
        return {
          device: selectedDevice.device?.deviceId,
          quantity: selectedDevice.quantity,
          price: selectedDevice.totalPrice,
        };
      }),
      customerDetails: {
        firstName: customerInformation["First Name"],
        lastName: customerInformation["Last Name"],
        phone: customerInformation["Mobile Number"],
        email: customerInformation["Email"],
      },
      deliveryDetails: {
        fullname: customerInformation["Full Name"],
        region: customerInformation["Region"],
        city: customerInformation["City"],
        address: customerInformation["Address"],
        phone: customerInformation["Delivery Phone"],
      },
      paymentType: {
        accountType: customerInformation.payment_method,
        accountNumber: customerInformation["Account Number"],
        accountIssuer: customerInformation["Account Issuer"],
      },
      inventory: inventory[0]?.type,
    };

    data.adminId &&
    data.cart.length > 0 &&
    data.customerDetails.firstName &&
    data.customerDetails.lastName &&
    data.customerDetails.phone &&
    data.customerDetails.email &&
    data.deliveryDetails.fullname &&
    data.deliveryDetails.region &&
    data.deliveryDetails.city &&
    data.deliveryDetails.address &&
    data.deliveryDetails.phone &&
    (data.paymentType.accountType === "momo"
      ? data.paymentType.accountType &&
        data.paymentType.accountNumber &&
        data.paymentType.accountIssuer
      : data.paymentType.accountType) &&
    data.inventory
      ? (async()=> {
        setLoading(true)
        let response = await $service.checkout!(data)
        if(response) {
          setLoading(false)
          if(response.success) {
            toast("Request successfully sent");
          } else {
            toast("Request could not be proceeded, Kindly check input fields")
          }  //WHERE TO CALL SERVICE TO CHECKOUT
        }
      })()
      : toast("Missing fields");
  };

  return (
    <div className="min-h-screen py-5 bg-blue-gray-50/50">
      <Sidenav routex={getMenu()} brandImg={Logo} />
      <div className="p-4 xl:ml-80">
        <DashboardNavbar />
        <Routes>
          {getMenu().map(
            ({ pages, layout }: any) =>
              layout === "katcare-developer" &&
              pages.map(({ path, element }: any) => (
                <Route path={path} element={element} key={path} />
              ))
          )}
        </Routes>
        <div className="my-12">
          <div className="grid grid-cols-2 gap-10">
            <div>
              <div className="mb-8">
                <Typography variant="h5" className="my-2 text-base">
                  Select Product
                </Typography>
                <Card>
                  <CardBody>
                    <div className="grid grid-cols-1 gap-6">
                      <form>
                        <div className="mb-2">
                          <Typography className="text-sm font-semibold">
                            Select Product
                          </Typography>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ width: "100%" }}
                            options={products?.map((d: any) => ({
                              label: `${d?.name} GHS${d?.price}`,
                              id: d?._id,
                              price: d?.price,
                              quantity: d?.quantity,
                            }))}
                            onChange={(_, v, e) => {
                              handleSelectedDeviceChange(_, v, e);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                        <div className="mb-4">
                          <Typography className="text-sm font-semibold">
                            Quantity{" "}
                            {maxQuantity > 0 && (
                              <small className="ml-5">
                                *Max. Quantity in Inventory: {maxQuantity}
                              </small>
                            )}
                          </Typography>
                          <Input type="number" onChange={handleNumberChange} />
                          {error && <div style={{ color: "red" }}>{error}</div>}
                        </div>

                        <Button
                          variant="filled"
                          color="blue"
                          className="mr-3"
                          onClick={addDevice}
                          disabled={!formValid}
                        >
                          Add Device
                        </Button>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="mb-8">
                <Typography
                  variant="h5"
                  className="my-2 text-base position-relative"
                >
                  Customer Infomation{" "}
                  <small className="ml-4" style={{ color: "red" }}>
                    *Required
                  </small>
                </Typography>
                <Card>
                  <CardBody>
                    <form
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <div className="grid grid-cols-2 gap-6">
                        <div className="mb-2">
                          {/* customer info should be download like the create jobcard  */}
                          <Typography className="text-sm font-semibold">
                            First Name
                          </Typography>
                          <Input
                            type="text"
                            name="First Name"
                            onChange={handleCustomerInformation}
                          />
                        </div>
                        <div className="mb-2">
                          {/* customer info should be download like the create jobcard  */}
                          <Typography className="text-sm font-semibold">
                            Last Name
                          </Typography>
                          <Input
                            type="text"
                            name="Last Name"
                            onChange={handleCustomerInformation}
                          />
                        </div>
                        <div className="mb-2">
                          <Typography className="text-sm font-semibold">
                            Mobile Number
                          </Typography>
                          <Input
                            type="tel"
                            name="Mobile Number"
                            onChange={handleCustomerInformation}
                          />
                        </div>
                        <div className="mb-2">
                          <Typography className="text-sm font-semibold">
                            Email
                          </Typography>
                          <Input
                            type="email"
                            name="Email"
                            onChange={handleCustomerInformation}
                          />
                        </div>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </div>
              {/* <div>
                <Typography variant="h5" className="my-2 text-base">
                  Delivery Details{" "}
                  <small className="ml-4" style={{ color: "red" }}>
                    *Required
                  </small>
                </Typography>
                <Card>
                  <CardBody>
                    <form
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <div className="grid grid-cols-2 gap-6">
                        <div className="mb-2">
                    
                          <Typography className="text-sm font-semibold">
                            Full Name
                          </Typography>
                          <Input
                            type="text"
                            name="Full Name"
                            onChange={handleCustomerInformation}
                          />
                        </div>
                        <div className="mb-2">
                          <Typography className="text-sm font-semibold">
                            Region
                          </Typography>
                          <Input
                            type="text"
                            name="Region"
                            onChange={handleCustomerInformation}
                          />
                        </div>
                        <div className="mb-2">
                          <Typography className="text-sm font-semibold">
                            City
                          </Typography>
                          <Input
                            type="text"
                            name="City"
                            onChange={handleCustomerInformation}
                          />
                        </div>
                        <div className="mb-2">
                          <Typography className="text-sm font-semibold">
                            Address
                          </Typography>
                          <Input
                            type="text"
                            name="Address"
                            onChange={handleCustomerInformation}
                          />
                        </div>
                        <div className="mb-2">
                          <Typography className="text-sm font-semibold">
                            Phone
                          </Typography>
                          <Input
                            type="tel"
                            name="Delivery Phone"
                            onChange={handleCustomerInformation}
                          />
                        </div>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </div> */}
              {/* payment info */}
              <div className="mt-8">
                <Typography variant="h5" className="my-2 text-base">
                  Payment Method{" "}
                  <small className="ml-4" style={{ color: "red" }}>
                    *Required
                  </small>
                </Typography>
                <Card className="my-3">
                  <CardBody>
                    <div className="flex gap-10">
                      <div className="flex items-center">
                        <input
                          id="link-radio"
                          type="radio"
                          name="payment_method"
                          onChange={(event) => {
                            handleCustomerInformation(event);
                            setWithMobileMoney(true);
                          }}
                          value="momo"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2"
                        />
                        <Typography
                          id="link-radio"
                          className="ml-2 text-base font-medium text-gray-900 "
                        >
                          Mobile Money
                        </Typography>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="link-radio"
                          type="radio"
                          name="payment_method"
                          onChange={(event) => {
                            handleCustomerInformation(event);
                            setWithMobileMoney(false);
                          }}
                          value="cash"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2"
                        />
                        <Typography
                          id="link-radio"
                          className="ml-2 text-base font-medium text-gray-900 "
                        >
                          Cash
                        </Typography>
                      </div>
                    </div>
                    {withMobileMoney && (
                      <div className="grid grid-cols-2 gap-6 mt-5">
                        <div className="mb-2">
                          {/* customer info should be download like the create jobcard  */}
                          <Typography
                            id="network provider"
                            className="ml-2 text-base font-medium text-gray-900"
                          ></Typography>
                          <select
                            name="Account Issuer"
                            className="form-select"
                            aria-label="Network Provider"
                            onChange={(event) => {
                              handleCustomerInformation(event);
                              console.log(event.target.name);
                            }}
                          >
                            <option selected>Select Network</option>
                            <option value="mtn">MTN</option>
                            <option value="vodafone">Vodafone</option>
                            <option value="airtelTigo">Airtel Tigo</option>
                          </select>
                          <div className="mt-4">
                            <Typography
                              id="Momo Number"
                              className="ml-2 text-base font-medium text-gray-900 "
                            >
                              Momo Number
                            </Typography>
                            <Input
                              id="Momo Number"
                              name="Account Number"
                              onChange={handleCustomerInformation}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
              <div className="mt-8">
                <Typography variant="h5" className="my-2 text-base">
                  Inventory
                </Typography>
                <Card className="my-3">
                  <CardBody>
                    <form>
                      <div className="grid grid-cols-2 gap-6">
                        <div className="mb-2">
                          {/* customer info should be download like the create jobcard  */}
                          <Typography className="text-sm font-semibold">
                            Inventory
                          </Typography>
                          <Input
                            type="text"
                            name="Inventory"
                            value={inventory[0]?.type}
                            disabled
                          />
                        </div>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </div>
            </div>
            {/* Product list */}
            <div>
              <Typography variant="h5" className="my-2 text-base">
                Cart{" "}
                <small className="ml-4" style={{ color: "red" }}>
                  *Required
                </small>
              </Typography>
              <Card className="mh-50">
                <CardBody className="h-100">
                  <div className="relative mt-3 h-100 overflow-auto">
                    <div className="d-flex justify-content-between my-2">
                      <Button
                        color="red"
                        onClick={removeDevice}
                        disabled={deleteIds.length <= 0}
                      >
                        Remove
                      </Button>
                      <Button
                        color="red"
                        className="ml-3"
                        onClick={() => {
                          setSelectedDevices([]);
                          setTotalPrice(0);
                        }}
                        disabled={selectedDevices.length <= 0}
                      >
                        Remove All
                      </Button>
                    </div>

                    <table className="w-full h-100 text-sm text-left text-gray-500">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Select
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Device Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Quantity
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Unit Price
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Total Price
                          </th>
                        </tr>
                      </thead>
                      <tbody className="overflow-scroll h-100">
                        {selectedDevices.map((d: any) => (
                          <tr className="bg-white border-b " key={d?.deviceId}>
                            <td className="pl-5">
                              <input
                                checked={deleteIds.includes(d.device?.deviceId)}
                                type="checkbox"
                                onChange={(e) =>
                                  onDelete(
                                    d.device?.deviceId,
                                    e.currentTarget.checked
                                  )
                                }
                              />
                            </td>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap e"
                            >
                              {d.device?.label}
                            </th>
                            <td className="px-6 py-4 text-center">
                              {d.quantity}
                            </td>
                            <td className="px-6 py-4">{d.device.price}</td>
                            <td className="px-6 py-4">{d.totalPrice}</td>
                          </tr>
                        ))}
                      </tbody>
                      {selectedDevices.length > 0 && (
                        <tfoot>
                          <th className="px-6 py-4 text-center uppercase">
                            Total{" "}
                          </th>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="px-6 py-4">{TotalPrice.current}</td>
                        </tfoot>
                      )}
                    </table>
                  </div>
                  <div className="my-5">
                    <Button
                      className="w-full"
                      onClick={() => {
                        submit();
                      }}

                      disabled={loading}
                    >
                      {" "}
                      Submit{" "}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaleCheckOut;

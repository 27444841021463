import { Fragment, useState, useEffect } from "react";
import CustomSkeleton from "../component/layout/CustomSkeleton";
import {
  Card,
  CardHeader,
  Button,
  Typography,
  CardBody,
} from "@material-tailwind/react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";
import { useMaterialTailwindController } from "../context";
import AddImportDevice from "../component/element/Stocks/AddImportDevice";
import WithFunctionGuard from "../guards/FunctionGuard";
import AddDeviceCategory from "../component/element/Stocks/AddDeviceCategory";
import DeviceCategoryTable from "../component/element/tables/DeviceCategoryTable";
import { getCategory } from "../state/app.state";

const DeviceCategoryPage = () => {
  const {categorys,loading} = useSelector((reducer: IStore) => reducer.state);
  const [trigger, setTrigger] = useState(0);
  const { discharge } = useMaterialTailwindController();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCategory())
  },[]);

  const filteredData = categorys.map((storelist: any) => {
    return {
      name: storelist.model,
      warrantyPeriod: storelist.warrantyPeriod,
      deviceName: storelist.category,
      distributionDate: storelist.distributionDate
    };
  });

  const AddDeviceCategoryButton = WithFunctionGuard(() => (
    <Button
      onClick={() => discharge({ type: "ADD_CATEGORY", addCategory: false })}
      color="blue"
      key={"add-category"}
    >
      {" "}
      Add Device Category
      {" "}
    </Button>
  ));

  return (
    <Fragment>
      <div className="mt-12">
      <AddDeviceCategory />
        <div>
          {loading ? (
            <CustomSkeleton />
          ) : (
            <>
              <div className="mt-12">
                <AddImportDevice />
                <div className="mb-20 grid gap-y-10 gap-x-6 md:grid-cols-1 xl:grid-cols-1">
                  <div></div>
                  <div className="flex justify-end gap-10">
                    <AddDeviceCategoryButton />
                  
                    <CSVLink data={filteredData} filename={"store.csv"}>
                      <Button variant="gradient" color="blue">
                        Export CSV
                      </Button>
                    </CSVLink>
                    <Button
                      variant="outlined"
                      color="blue"
                      onClick={() => {
                        setTrigger((trigger) => trigger + 1);
                      }}
                    >
                      Print
                    </Button>
                  </div>
                </div>

                <Card shadow={false}>
                  <CardHeader
                    variant="gradient"
                    color="blue"
                    className="mb-8 p-4"
                  >
                    <Typography variant="h6" color="white">
                      All KAT Devices and Others
                    </Typography>
                  </CardHeader>
                  <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                    {/* Add a check for whether the data is loaded before rendering */}
                    {loading ? (
                      <CustomSkeleton />
                    ) : (
                      <DeviceCategoryTable tableData={categorys} trigger={trigger} />
                    )}
                  </CardBody>
                </Card>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default DeviceCategoryPage ;

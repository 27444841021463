import { useEffect, Fragment, useState, useRef } from 'react';
import CustomSkeleton from '../component/layout/CustomSkeleton';
// import { OfficersTablesProps } from "../../data/types";
import { FaEye } from 'react-icons/fa';
import {
  Card,
  CardHeader,
  Button,
  Typography,
  CardBody,
} from '@material-tailwind/react';
import { useMaterialTailwindController } from '../context';
import { CSVLink } from 'react-csv';
import OfficersTables from '../component/element/tables/OfficersTable';
import CreateOfficer from '../component/element/admins/CreateOfficer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../state/app.reducer';
import { getAdminRoles, getAdmins } from '../state/app.state';
import { useReactToPrint } from 'react-to-print';

const AdministratorsPage = () => {
  const dispatch = useDispatch();
  const { loading, admins } = useSelector((store: IStore) => store.state);
  const componentToPrint = useRef(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const { discharge } = useMaterialTailwindController();

  useEffect(() => {
    dispatch(getAdmins());
    dispatch(getAdminRoles());
  }, []);

  useEffect(() => {
    const updatedPartners = admins.filter(officer => officer.role !== 'KATCARE-PARTNER').map<any>((officer) => {

      return {
        key: officer._id,
        _id: officer._id,
        role: officer.role || 'N/A',
        firstName: officer.first_name,
        lastName: officer.last_name,
        company: officer.company_name,
        phoneNumber: officer.mobile,
        region: officer.region,
        district: officer.district,
      };
    });

    setTableData(updatedPartners);
  }, [admins]);

  const filteredData = admins.filter(officer => officer.role !== 'KATCARE-PARTNER').map((officer) => {
    return {
      firstName: officer.first_name,
      lastName: officer.last_name,
      email: officer.email,
      phoneNumber: officer.mobile,
      role: officer.role || 'No ',
      region: officer.region,
      district: officer.district,
      // Add more properties as needed
    };
  });

  const generatePDFtoPrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  const onRef = (ref: any) => (componentToPrint.current = ref);

  return (
    <Fragment>
      <div className='mt-12'>
        <ToastContainer />
        <div>
          {loading ? (
            <CustomSkeleton />
          ) : (
            <>
              <div className='mt-12'>
                <CreateOfficer />
                <div className='mb-20 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-2'>
                  <div></div>
                  <div className='flex justify-end gap-3'>
                    <Button
                      variant='gradient'
                      color='blue'
                      className=''
                      onClick={() =>
                        discharge({ type: 'OPEN_MODAL', openModal: false })
                      }
                    >
                      Add Officers
                    </Button>

                    <CSVLink data={filteredData} filename={'officers.csv'}>
                      <Button variant='gradient' color='blue' className='w-38'>
                        Export CSV
                      </Button>
                    </CSVLink>
                    <Button
                      variant='outlined'
                      color='blue'
                      onClick={generatePDFtoPrint}
                    >
                      Print
                    </Button>
                  </div>
                </div>

                <Card shadow={false}>
                  <CardHeader
                    variant='filled'
                    color='blue'
                    className='mb-8 p-4'
                  >
                    <Typography variant='h6' color='white'>
                      All KAT Officers
                    </Typography>
                  </CardHeader>
                  <CardBody className='overflow-x-scroll px-0 pt-0 pb-2'>
                    {tableData && tableData.length > 0 ? (
                      <OfficersTables tableData={tableData} tableRef={onRef} />
                    ) : (
                      <div className=''>
                        <p>There is currently no data available for customer</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AdministratorsPage;

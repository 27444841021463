import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";


export function Footer({ brandName, brandLink, routes }:any) {
  const year = new Date().getFullYear();

  return (
    <footer className="py-2">
      <div className="flex w-full flex-wrap items-center justify-center gap-6 px-2 md:justify-between">
        <Typography variant="small" className="font-normal text-inherit">
          &copy; {year}, PASS all rights resevered 
        </Typography>
        <ul className="flex items-center gap-4">
          {routes.map(({ name, path }:any) => (
            <li key={name}>
              <Typography
                as="a"
                href={path}
                target="_blank"
                variant="small"
                className="py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500"
              >
                {name}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  brandName: "Kat Technologies Ghana",
  brandLink: "https://www.katechnologiesgh.com",
  routes: [
    { name: "Kat Technologies Ghana", path: "https://www.katechnologiesgh.com" },
   
  ],
};

Footer.propTypes = {
  brandName: PropTypes.string,
  brandLink: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};



export default Footer;
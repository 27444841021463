import React, { useEffect, useState } from "react";
import { useMaterialTailwindController } from "../../../context";
import { Dialog, DialogContent, TextField } from "@mui/material";
import {
  DialogHeader,
  DialogFooter,
  Button,
  Input,
  Typography,
} from "@material-tailwind/react";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../state/app.reducer";
import $service from "../../../services/app.service";
import { toast } from "react-toastify";
import { getAllStockItems,  getProducts, getShops } from "../../../state/app.state";

interface IStockItems {
  adminId?: string;
  products: Array<{
    quantity: number;
    productId: string;
  }>;
  shopId: any;
}

function StockRequestModal() {
  const { discharge, controller } = useMaterialTailwindController();
  const { stockRequest } = controller;
  const dispatch = useDispatch();
  const { user, products, shops } = useSelector((reducer: IStore) => reducer.state);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedDevices, setSelectedDevices] = useState<any>([]);
  const [deleteIds, setDeleteIds] = useState<string[]>([]);
  const [deviceId, setDeviceId] = useState<{
    name: string;
    productId: string;
  }>({ name: '', productId: '' });
  const [quantity, setQuantity] = useState<number>(1);
  const [error, setError] = useState("");
  const [formValid, setFormValid] = useState(false);

  const handleSelectedDeviceChange = (selectedOption: { label: string; id: string; } | null) => {
    if (selectedOption && selectedOption.id && selectedOption.label) {
      setDeviceId({ productId: selectedOption.id, name: selectedOption.label });
    } else {
      console.error("Invalid data structure for selected device:", selectedOption);
    }
  };
  

  const handleNumberChange = (e: any) => {
    const inputValue = parseInt(e.target.value);

    // Validate minimum and maximum values
    const minNumber = 1;
    const maxNumber = 100;

    if (isNaN(inputValue)) {
      setFormValid(false);
      setError("Please enter a valid number");
    } else if (inputValue < minNumber || inputValue > maxNumber) {
      setFormValid(false);
      setError(`Number must be between ${minNumber} and ${maxNumber}`);
    } else {
      if (deviceId?.productId?.length > 0) {
        setQuantity(inputValue);
        setFormValid(true);
        setError("");
      }
    }
  };

  const addDevice = () => {
    console.log("deviceId:", deviceId);
    console.log("deviceId.productId:", deviceId?.productId);
    if (deviceId && deviceId.productId && deviceId.productId.length > 0) {
      let deviceInTheList = selectedDevices?.find(
        (item: any) => item?.product?._id === deviceId?.productId
      );
  
      if (deviceInTheList) {
        let indexOfSelectedDevice = selectedDevices.indexOf(deviceInTheList);
        let newSelectedDevices = [...selectedDevices];
        newSelectedDevices[indexOfSelectedDevice].quantity += quantity;
        setSelectedDevices(newSelectedDevices);
      } else {
        console.log(...selectedDevices);
        setSelectedDevices([
          ...selectedDevices,
          { product: deviceId, quantity: quantity },
        ]);
      }
    } else {
      throw Error("Please choose device");
    }
  };
   
  const removeDevice = () => {
    const updateDevice = selectedDevices.filter(
      ({ product }: any) => !deleteIds.includes(product?.productId)
    );
    setSelectedDevices(updateDevice);
    setDeleteIds([]);
  };

  useEffect(() => {
    dispatch(getShops());
    dispatch(getProducts());
  }, [dispatch]);

  const handleClose = () =>
    discharge({ type: "STOCK_REQUEST_MODAL", stockRequest: false });

  const onDelete = (_id: string, checked: boolean) => {
    let ds: string[] = [...deleteIds];
    switch (typeof checked === "boolean" && checked === true) {
      case true:
        ds.push(_id);
        break;
      default:
        ds = ds.filter((id) => id !== _id);
        break;
    }
    setDeleteIds(ds);
  };

  const submit = async () => {
    try {
      if (selectedDevices?.length < 1) {
        throw Error("Please complete the form");
      }

      const devices = selectedDevices.map(({ product, quantity }: any) => ({
        productId: product.productId,
        quantity,
      }));
      const data: IStockItems = {
        adminId: user._id!,
        products: devices,
        shopId: '65c69cc846f0844d2c4b9b55',
      };

      if (devices?.length < 1) {
        throw Error("Quantity is less than one");
      }

      setLoading(true);
      console.log(data);
      const response = await $service.addStockRequest!(data);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast("Stock request successfully");
      discharge({ type: "STOCK_REQUEST_MODAL", stockRequest: false });
      dispatch(getAllStockItems());
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };


  return (
    <Dialog open={stockRequest} onClose={handleClose} maxWidth="md">
      <DialogHeader className="text-lg">Request Stocks</DialogHeader>
      <DialogContent sx={{ width: "700px" }}>
        <form>
          <div className="mb-2 ">
            <Typography className="text-sm font-semibold">
              Select Products
            </Typography>
            <Autocomplete
  disablePortal
  id="combo-box-demo"
  sx={{ width: "100%" }}
  options={products?.map((d) => ({
    label: `${d.name} GHS${d.price}`,
    id: d?._id,
  }))}
  onChange={(event, selectedOption) => handleSelectedDeviceChange(selectedOption)}
  renderInput={(params) => <TextField {...params} />}
/>

          </div>
          <div className="mb-4">
            <Typography className="text-sm font-semibold">Quantity</Typography>
            <Input
              type="number"
              onChange={handleNumberChange}
            />
            {error && <div style={{ color: "red" }}>{error}</div>}
          </div>
          <div>
            <Button
              variant="filled"
              color="blue"
              className="mr-3"
              onClick={addDevice}
              // disabled={!formValid}
            >
              Add Device
            </Button>
            <Button color="red" onClick={removeDevice}>
              Remove
            </Button>
          </div>
          <div>
            <div className="relative overflow-x-auto mt-3">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Select
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Device Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedDevices.map((d: any) => (
                    <tr className="bg-white border-b " key={d?._id}>
                      <td>
                        <input
                          checked={deleteIds.includes(d?.product?.name)}
                          type="checkbox"
                          onChange={(e) =>
                            onDelete(
                              d._id,
                              e.currentTarget.checked
                            )
                          }
                        />
                      </td>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap e"
                      >
                        {d?.product?.name}
                      </th>
                      <td className="px-6 py-4">{d.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogFooter>
        {isLoading ? (
          <>
            <Button variant="filled" color="blue-gray">
              Loading...
            </Button>
          </>
        ) : (
          <>
            <Button variant="filled" color="blue" className="" disabled={selectedDevices?.length <=0} onClick={submit}>
              Submit Request
            </Button>
          </>
        )}

        <Button color="red" className="mx-3" onClick={handleClose}>
          Cancel
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default StockRequestModal;



import { Routes, Route } from "react-router-dom";
import DashboardNavbar from "../component/layout/DashboardNavbar";
import Sidenav from "../component/layout/Sidenav";
import Footer from "../component/layout/Footer";
import Logo from "../Assets/images/logo.png";
import AddJobCard from "../component/element/jobcards/AddJobCard";
import { useDispatch, useSelector } from "react-redux";
import { routex } from "../Routes/routes";
import { useEffect } from "react";
import { getAccessoryRequest, getAllPartnerSales, getAllStockItems, getCustomers, getDevices, getInventory, getJobcards, getOrders } from "../state/app.state";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const { roles, user } = useSelector((reducer: any)=>reducer.state);
  useEffect(()=> {
    dispatch(getAllStockItems(roles));
    dispatch(getAccessoryRequest());
    dispatch(getDevices());
    dispatch(getJobcards());
    dispatch(getCustomers());
    dispatch(getInventory(roles));
    dispatch(getOrders({}))
    dispatch(getAllPartnerSales());
  }, [dispatch])


  const getMenu = () => {
    try {
      return roles.menu ?  routex[roles.menu] : []
    } catch (err) {
      return []
    }}

  return (
    <div className="min-h-screen bg-blue-gray-50/50">
      <AddJobCard />
      <Sidenav routex={getMenu()} brandImg={Logo} />
      <div className="p-4 xl:ml-80">
        <DashboardNavbar />
        <Routes>
          {getMenu().map(({ pages, layout }: any) => {
            return (
              layout === "katcare-admin" &&
              pages.map(({ path, element }: any) => (
                <Route path={path} element={element} key={path} />
              ))
            );
          })}
        </Routes>
        <div className="text-blue-gray-600">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;

import React, { useState, useEffect } from 'react';
import DashboardLayout from '../component/layout/DashboardLayout';
import {
  Card,
  CardBody,
  Input,
  Typography,
  Textarea,
  Button,
} from '@material-tailwind/react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../state/app.reducer';
import { getCategory, getProducts } from '../state/app.state';
import { IProducts } from '../models/app.model';
import $service from '../services/app.service';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const AddProductPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { categorys } = useSelector((reducer: IStore) => reducer.state);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const navigation = useNavigate();

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getCategory());
  }, []);

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const base64Image = await covertBase64(file);
        setValue('image', base64Image);
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    }
  };

  const covertBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        // setImagePreview(fileReader.result)
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onSubmit = async (data: any) => {
    try {
      if (
        !data.name ||
        !data.category ||
        !data.specification ||
        !data.color ||
        !data.price ||
        !data.image
      ) {
        throw Error('Please fill the form completely');
      }

      const base64Image = await covertBase64(data.image[0]);
      setImagePreview(base64Image);
      const payload: Partial<IProducts> = {
        name: data.name,
        category: data.category,
        specification: data.specification,
        color: data.color,
        price: data.price,
        image: base64Image,
      };

      setLoading(true);
      const response = await $service.addProducts!(payload);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast.success(response.message);
      dispatch(getProducts());
      navigation('/products');
    } catch (err) {
      setLoading(false);
      toast.error((err as Error).message);
    }
  };

  const { register, handleSubmit, control, setValue, watch } = useForm<any>();
  return (
    <DashboardLayout>
      <div className='my-12'>
        <Card className='p-5'>
          <CardBody>
            <div className='py-5 px-3'>
              <Typography variant='h4'>
                Add new product to shop on the katcare mobile app
              </Typography>
            </div>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='grid grid-cols-3 gap-6 py-6'>
                <div className='flex flex-col'>
                  <div className='flex items-center justify-center w-full'>
                    <label
                      htmlFor='dropzone-file'
                      className='flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  '
                    >
                      <div className='flex flex-col items-center justify-center pt-5 pb-6'>
                        {imagePreview ? (
                          <>
                            <img
                              src={imagePreview}
                              alt='upload product'
                              className='w-32 h-32 mb-4'
                            />
                          </>
                        ) : (
                          <svg
                            className='w-8 h-8 mb-4 text-gray-500'
                            aria-hidden='true'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 20 16'
                          >
                            <path
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                            />
                          </svg>
                        )}
                        <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                          <span className='font-semibold'>Click to upload</span>{' '}
                          or drag and drop
                        </p>
                        {!watch('image') || watch('image').length === 0 ? (
                          <p className='text-xs text-gray-500 dark:text-gray-400'>
                            PNG or JPG
                          </p>
                        ) : (
                          <strong className='text-center '>{watch('image')?.[0]?.name}</strong>
                        )}
                      </div>
                      <input
                        id='dropzone-file'
                        type='file'
                        className='hidden'
                        {...register('image')}
                      />
                    </label>
                  </div>
                </div>
                <div className='col-span-2'>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='mb-2'>
                      <Typography className='text-sm font-semibold'>
                        Product Name
                      </Typography>
                      <Input {...register('name')} />
                    </div>
                    <div className='mb-2'>
                      <Typography className='text-sm font-semibold'>
                       Device Category
                      </Typography>
                      <Controller
                        name='category'
                        control={control}
                        defaultValue='' // Set the default value for the select input
                        // rules={{ required: 'This field is required' }} // Add any validation rules
                        render={({ field }: any) => (
                          <select
                            {...field}
                            className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                          >
                            <option value=''> Choose Device</option>
                            {categorys.map((category: any) => (
                              <option value={category?._id}>
                                {category?.name}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                    </div>
                    <div className='mb-2 col-span-2'>
                      <Typography className='text-sm font-semibold'>
                        Specifications
                      </Typography>
                      <Textarea {...register('specification')} />
                    </div>
                    <div className='mb-2'>
                      <Typography className='text-sm font-semibold'>
                        Price(in Ghs)
                      </Typography>
                      <Input {...register('price')} />
                    </div>

                    <div className='mb-2'>
                      <Typography className='text-sm font-semibold'>
                        Color
                      </Typography>
                      <Controller
                        name='color'
                        control={control}
                        defaultValue='blue' // Set the default value for the select input
                        // rules={{ required: 'This field is required' }} // Add any validation rules
                        render={({ field }: any) => (
                          <select
                            {...field}
                            className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                          >
                               <option value=''>Choose Color</option>
                            <option value='blue'>Blue</option>
                            <option value='black'>Black</option>
                            <option value='sliver'>Sliver</option>
                            <option value='none'>No Color</option>
                          </select>
                        )}
                      />
                    </div>
                    <div className='mb-2'>
                      <Button
                        className='w-full'
                        variant='filled'
                        color='blue'
                        type='submit'
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default AddProductPage;

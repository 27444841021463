import { Button, CardFooter, Typography } from '@material-tailwind/react';
import { useState, useRef, useEffect } from 'react';
import {
  Table as ReactTable,
  Column,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { DeviceItemElementProps } from '../../../data/types';
import Input from '@material-tailwind/react/components/Input';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';


const columnHelper = createColumnHelper<Partial<DeviceItemElementProps>>();

function Filter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: ReactTable<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);
  const columnFilterValue = column.getFilterValue();

  return firstValue === 'buttons' ? null : typeof firstValue === 'number' ? (
    <div
      style={{
        display: 'grid',
        gap: '1rem',
        gridTemplateColumns: 'repeat(2, 1fr)',
      }}
    >
      <Input
        type='number'
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder={`Max`}
        className='w-36 border shadow rounded'
      />
    </div>
  ) : (
    <Input
      type='text'
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className='w-36 border shadow rounded'
    />
  );
}

export default function DeviceTables(props: {
  tableData: Partial<DeviceItemElementProps>[];
  trigger: any;
  onClick?: any;
}) {
  const generatePDFtoPrint = useReactToPrint({
    content: () => componentToPrint.current,
  });
  useEffect(() => {
    if (props.trigger) {
      generatePDFtoPrint();
    }
  }, [props.trigger, generatePDFtoPrint]);
  const { categorys} = useSelector((reducer: any) => reducer.state);

  const { tableData } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor('category', {
      id: 'category',
      header: () => (
        <Typography
          variant='small'
          className='text-[11px] font-bold uppercase text-blue-gray-400 mt-[1.5rem]'
        >
          Device Name
        </Typography>
      ),
      cell: (info) => (
        <Typography
          variant='small'
          color='blue-gray'
          className='text-xs font-semibold text-blue-gray-600'
        >
          {info.getValue()}
        </Typography>
      ),
     
    }),
    columnHelper.accessor('model', {
      id: 'model',
      header: () => (
        <Typography
          variant='small'
          className='text-[11px] font-bold uppercase text-blue-gray-400 mt-[1.5rem]'
        >
          Type
        </Typography>
      ),
      cell: (info) => (
        <Typography
          variant='small'
          color='blue-gray'
          className='text-xs font-semibold text-blue-gray-600'
        >
          {info.getValue()}
        </Typography>
      ),
    }),

    columnHelper.accessor('serialNumber', {
      id: 'serialNumber',
      header: () => (
        <Typography
          variant='small'
          className='text-[11px] font-bold uppercase text-blue-gray-400 mt-[1.5rem]'
        >
          Serial Number
        </Typography>
      ),
      cell: (info) => (
        <Typography
          variant='small'
          color='blue-gray'
          className='text-xs font-semibold text-blue-gray-600'
        >
          {info.getValue()}
        </Typography>
      ),
    }),
    columnHelper.accessor('distributionDate', {
      id: 'distributionDate',
      header: () => (
        <Typography
          variant='small'
          className='text-[11px] font-bold uppercase text-blue-gray-400 mt-[1.5rem]'
        >
          Distribution Date
        </Typography>
      ),
      cell: (info) => (
        <Typography
          variant='small'
          color='blue-gray'
          className='text-xs font-semibold text-blue-gray-600'
        >
          {info.getValue()}
        </Typography>
      ),
    }),

    // columnHelper.accessor('Buttons', {
    //   id: 'buttons',
    //   header: () => (
    //     <Typography
    //       variant='small'
    //       className='text-[11px] font-bold uppercase text-blue-gray-400 mt-[1.5rem]'
    //     >
    //       {' '}
    //     </Typography>
    //   ),
    //   cell: (info) => {
    //     return (
    //       <Link to={`/stock-details/${info.row.original._id}`}>
    //         <Button color='blue' className='mr-[2px] p-3'>
    //           <FaEye />
    //         </Button>
    //       </Link>
    //     );
    //   },
    // }),
  ];

  const componentToPrint = useRef(null);

  const [data] = useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const {
    getCanPreviousPage,
    getCanNextPage,
    previousPage,
    nextPage,
    setPageIndex,
    getPageCount,
    getRowModel,
  } = table;
  return (
    <div id='printablediv'>
      <Box sx={{ overflow: 'auto' }}>
        <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
          <Table ref={componentToPrint}>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            fontSize: { sm: '10px', lg: '12px' },
                            color: 'gray.400',
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' �',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                          {header.column.getCanFilter() &&
                          header.getContext().header.id !== 'buttons' ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <>
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        </>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <CardFooter>
          <div className='flex justify-between items-center justify-items-center place-content-center '>
            <Typography variant='paragraph' className='text-sm mr-[1.5rem]'>
              Page{' '}
              <strong>
                {table.getState().pagination.pageIndex + 1} of {getPageCount()}
              </strong>{' '}
            </Typography>
            <ul className='flex justify-between items-center'>
              <div className='w-32 mr-[-2.5rem]'>
                <select
                  className='text-sm  font-light p-[.4rem] rounded-md'
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <li className='flex flex-row items-center'>
                <button
                  className='mx-1 px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-full'
                  disabled={!getCanPreviousPage()}
                  onClick={() => setPageIndex(0)}
                >
                  {'<<'}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!getCanPreviousPage()}
                  className='mx-1 px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-full'
                >
                  <span className='sr-only'>Previous</span>
                  <svg
                    className='w-5 h-5'
                    aria-hidden='true'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
                      clipRule='evenodd'
                    ></path>
                  </svg>
                </button>
              </li>
              <li className='flex flex-row items-center justify-items-center place-content-center'>
                <button
                  onClick={() => nextPage()}
                  disabled={!getCanNextPage()}
                  className='mx-1 px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-full'
                >
                  <span className='sr-only'>Next</span>
                  <svg
                    className='w-5 h-5'
                    aria-hidden='true'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                      clipRule='evenodd'
                    ></path>
                  </svg>
                </button>
                <button
                  className='mx-1 px-3 py-2 mr-[1.5rem] leading-tight text-gray-500 bg-white border border-gray-300 rounded-full'
                  disabled={!getCanNextPage()}
                  onClick={() => setPageIndex(getPageCount() - 1)}
                >
                  {'>>'}
                </button>
                <Typography
                  variant='paragraph'
                  className='flex items-center gap-1 text-sm'
                >
                  | Go to page:
                  <Input
                    type='number'
                    className=' '
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      setPageIndex(page);
                    }}
                  />
                </Typography>
                <Typography variant='paragraph' className='text-sm'>
                  {getRowModel().rows.length} Rows
                </Typography>
              </li>
            </ul>
          </div>
        </CardFooter>
      </Box>
    
    </div>

  );
}

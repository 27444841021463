import Chart from "react-apexcharts";
import { jobCardData } from "./Data/jobCardData";
const JobCardChart = () => {
    return (
        <>
        <Chart
            options={jobCardData.options}
            series={jobCardData.series}
            type="line"
            width="400"
        />
        </>
    )
}
export default JobCardChart;
import { Routes, Route, useParams} from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Typography,
  Progress,
} from "@material-tailwind/react";
import DetailPageSkeleton from "../component/layout/DetailPageSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import $service from "../services/app.service";
import {  getAllStockItems } from "../state/app.state";
import { toast } from "react-toastify";
import WithFunctionGuard from "../guards/FunctionGuard";
import moment from "moment";
import DashboardLayout from "../component/layout/DashboardLayout";


const StockRequestDetails = () => {
  const { _id } = useParams<string>();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { roles, status } = useSelector((reducer: any) => reducer.state);
  const [stocks, setStocks] = useState<any>([]);

  

  useEffect(() => {
    getRequest();
    dispatch(getAllStockItems())
  }, [_id]);

  const transfer = async (id: any) => {
    try {
      const data = { id: _id! };
      setLoading(true);
      const response = await $service.approveStockRequest(data);
      if (!response.success) {
        throw Error(response.message);
      }
      getRequest();
    } catch (err) {
      setLoading(false);
      dispatch(getAllStockItems());
      toast((err as Error)?.message);

    }
  };

  const getRequest = async () => {
    try {
      let response = await $service.getStockItemsByPartnerId(_id!);
      if (!response.success) {
        throw Error(response.message);
      }
      setStocks(response.data);
      response = await $service.getStockItemsByPartnerId(_id!);
      if (response.success) {
        setStocks(response.data);
      }
    } catch (err) {}
  };


 const ApproveStockButton = WithFunctionGuard(() => (
    <Button onClick={transfer} 
    key={"approveStockButton"}
    >
      Approve Stock 
    </Button> 
 ))


  return (
 <DashboardLayout>
        <div className="my-12">
          {isLoading ? (
            <DetailPageSkeleton />
          ) : (
            <div>
              <Card
                className="w-full h-56 relative mb-24"
                color="blue"
                variant="gradient"
              >
                <CardHeader className="h-28 relative left-0 top-40">
                  <div className="flex flex-row justify-end p-3 items-center gap-5">
                    <div className="flex mt-4">
                <ApproveStockButton />
                    {/* <Button onClick={transfer} >Approve Stock</Button> */}
                    </div>
                  </div>
                </CardHeader>
              </Card>

             

              <div className="relative ">
              {stocks?.map((items:any) => (
                <div key={items?._id}>
                    <>
                    {/* {Object.entries(items).map(([key,value]:any) => {
                     
                      console.log({key,value})
                        return <div >

                        </div>
})} */}
                   {Object.entries(items).map(([key,value]:any) => (
                        <Card className="w-full my-3"  key={key}>
                         <CardBody>
                            <div className="grid grid-cols-4 gap-8">
                         <div className="flex flex-col gap-3" >
          <h2 className=" text-gray-500  ">
            Device:
          </h2>
          <div className="inline-flex items-center text-base font-semibold text-gray-900 " > 
         {value?.model}
          </div>
          <div className="inline-flex items-center text-base font-semibold text-gray-900 " > 
         {value?.category}
          </div>
          <div className="inline-flex items-center text-base font-semibold text-gray-900 " > 
         {value?.color || 'No Color'}
          </div>
          <div className="inline-flex items-center text-base font-semibold text-gray-900 " > 
         {value?.price}
          </div>
        </div>
        <div className="flex flex-col gap-3">
        <h2 className=" text-gray-500  ">
            Quantity:
          </h2>
          <div className="inline-flex items-center text-base font-semibold text-blue-500 " > 
         {value?.quantity}
          </div>
        </div>
        <div className="flex flex-col gap-3">
        <h2 className=" text-gray-500  ">
            Date of Request:
          </h2>
          <div className="inline-flex items-center text-base font-semibold text-blue-500 " > 
         {moment(value?.createdAt).format('DD/MM/YYYY')}
          </div>
        </div>
        <div className="flex flex-col gap-3">
        <h2 className=" text-gray-500 ">
           Stocks
          </h2>
          <div className="inline-flex items-center text-base font-semibold text-blue-500 " > 
        {value?.inventory || 'N/A'}
          </div>

          <div className="inline-flex items-center text-base font-semibold text-blue-500 " > 
        {value?.status || 'N/A'}
          </div>
          <div className="inline-flex items-center text-base font-semibold text-blue-500 " > 
        {value?.progress || 'N/A'}
          </div>
        </div>
        </div>
                         </CardBody>
                        </Card>
                    ))}
                    </>
                    </div>
              ))}
                </div>

{/* <div className="relative overflow-x-auto">
    <table className="w-full text-sm text-left text-gray-500 ">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Product name
                </th>
                <th scope="col" className="px-6 py-3">
                    Color
                </th>
                <th scope="col" className="px-6 py-3">
                    Category
                </th>
                <th scope="col" className="px-6 py-3">
                    Price
                </th>
            </tr>
        </thead>
        <>
        {stocks?.map((items:any) => (
        <tbody key={items?._id}>
            
               {Object.entries(items).map(([key,value]:any) => (
            <tr className="bg-white border-b" key={key}>
  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  {value?._id}
                </th>
                <td className="px-6 py-4">
                {value?.quantity}
                </td>
                <td className="px-6 py-4">
                {value?.stockId?.inventory}
                </td>
                <td className="px-6 py-4">
                {value?.createdAt}
                </td>
            </tr>
               ))}
            
      
            </tbody>
              ))}
            </>
        </table>
        </div> */}

              {/* <div className="grid grid-cols-2 my-8 gap-8 mt-52">
                {stocks?.map((items:any) => (
                    <Card className="w-full" key={items?.id}>
                        {Object.entries(items).map(([key,value]:any) => (
  <CardBody >
  <div className="">
    <Typography
      variant="h5"
      className="text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white"
    >
      Job Card Details
    </Typography>
    <ul className="list-none">
      <li className="py-3 sm:py-4">
        <div className="grid grid-cols-2" key={key}>
          <h2 className=" text-gray-500 truncate ">
            Job Name:
          </h2>
          <div className="inline-flex items-center text-base font-semibold text-gray-900 " > 
         {value?._id}
          </div>
        </div>
      </li>
      <li className="py-3 sm:py-4">
        <div className="grid grid-cols-2">
          <h2 className=" text-gray-500 truncate ">
            Description:
          </h2>
          <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
            {accessory?.jobcardId?.description}
          </div>
        </div>
      </li>
      <li className="py-3 sm:py-4">
        <div className="grid grid-cols-2">
          <h2 className=" text-gray-500 truncate ">
            Device Progress:
          </h2>
          <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
            <Progress
              value={accessory?.jobcardId?.progress}
              color={
                accessory?.jobcardId &&
                accessory?.jobcardId?.progress <= 50
                  ? "red"
                  : "green"
              }
              label={true}
            />
          </div>
        </div>
      </li>
      <li className="py-3 sm:py-4">
        <div className="grid grid-cols-2">
          <h2 className=" text-gray-500 truncate ">
            JobCard Status:
          </h2>
          <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
            {accessory?.jobcardId?.status}
          </div>
        </div>
      </li>
    </ul>
  </div>
</CardBody>
                        ))

                        }
                
                </Card>
                ))

                }
                

                <Card>
                  <CardBody>
                    <div className="">
                      <Typography
                        variant="h5"
                        className="text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white"
                      >
                        Technician
                      </Typography>
                      <ul className="list-none">
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Full Name:
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {accessory?.adminId?.first_name}{" "}
                              {accessory?.adminId?.last_name}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">Email:</h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {accessory?.adminId?.email}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Company:
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {accessory?.adminId?.company_name}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">Role:</h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {accessory?.adminId?.role}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </div> */}

              {/* Customer Job Cards */}
              {/* <>
                {devices && devices.length === 0 ? (
                  <div className="flex flex-col items-center py-4 justify-center">
                    <NoSymbolIcon className="w-8 h-8 text-gray-600" />
                    <p className="text-lg mt-4 text-gray-600">
                      No customer job cards available
                    </p>
                  </div>
                ) : (
                  <Card shadow={false}>
                    <CardBody>
                      <div className="py-2 pb-3">
                        <h5 className="font-bold text-lg  text-gray-900">
                          Store Items
                        </h5>
                      </div>
                      <div className="relative overflow-x-auto shadow-sm sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Device Name
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Serial Number
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Category
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Type
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Price
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Status
                              </th>
                              <th scope="col" className="px-6 py-3">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>

                          {devices && devices.map(
                            (storeItem) => (
                              <tbody key={storeItem?._id}>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                  <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                  >
                                    {storeItem?.deviceId?.model}
                                  </th>
                                  <td className="px-6 py-4">
                                    {storeItem?.deviceId?.serialNumber}
                                  </td>
                                  <td className="px-6 py-4 text-teal-600">
                                    {storeItem?.deviceId?.category}
                                  </td>
                                  <td className="px-6 py-4">
                                    {storeItem?.deviceId?.color}
                                  </td>
                                  <td className="px-6 py-4">
                                    {storeItem?.deviceId?.price}
                                  </td>

                                  <td className="px-6 py-4">
                                    {storeItem?.deviceId?.sold ? "sold"
                                      : "not sold"}
                                  </td>

                                  <td className="px-6 py-4 text-right">
                                    <Link
                                      to={`/stock-details/${storeItem.deviceId?._id}`}
                                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                      View
                                    </Link>
                                  </td>
                                </tr>
                              </tbody>
                            )
                          )}
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </> */}
            </div>
          )}
        </div>

       </DashboardLayout>
  );
};

export default StockRequestDetails;

const regions: any = {
  AHAFO: [
    'ASUTIFI SOUTH',
    'ASUTIFI NORTH',
    'TANO NORTH',
    'TANO SOUTH MUNICIPAL',
    'ASUNAFO SOUTH',
    'ASUNAFO NORTH',
  ],
  ASHANTI: [
    'OFORIKROM',
    'SEKYERE AFRAM PLAINS',
    'OLD TAFO MUNICIPAL',
    'SEKYERE CENTRAL',
    'SEKYERE EAST',
    'SEKYERE KUMAWU',
    'SEKYERE SOUTH',
    'SUAME MUNICIPAL',
    'KUMASI METROPOLITAN',
    'KWABRE EAST',
    'KWADASO',
    'MAMPONG MUNICIPAL',
    'OBUASI EAST',
    'OBUASI MUNICIPAL',
    'OFFINSO MUNICIPAL',
    'OFFINSO NORTH',
    'JUABEN MUNICIPAL',
    'KUMASI METROPOLITAN',
    '3 BOSOMTWE',
    'EJISU MUNICIPAL',
    'EJURA/SEKYEDUMASE',
    'ASOKORE MAMPONG',
    'ASOKWA MUNICIPAL',
    'ATWIMA NWABIAGYA',
    'ATWIMA NWABIAGYA NORTH',
    'ATWIMA-MPONUA',
    'ATWIMA-KWANWOMA',
    'BEKWAI MUNICIPAL',
    'BOSOME FREHO',
    'BOSOMTWE',
    'AHAFO ANO NORTH',
    'AHAFO ANO SOUTH WEST',
    'AKROFUOM',
    'AMANSIE SOUTH',
    'AMANSIE CENTRAL',
    'AMANSIE WEST',
    'ASANTE AKIM CENTRAL',
    'ASANTE AKIM NORTH',
    'ASANTE AKIM SOUTH',
    'ADANSI ASOKWA',
    'ADANSI NORTH',
    'ADANSI SOUTH',
    'AFIJA KWABRE NORTH',
    'AFIJA KWABRE SOUTH',
  ],
  BONO: [
    'TAIN',
    'WENCHI MUNICIPAL',
    'SUNYANI WEST',
    'BANDA',
    'BEREKUM EAST MUNICIPAL',
    'BEREKUM WEST',
    'DORMAA CENTRAL',
    'DORMAA EAST',
    'DORMAA WEST',
    'JAMAN NORTH',
    'JAMAN SOUTH',
    'SUNYANI MUNICIPAL',
  ],
  'BONO EAST': [
    'PRU-WEST',
    'SENE EAST',
    'SENE WEST',
    'TECHIMAN MUNICIPAL',
    'TECHIMAN NORTH',
    'ATEBUBU AMANTIN',
    'KINTAMPO NORTH',
    'KINTAMPO SOUTH',
    'NKORANZA NORTH',
    'PRU EAST',
    'NKORANZA SOUTH',
  ],
  CENTRAL: [
    'UPPER DENKYIRA EAST',
    'TWIFO HEMANG LOWER DENKYIRA',
    'UPPER DENKYIRA WEST',
    'TWIFO ATTI MORKWA',
    'GOMOA EAST',
    'GOMOA WEST',
    'KOMENDA EDINA EGUAFO ABREM',
    'MFANTSIMAN',
    'ASSIN FOSO',
    'ASSIN NORTH',
    'ASSIN SOUTH',
    'AWUTU SENYA EAST',
    'AWUTU SENYA',
    'AWUTU SENYA WEST',
    'CAPE COAST',
    'EFFUTU MUNICIPAL',
    'EKUMFI DISTRICT',
    'GOMOA CENTRAL',
    'ABURA ASEBU KWAMANKESE',
    'AGONA WEST',
    'AJUMAKO/ENYAN/ESSIAM',
    'ASIKUMA ODOBEN BRAKWA',
  ],
  EASTERN: [
    'WEST AKIM',
    'YILO KROBO',
    'OKERE DISTRICT',
    'SUHUM',
    'UPPER MANYA KROBO',
    'UPPER WEST AKIM',
    'LOWER MANYA KROBO',
    'KWAHU WEST',
    'NEW JUABEN NORTH MUNICIPAL',
    'NEW JUABEN SOUTH  MUNICIPAL',
    'NSAWAM ADUAGYIRI',
    'KWAHU AFRAM PLAINS NORTH',
    'KWAHU AFRAM PLAINS SOUTH',
    'KWAHU EAST',
    'KWAHU SOUTH',
    'DEMKYEMBOUR',
    'DENKYEMBOUR',
    'FANTEAKWA NORTH',
    'FANTEAKWA SOUTH',
    'KWAEBIBIREM',
    'ATIWA WEST',
    'AYENSUANO',
    'BIRIM CENTRAL',
    'BIRIM NORTH',
    'BIRIM SOUTH',
    'AKUAPIM SOUTH',
    'AKYEMANSA',
    'ASUOGYAMAN',
    'ATIWA EAST',
    'ABUAKWA NORTH',
    'ABUAKWA SOUTH',
    'ACHIASE',
    'AKROSO-MANSO-ASANE',
    'AKUAPEM NORTH',
  ],
  'GREATER ACCRA': [
    'WEIJA GBAWE',
    'OKAIKWEI NORTH',
    'SHAI OSUDOKU/DODOWA',
    'TEMA METROPOLITAN',
    'TEMA WEST',
    'LADEKOTOPON',
    'NINGO PRAMPRAM',
    'LEDZOKUKU MUNICIPAL',
    'OKAIKWEI NORTH',
    'GA WEST DISTRICT',
    'KORLE KLOTTEY',
    'KPONE KATAMANSO',
    'KROWOR',
    'LA NKWATANANG MUNICIPAL',
    'GA CENTRAL',
    'GA EAST DISTRICT',
    'GA NORTH',
    'GA SOUTH',
    'ADENTA MUNICIPAL',
    'ASHAIMAN MUNICIPAL',
    'AYAWASO EAST',
    'AYAWASO WEST',
    'AYAWASO NORTH',
    'ABLEKUMA WEST',
    'ACCRA METROPOLITAN',
    'ADA WEST',
    'ADA EAST',
    'ABLEKUMA CENTRAL',
    'ABLEKUMA NORTH',
  ],
  'NORTH EAST': [
    'MAMPRUGU MOADURI',
    'WEST MAMPRUSI MUNICIPAL',
    'BUNKPRUNKGU NAPANDURE',
    'CHEREPONI',
    'EAST MAMPRUSI',
  ],
  NORTHERN: [
    'TOLON DISTRICT',
    'YENDI',
    'ZABZUGU',
    'SAGNARIGU MUNICIPAL',
    'SAVELUGU MUNICIPAL',
    'TAMALE METROPOLITAN',
    'TATALE-SANGULI',
    'NANTON',
    'NANUMBA NORTH MUNICIPAL',
    'SABOBA',
    'NANUMBA SOUTH',
    'SAGNARIGU MUNICIPAL',
    'GUSHEGU',
    'KARAGA',
    'KPANDAI',
    'KUMBUNGU',
    'MION',
  ],
  OTI: [
    'NKWANTA NORTH',
    'NKWANTA SOUTH',
    'JASIKAN',
    'KADJEBI',
    'KRACHI EAST',
    'KRACHI NCHUMURU',
    'KRACHI WEST',
    'BIAKOYE',
    'GUAN',
  ],
  SAVANNAH: [
    'WEST GONJA MUNICIPAL',
    'EAST GONJA',
    'NORTH GONJA',
    'SAWLA-TUNA-KALBA',
    'BOLE',
    'CENTRAL GONJA',
  ],
  'UPPER EAST': [
    'TEMPANE',
    'KASENNA NANKANA WEST',
    'KASSENA-NANKANA MUNICIPAL',
    'PUSIGA',
    'TALENSI DISTRICT',
    'BOLGATANGA MUNICIPAL',
    'BUILSA NORTH',
    'BONGO',
    'BUILSA SOUTH',
    'GARU DISTRICT',
    'BAWKU MUNICIPAL',
    'BAWKU WEST',
    'BINDURI',
    'BOLGA EAST',
  ],
  'UPPER WEST': [
    'WA MUNICIPAL',
    'WA WEST',
    'NADOWLI KALEO',
    'NANDOM',
    'SISSALA EAST',
    'SISSALA WEST',
    'WA EAST',
    'WA MUNICIPAL',
    'DAFIAMA BUSSIE ISSA',
    'JIRAPA',
    'LAMBUSSUE',
    'LAWRA',
  ],
  VOLTA: [
    'HOHOE',
    'KETA MUNICIPAL',
    'KETU NORTH',
    'KETU SOUTH',
    'KETU SOUTH MUNICIPAL',
    'KPANDO',
    'NORTH DAYI',
    'NORTH TONGU',
    'SOUTH DAYI',
    'SOUTH TONGU',
    'AGOTIME ZIOPE',
    'AKATSI NORTH',
    'AKATSI SOUTH',
    'ANLOGA',
    'CENTRAL TONGU',
    'HO MUNICIPAL',
    'HO WEST',
    'ADAKLU',
    'AFADZATO',
  ],
  WESTERN: [
    'AHANTA WEST',
    'EFFIA/ KWESIMINTSIM',
    'ELLEMBELLE',
    'JOMORO',
    'PRESTEA HUNI-VALLEY MUNICIPAL',
    'NZEMA EAST',
    'SEKONDI TAKORADI',
    'SHAMA',
    'TARWA NSUAEM',
    'WASSA AMENFI CENTRAL',
    'WASSA AMENFI EAST',
    'WASSA EAST',
  ],
  'WESTERN NORTH': [
    'AOWIN',
    'BIA EAST',
    'BIA WEST',
    'BIBIANI ANHWIASO BEKWAI',
    'BODI',
    'JUABOSO',
    'SEFWI AKONTOMBRA',
    'SEFWI WIASO',
    'SUAMAN',
  ],
};

export default regions;

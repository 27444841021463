import { Fragment, useEffect, useState } from 'react';
import {
  Button,
  DialogHeader,
  DialogFooter,
  Input,
  Typography,
  Select,
  Option,
  Textarea,
} from '@material-tailwind/react';
import { useMaterialTailwindController } from '../../../context';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../state/app.reducer';
import $service from '../../../services/app.service';
import { toast } from 'react-toastify';
import { getCategory, getDevices } from '../../../state/app.state';
import { Dialog, DialogContent } from '@mui/material';
import { addDeviceProps } from '../../../data/types';
import { IDeviceAccessorys, IDevices } from '../../../models/app.model';


const AddDeviceAccessory = () => {
  const { controller, discharge } = useMaterialTailwindController();
  const { addDeviceAccessory } = controller;
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { categorys } = useSelector((reducer: IStore) => reducer.state);

  useEffect(() => {
    dispatch(getDevices());
    dispatch(getCategory());
  }, []);

  const onSubmit = async (data: IDeviceAccessorys) => {
    try {
      console.log(data);
      if (
        !data.category ||
        !data.name ||
        !data.quantity ||
        !data.price ||
        !data.specification 
        // !data.image
        // !data.price
      ) {
        throw Error('Please fill the form completely');
      }
      const payload: Partial<IDeviceAccessorys> = {
        category: data.category,
        name: data.name,
        quantity: data.quantity,
        price: data.price,
        specification: data.specification,
        // image: data.image,
      };
      setLoading(true);
      const response = await $service.addDeviceAccessories!(payload);
      console.log(payload);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      discharge({ type: 'ADD_DEVICEACCESSORY', addDeviceAccessory: false });
      toast.success(response.message);
      dispatch(getDevices());
    } catch (err) {
      setLoading(false);
      toast.error((err as Error).message);
    }
  };

  const { register, handleSubmit, control } = useForm<any>();
  const handleOpen = () =>
    discharge({ type: 'ADD_DEVICEACCESSORY', addDeviceAccessory: false });

  return (
    <Fragment>
      <Dialog
        open={addDeviceAccessory}
        onClose={handleOpen}
        className='h-screen relative '
      >
        <DialogHeader className='text-lg'>Add New Devices</DialogHeader>
        <DialogContent sx={{ width: '500px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-2'>
              <Typography className='text-sm font-semibold'>
                Device Name
              </Typography>
              <Controller
                name='category'
                control={control}
                defaultValue='SM1' 
                rules={{ required: 'This field is required' }} 
                render={({ field }) => (
                  <Select {...field}>
                    {categorys.map((category: any) => (
                      <Option
                        key={category?._id}
                        value={category?._id}
                        className='text-uppercase'
                      >
                        {category?.name}
                      </Option>
                    ))}
                  </Select>
                )}
              />
            </div>
            <div className='mb-2 '>
              <Typography className='text-sm font-semibold'>
                Accessory Name
              </Typography>
              <Input {...register('name')} />
            </div>
            <div className='mb-2'>
              <Typography className='text-sm font-semibold'>
                Quantity
              </Typography>
              <Input {...register('quantity')} />
            </div>
            <div className='mb-2'>
              <Typography className='text-sm font-semibold'>Price</Typography>
              <Input {...register('price')} />
            </div>

            <div className='mb-2'>
              <Typography className='text-sm font-semibold'>
                Specification
              </Typography>
              <Textarea {...register('specification')} />
            </div>

            {/* <div className='mb-2'>
                <Typography className='text-sm font-semibold'>
                  Company
                </Typography>
                <Input
                    {...register("company", { required: true,  })}
                  />
              </div> */}

            <Button
              type='submit'
              variant='filled'
              color='blue'
              className='w-full mt-2'
            >
              {isLoading ? 'Loading' : 'Add Accessory'}
            </Button>
          </form>
        </DialogContent>
        <DialogFooter>
          <Button
            variant='filled'
            color='red'
            onClick={handleOpen}
            className='mr-1'
          >
            Cancel
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default AddDeviceAccessory;

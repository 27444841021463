import React from 'react'
import { Skeleton } from '@mui/material'
import { CardBody, Card, CardHeader,  } from '@material-tailwind/react'

const DetailPageSkeleton = () => {
  return (
    <div className="">
          <Card>
         <CardHeader className="mb-8 p-6">
         <Skeleton variant="rounded" width={'100%'} height={50} />
         </CardHeader>
         <CardBody className="grid grid-cols-2 my-8 gap-8">
         <Skeleton variant="rounded" width={'100%'} height={70} style={{ marginBottom: 6 }} />
         <Skeleton variant="rounded" width={'100%'} height={70}  style={{ marginBottom: 6 }}/>
         <Skeleton variant="rounded" width={'100%'} height={70} style={{ marginBottom: 6 }} />
         <Skeleton variant="rounded" width={'100%'} height={70} style={{ marginBottom: 6 }}/>
       
         </CardBody>
         
         </Card>
    </div>
  )
}

export default DetailPageSkeleton
import { useEffect, Fragment, useState } from "react";
import {
  Card,
  CardHeader,
  Button,
  Typography,
  CardBody,
} from "@material-tailwind/react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";
import { getAllPartnerSales, getOrders } from "../state/app.state";
import AddImportDevice from "../component/element/Stocks/AddImportDevice";
import WithFunctionGuard from "../guards/FunctionGuard";
import StockRequestModal from "../component/element/Stocks/StockRequestModal";
import { Link } from "react-router-dom";
import SalesCard from "../component/element/SalesCard";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DateRange, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import {  Select, FormControl, MenuItem } from "@mui/material";
import PartnerSalesTable from "../component/element/tables/PartnerSalesTable";


const PartnerSalesPage = () => {
  const dispatch = useDispatch();
  const { sales  } = useSelector(
    (reducer: IStore) => reducer.state
  );
  const [trigger, setTrigger] = useState(0);
  const [date, setDate] = useState<DateRange<any>>();
  const [status, setStatus] = useState<any>(null);

  useEffect(() => {
    dispatch(getAllPartnerSales());
  }, []);

  const filteredData = sales.map(
    (storelist: {
      deviceId: { model: any };
      quantity: any;
      status: any;
      createdAt: any;
    }) => {
      return {
        device: storelist.deviceId?.model,
        quantity: storelist.quantity,
        status: storelist.status,
        createdAt: storelist.createdAt,
      };
    }
  );

  const MakeButton = WithFunctionGuard(() => (
    <Link to="/sell" key={"make-sale"}>
      <Button variant="filled" color="blue">
        Sell
      </Button>
    </Link>
  ));

  const FilterBox = WithFunctionGuard(() => (
    <div className="grid grid-cols-4 gap-10 items-center pb-16" key={"filter-box"}>
      <div className="col-span-2">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateRangePicker", "DateRangePicker"]}>
            <DemoItem component="DateRangePicker">
              <DateRangePicker
                calendars={1}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </div>

      <div>
        <FormControl sx={{ width: 250 }}>
          <Select
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <MenuItem value="PENDING" className="uppercase p-2">
              {" "}
              Pending{" "}
            </MenuItem>
            <MenuItem value="CANCELLED" className="uppercase p-2">
              {" "}
              Cancelled{" "}
            </MenuItem>
            <MenuItem value="ALL" className="uppercase p-2">
              {" "}
              All{" "}
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="flex gap-3">
        <Button onClick={onFilter}>Search</Button>
        <Button color="amber" onClick={onReset}>
          Reset
        </Button>
      </div>
    </div>
  ));

  const SalesCardBox = WithFunctionGuard(()=> (
    <div key={"salescard-box"}>
    <SalesCard  />
    </div>
    
  ))

  const onFilter = () => {
    let data: any = {};
    if (date) {
      const dateArray: any = date?.map(
        (x) => new Date(x).toLocaleString().split(",")[0]
      );
      const [startDate, endDate] = dateArray;
      data = {
        startDate,
        endDate,
      };
    }

    if (status) {
      if (status !== "ALL") {
        data.status = status;
      }
    }

    dispatch(getOrders(data));
  };

  const onReset = () => {
    dispatch(getOrders({}));
  };

  return (
    <Fragment>
      <div className="mt-12">
        <StockRequestModal />
        <div>
          {/* {stocks.loading ? (
            <CustomSkeleton />
          ) : ( */}
          <>
            <div className="mt-12">
              <AddImportDevice />

              <FilterBox />

              <div className="mb-20 grid gap-y-10 gap-x-2 md:grid-cols-1 xl:grid-cols-1">

                <SalesCardBox />
                
                <div></div>

                <div className="flex justify-end gap-4">
                  <MakeButton />
                  <CSVLink data={filteredData} filename={"stockrequest.csv"}>
                    <Button variant="gradient" color="blue">
                      Export CSV
                    </Button>
                  </CSVLink>
                  <Button
                    variant="outlined"
                    color="blue"
                    onClick={() => {
                      setTrigger((trigger) => trigger + 1);
                    }}
                  >
                    Print
                  </Button>
                </div>
              </div>

              <Card shadow={false}>
                <CardHeader
                  variant="gradient"
                  color="blue"
                  className="mb-8 p-4"
                >
                  <Typography variant="h6" color="white">
                    Sales
                  </Typography>
                </CardHeader>
                <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
             

<PartnerSalesTable tableData={sales} trigger={trigger}/>  
               
                  
                </CardBody>
              </Card>
            </div>
          </>
          {/* )} */}
        </div>
      </div>
    </Fragment>
  );
};

export default  PartnerSalesPage;

import React from 'react'
import ShowSerialNumber from '../component/element/Stocks/showSerialNumber'

const SerialNumberPage = () => {
  return (
    <div>
        <ShowSerialNumber />
    </div>
  )
}

export default SerialNumberPage
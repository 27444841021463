import { useEffect, Fragment, useState, useRef } from "react";
import CustomSkeleton from "../component/layout/CustomSkeleton";
// import { OfficersTablesProps } from "../../data/types";
import { FaEye } from "react-icons/fa";
import {
  Card,
  CardHeader,
  Button,
  Typography,
  CardBody,
} from "@material-tailwind/react";
import { useMaterialTailwindController } from "../context";
import { CSVLink } from "react-csv";
import OfficersTables from "../component/element/tables/OfficersTable";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";
import { getAdminRoles, getAdmins } from "../state/app.state";
import { useReactToPrint } from "react-to-print";
import AddPartner from "../component/element/admins/AddPartner";


const PartnerAdministratorsPage = () => {
  const dispatch = useDispatch();
  const { loading, admins } = useSelector((store: IStore) => store.state);
  const componentToPrint = useRef(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const { discharge } = useMaterialTailwindController();

  useEffect(() => {
    dispatch(getAdmins());
    dispatch(getAdminRoles());
  }, [dispatch]);

  useEffect(() => {
    const updatedPartners = admins.filter(officer => officer.role === 'KATCARE-PARTNER').map<any>((officer) => {
    //   if (officer.region === 'CENTRAL') {
    //     console.log(officer.district);
    // }
      return {
        key: officer._id,
        _id: officer._id,
        role: officer.role || "N/A",
        firstName: officer.first_name,
        lastName: officer.last_name,
        company: officer.company_name,
        phoneNumber: officer.mobile,
        region: officer.region,
        district:officer.district,
        Buttons: [
          {
            text: FaEye,
            onClick: () => console.log("Button 1 clicked"),
            disabled: false,
            link: "/admin-details/:_id",
          },
        ],
      };
    });

    setTableData(updatedPartners);
  }, [admins]);

  const filteredData = admins.map((officer) => {
    return {
      firstName: officer.first_name,
      lastName: officer.last_name,
      phoneNumber: officer.mobile,
      company: officer.company_name,
      region: officer.region,
      district:officer.district,
      digitalAddress:officer.digital_address,
     locationAddress:officer.locationAddress
 
    };
  });



  const generatePDFtoPrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  const onRef = (ref: any) => (componentToPrint.current = ref);

  return (
    <Fragment>
      <div className="mt-12">
        <ToastContainer />
        <div>
          {loading ? (
            <CustomSkeleton />
          ) : (
            <>
              <div className="mt-12">
              <AddPartner />
                <div className="mb-20 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
                  <div></div>
                  <div className="flex justify-end gap-3">
                  <Button variant="filled"
                     color="blue"
                     className=""
                     onClick={() => discharge({ type: "IMPORT_PARTNER", importPartner:false })}
                     >
                    Import Partners
                    </Button>

                    <CSVLink data={filteredData} filename={"officers.csv"}>
                      
                      <Button variant="gradient" color="blue"  className="w-38">
                        Export CSV
                      </Button>
                    </CSVLink>
                    <Button
                      variant="outlined"
                      color="blue"
                      onClick={generatePDFtoPrint}
                    >
                      Print
                    </Button>
                  </div>
                </div>

                <Card shadow={false}>
                  <AddPartner />
                  <CardHeader
                    variant="filled"
                    color="blue"
                    className="mb-8 p-4"
                  >
                    <Typography variant="h6" color="white">
                      All Katcare Partners
                    </Typography>
                  </CardHeader>
                  <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                    {tableData && tableData.length > 0 ? (
                      <OfficersTables tableData={tableData} tableRef={onRef} />
                    ) : (
                      <div className="">
                        <p>There is currently no data available for customer</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default PartnerAdministratorsPage;

"use client";
import React, { useEffect, useState } from "react";
import { Alert, Button, Typography } from "@material-tailwind/react";
// import {
//   ApproveJobCardRequest,
//   GetAllApprovalRequestsByKat,
//   getManagers,
// } from "../data/katcareapi";
import {
  ApprovalRequestsByKat,
  ApproveCustomerRequestProp,
  KatCareManager,
} from "../data/types";
import CustomSkeleton from "../component/layout/CustomSkeleton";
import { Modal, Label, Select } from "flowbite-react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";
import { getapprovals } from "../state/app.state";
import $service from "../services/app.service";
import { IDevices } from "../models/app.model";

export const REQUEST_ATTRIBUTES = {
  REQUEST_SUCCESS: "REQUEST_SUCCESS",
  REQUEST_LOADING: "REQUEST_LOADING",
  REQUEST_FAILURE: "REQUEST_FAILURE",
};
const ApprovalsPage = () => {
  const dispatch = useDispatch();
  const { loading, approvals } = useSelector(
    (reducer: IStore) => reducer.state
  );

  useEffect(() => {
    dispatch(getapprovals());
  }, []);

  
  return (
    <>
      <div className="my-12">
        <div className="flex flex-col gap-5">
          {loading ? (
            <CustomSkeleton />
          ) : (
            approvals &&
            approvals.length > 0 &&
            approvals.map((item, index) => {
              return <ApprovalsAlertComponent key={index} item={item} />;
            })
          )}
        </div>
      </div>
    </>
  );
};

const ApprovalsAlertComponent = ({ item }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [device, setDevice] = useState<Partial<IDevices>>({});

  useEffect(() => {
    loadDetails();
  }, [item]);

  const loadDetails = async () => {
    try {
      const { _id } = item.requestId?.deviceId;
      setLoading(true);
      const response = await $service.deviceDetails!(_id);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      setDevice(response.data.deviceId);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <Alert
        variant="gradient"
        color={item.approvedBy === null ? "blue" : "green"}
      >
        <div className="flex justify-between items-baseline">
          <div>
            <Typography variant="lead">{`${item?.requestId?.customerId?.firstName} ${item?.requestId?.customerId?.lastName}`}</Typography>
            <Typography variant="paragraph">{`${item?.requestId?.description}`}</Typography>
            {item.approvedBy !== null ? (
              <div>
                <div className="mb-[1.5rem]">
                  Approved By:{" "}
                  {`${item.approver?.first_name} ${item.approver?.last_name}`}
                </div>
                <div className="flex">
                  <div className="mr-[2rem]">{`${device?.model}`}</div>
                  <Link
                    className="text-[#4169E1]"
                    to={`/jobcard-details/${item?.requestId?._id}`}
                  >
                    Go to job card
                  </Link>
                </div>
              </div>
            ) : (
              <div className="flex flex-row gap-5 mt-5">
                <Button
                  color="green"
                  onClick={() => {
                    // SetCustomerJobCardRequestId(item.id);
                    setOpenModal(!openModal);
                  }}
                >
                  Accept
                </Button>
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <div className="mb-[1rem]">
              <Typography variant="h2" className="text-md font-bold">
                Device Name:
              </Typography>
              <Typography
                variant="lead"
                className="text-xl font-semibold text-gray-800"
              >
                {device?.model}
              </Typography>
            </div>
            <div className="">
              <Typography variant="h2" className="text-md font-bold">
                Serial #:
              </Typography>
              <Typography
                variant="lead"
                className="text-xl font-semibold text-gray-800"
              >
                {device?.serialNumber}
              </Typography>
            </div>
          </div>
        </div>
      </Alert>
      <ApprovalModal
        customerRequestId={"CustomerJobCardRequestId"}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

const ApprovalModal = ({
  openModal,
  setOpenModal,
  customerRequestId,
}: {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  customerRequestId: string;
}) => {
  const [AllKatCareManager, SetAllKatCareManager] = useState<
    KatCareManager[] | null
  >(null);
  const [KatCareManagerLoaded, SetKatCaremanagerLoaded] =
    useState<boolean>(false);
  const [KatCareManagerId, SetKatCareManagerId] = useState<string | undefined>(
    ""
  );
  const [Request, SetRequest] = useState("");
  // const createApprovedJobCardMutation = useMutation(ApproveJobCardRequest, {
  //   onSuccess: (data) => {
  //     if (data.success) {
  //       SetRequest(REQUEST_ATTRIBUTES.REQUEST_SUCCESS);
  //       retreiveResults();
  //       setOpenModal(false);
  //       toast.success(data.message);
  //     } else if (!data.success) {
  //       SetRequest(REQUEST_ATTRIBUTES.REQUEST_FAILURE);
  //       toast.error(data.message);
  //     }
  //   },
  //   onError: () => {
  //     SetRequest(REQUEST_ATTRIBUTES.REQUEST_FAILURE);
  //     toast.error("An error occurred while approving the request.");
  //   },
  // });

  const ProcessForm = async () => {
    // const data: ApproveCustomerRequestProp = {
    //   KatCareManagerId: KatCareManagerId,
    //   PartnerOrKatCareOfficerId: `${auth.user?.profile.sub}`,
    //   CustomerJobCardRequestId: customerRequestId,
    // };
    // SetRequest(REQUEST_ATTRIBUTES.REQUEST_LOADING);
    // await createApprovedJobCardMutation.mutateAsync(data);
  };

  useEffect(() => {
    // const fetchAllManagers = async () => {
    //   const data: KatCareManager[] = await getManagers();
    //   if (data) {
    //     SetAllKatCareManager(data);
    //     SetKatCaremanagerLoaded(true);
    //     console.log(`This is the katcare managers: ${JSON.stringify(data)}`);
    //   }
    // };
    // fetchAllManagers();
  }, []);
  return (
    <Modal
      show={openModal}
      size={"3xl"}
      position="center"
      onClose={() => setOpenModal(!openModal)}
    >
      <Modal.Header>Approve Customer Request</Modal.Header>
      <Modal.Body>
        <div className="max-w-md" id="select">
          {!KatCareManagerLoaded ? (
            <>
              <Skeleton variant="rectangular" className="w-full h-[1.5rem]" />
            </>
          ) : (
            <>
              <Autocomplete
                disablePortal
                className="mb-[1.5rem]"
                id="combo-box-demo"
                disableClearable
                options={
                  AllKatCareManager?.map(
                    (options: KatCareManager) =>
                      `${options.firstName} ${options.lastName}`
                  ) || []
                }
                value={
                  KatCareManagerId
                    ? `${
                        AllKatCareManager?.find(
                          (x) => x.katCareManagerId === KatCareManagerId
                        )?.firstName
                      } ${
                        AllKatCareManager?.find(
                          (x) => x.katCareManagerId === KatCareManagerId
                        )?.lastName
                      }`
                    : undefined
                }
                onChange={(event: any, newValue: string) =>
                  SetKatCareManagerId(
                    AllKatCareManager?.find(
                      (options) =>
                        `${options?.firstName} ${options?.lastName}` ===
                        newValue
                    )?.id
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select KatCare Manager" />
                )}
              />
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {Request === REQUEST_ATTRIBUTES.REQUEST_LOADING ? (
          <>
            <Button>Loading...</Button>
          </>
        ) : (
          <Button onClick={async () => await ProcessForm()}>Approve</Button>
        )}
        <Button color="gray" onClick={() => setOpenModal(false)}>
          Decline
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApprovalsPage;

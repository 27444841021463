import { useEffect, Fragment, useState } from "react";
import {
  Card,
  CardHeader,
  Button,
  Typography,
  CardBody,
} from "@material-tailwind/react";
import { CSVLink } from "react-csv";
import { useSelector, useDispatch } from "react-redux";
import { IStore } from "../state/app.reducer";
import InventoryTable from "../component/element/tables/InventoryTable";
import CustomSkeleton from "../component/layout/CustomSkeleton";
import { getAdmins, getShops } from "../state/app.state";
import { useMaterialTailwindController } from "../context";
import withFunctionGuard from "../guards/FunctionGuard";
import AddShop from "../component/element/Stocks/AddShop";
import $service from "../services/app.service";
import { toast } from "react-toastify";

const InventoryPage =  () => {
  const { shops } = useSelector((reducer: IStore) => reducer.state);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch()
  const [trigger, setTrigger] = useState(0);
  const {discharge} = useMaterialTailwindController();
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getShops())
  },[dispatch]);

  const loadCategory = () => {
    dispatch(getAdmins())
  }

  useEffect(() => {
  const updateShops = shops.map((shop:any) => {
    return {
      key: shop._id,
     _id: shop._id,
     name: shop?.name,
     type: shop?.type,
     managedBy: shop?.managedBy,
     active: shop?.active,
     createdAt: shop?.createdAt
    }
  });
  setTableData(updateShops)
  },[shops])


    const onSubmit =  async () => {
      try {
        setLoading(true);
        const response = await $service.createMainShop();
        if(!response.success) {
          throw new Error(response.message);
        }
        setLoading(false);
        toast.success(response.message);  
        dispatch(getShops())
      } catch(err) {
        setLoading(false);
        toast.error((err as Error).message); 
      }
        };
        


  const filteredData = shops.map((shop: any) => {
    return {
     name: shop?.name,
     type: shop?.type,
     managedBy: shop?.managedBy?.first_name + shop?.managedBy?.last_name,
     active: shop?.active,
     createdAt: shop?.createdAt
    };
  });

  const AddShopButtton = withFunctionGuard(() => (
    <Button
      onClick={() => discharge({ type: "ADD_SHOP", addShop: false })}
      color="blue"
      key={"add-shop"}
    >
      {" "}
      Add Shop
      {" "}
    </Button>
  ))

  return (
    <Fragment>
        <AddShop loadCategory={loadCategory}/>
      <div className="mt-12">
        <div>
          {/* {stocks.loading ? (
            <CustomSkeleton />
          ) : ( */}
            <>
              <div className="mt-12">

                <div className="mb-20 grid gap-y-10 gap-x-6 md:grid-cols-1 xl:grid-cols-1">
                  <div></div>
                  <div className="flex justify-end gap-10">
                    <Button onClick={onSubmit}>
                      Create KAT Shop
                    </Button>
                    <AddShopButtton />
                    <CSVLink data={filteredData} filename={"stockrequest.csv"}>
                      <Button variant="gradient" color="blue">
                        Export CSV
                      </Button>
                    </CSVLink>
                    <Button
                      variant="outlined"
                      color="blue"
                      onClick={() => {
                        setTrigger((trigger) => trigger + 1);
                      }}
                    >
                      Print
                    </Button>
                  </div>
                </div>

                <Card shadow={false}>
                  <CardHeader
                    variant="gradient"
                    color="blue"
                    className="mb-8 p-4"
                  >
                    <Typography variant="h6" color="white">
                     Shop
                    </Typography>
                  </CardHeader>
                  <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                    {/* Add a check for whether the data is loaded before rendering  */}
                     {tableData && tableData.length > 0 ? (
                      <InventoryTable tableData={tableData} trigger={trigger} />
                    ) : (
                     <CustomSkeleton />
                     )} 
                  </CardBody>
                </Card>
              </div>
            </>
          {/* )} */}
        </div>
      </div>
    </Fragment>
  );
};

export default InventoryPage;

export interface IAccessoryRequestTypes  {
  jobcardId: IJobcards;
  adminId: IAdmin;
  status: string;
  reason: string;
  stage: string;
  createdAt: string | any;
  updatedAt: string;
}

export interface IAdminRoles {
  _id: string;
  description: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAccessoryRequestDevices {
  _id: string;
  quantity: number;
  accessoryRequestId: IAccessoryRequestTypes;
  deviceId: IDevices;
  createdAt: string;
  updatedAt: string;
}



export enum EStatusTypes {
  "JOBCARD" = "JOBCARD",
  "ACCESSORYREQUEST" = "ACCESSORY-REQUEST",
}

export interface IStatus {
  _id: string;
  type: string;
  name: string;
  progress: string;
  role: string;
  createdAt: string;
  updatedAt: string;
}

export interface ICustomerDevices {
  _id: string;
  customerId: ICustomer;
  deviceId: IDevices;
  createdAt: string;
  updatedAt: string;
}

export interface IWarranty {
  _id: string;
  customerId: string;
  customerDeviceId: string;
  start_date: string;
  end_date: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAdmin {
  _id: string;
  role: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  email: string;
  mobile: string;
  password: string;
  adminType: string;
  company_name: string;
  district: string;
  region: string;
  digital_address: string;
  certified: boolean;
  resetRequired: boolean;
  createdAt: string;
  updatedAt: string;
  lat: string;
  lng: string;
  locationAddress: string;
}

export interface IJobcardMetrics {
  total: Array<{
    count: number;
  }>;
  pending: Array<{
    count: number;
  }>;
  completed: Array<{
    count: number;
  }>;
  approved: Array<{
    count: number;
  }>;
  rejected: Array<{
    count: number;
  }>;
}

export interface IDevices {
  _id: string;
  serialNumber: string;
  category: any;
  model: string;
  color: string;
  year: string;
  company: string;
  sold: boolean;
  forsale: boolean;
  purchaseDate: string;
  price: number;
  createdAt: string;
  updatedAt: string;
  distributionDate: string;
}

export interface ICustomer {
  _id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
  address: string;
  blocked: boolean;
  email: string;
  resetRequired: boolean;
  lastseen: string;
  password: string;
  createdAt: string;
  updatedAt: string;
}

export interface IJobcards {
  _id: string;
  progress: number;
  jobcardNumber: string;
  createdBy: any;
  createdByRef: string;
  customerId: ICustomer;
  stage: string;
  deviceId: any;
  description: string;
  requestType: string;
  status: string;
  ratings: number;
  createdAt: any;
  updatedAt: string;
  deliveryType: string;
  
}

export interface IApprovals {
  _id: string;
  requestId: string;
  approver: string;
  remarks: string;
  timestamp: string;
  stage: string;
  status: string;
  type: string;
  referencePath: string;
  createdAt: string;
  updatedAt: string;
  PowerOnCheckWhetherLCDDisplayIsNormal: string;
  TheLogoWhenPowerOnAndAnimationMustDisplay: string;
  TheLCDDisplayContrastQualityIsBad: string;
  LCDDisplayCheckSymbolsOnDesk: string;
  CheckFlipByFlipingThreeTimes: string;
  KeyboardFunctionTest: string;
  TestToCheckIfTouchPadIsWorkingOrHasRoughSurface: string;
  CheckMouseRightOrLeftClick: string;
  CheckSpeakerIfSoundIsClearForHearing: string;
  CheckMicrophoneByRecordingAVoiceMessageToEnsureClarity: string;
  CheckLcdColours: string;
  CameraTestNoAbnormalDisplayAfterEnterPreviewScreen: string;
  CheckSoftwareOnComputer: string;
  CheckWifiSymbol: string;
  TestForBluetooth: string;
  StandardDetailCheckingOfSystemsSetting: string;
  PhysicalCheckToTestIfFanIsWorking: string;
  CheckUsbSlotsIfItsFunctioning: string;
  CheckOtherButtons: string;
  SimSlotCannotRegisterSIMCard: string;
  ShutdownLaptopOrDesktopAfterAboveCheckingDone: string;
  HasDeviceBeenTamperedWith: boolean;
  HasDeviceBeenWorkedOnByUnAuthorizedPerson: boolean;
  IsDamageToTheDeviceDueToNegligence: boolean;
  SameFaultInThePast: boolean;
  IsDeviceWithinWarranty: boolean;
}

export interface IStock {
  _id: string
  requestBy: any
  inventory: string
  createdAt: string
  updatedAt: string
  __v: number
  approvedBy: string
  status: string
}

export interface Iitems{
  _id: string
  deviceId: IDevices
  quantity: number
  stockId: IStock
  createdAt: string
  updatedAt: string
  __v: number
}

export interface Iinventory{
    _id: string
    type: string
    managedBy: any
    deviceId: IDevices
    quantity: number
    createdAt: string
    updatedAt: string
    __v: number
  
}

export interface IProducts {
  _id: string
  name: string
  category: IProductsCategory
  color: string
  price: number
  createdAt: string
  updateddAt: string
  __v: number
  specification?: string,
  image?: any
}

export interface IProductsCategory { 
  _id: string
  name: string
  active: boolean
  createdAt: string
  updateddAt: string
  __v: number
}

export interface IAddShop {
  type: string
  name: string
  managedBy: IAdmin
}

export interface IProductsShop {
  products: any[],
  shopId: string
  quantity: number
}

export interface IDeviceAccessorys {
  category: IProductsCategory;
  name: string;
  quantity: string | number;
  price: string | number;
  specification: string;
  image?: string | null; // Optional
}


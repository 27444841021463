import {
  Button,
  DialogFooter,
  DialogHeader,
  Input,
  Typography,
} from '@material-tailwind/react';
import React, { useState } from 'react';
import { useMaterialTailwindController } from '../../../context';
import { Dialog, DialogContent } from '@mui/material';
import $service from '../../../services/app.service';
import { toast } from 'react-toastify';
import { setLoading } from '../../../state/app.state';

function AddDeviceCategory() {
  const { discharge, controller } = useMaterialTailwindController();
  const { addCategory } = controller;
  const [category, setCategory] = useState<any>({
    name: '',
    warrantyPeriod: '',
  });

  const handleOpen = () =>
    discharge({ type: 'ADD_CATEGORY', addCategory: false });

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const setFormValue = (key: string, value: string | number) =>
    setCategory({ ...category, [key]: value });

  const onSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const { name, warrantyPeriod } = category;
      if (name === '' || warrantyPeriod === '') {
        throw Error('Please specify a category');
      }

      const data: any = {
        name: name,
        warrantyPeriod: warrantyPeriod!,
      };
      setLoading(false);
      const response = await $service.addDeviceCategory(data);

      switch (response.success) {
        case true:
          toast('Category added successfully');
          discharge({ type: 'ADD_CATEGORY', addCategory: false });
          setCategory('');
          break;
        case false:
          throw Error(response.message);
        default:
          throw Error('Could not add category');
      }
    } catch (e) {
      toast((e as Error)?.message);
    }
  };

  return (
    <Dialog open={addCategory} onClose={handleOpen} sx={{ width: '100%' }}>
      <DialogHeader className='text-lg'>Add Device Category</DialogHeader>
      <DialogContent sx={{ width: '500px' }}>
        <form onSubmit={handleSubmit}>
          <div className='mb-2'>
            <Typography className='text-sm font-semibold'>
              Add Device Categorys(SM1, TM1)
            </Typography>
            <Input
              type='text'
              onChange={(event) => setFormValue('name', event.target.value)}
            />
          </div>
          <div className='mb-2'>
            <Typography className='text-sm font-semibold'>
              Warranty Period
            </Typography>
            <select 
              onChange={(event) =>
                setFormValue('warrantyPeriod', event.target.value)
              }
            className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '>
              <option selected>Choose duration</option>
              <option value='0'>0 Years</option>
              <option value='1'>1 Years</option>
              <option value='2'>2 Years</option>
          
            </select>
            {/* <Input
              type='text'
            
            /> */}
          </div>
        </form>
      </DialogContent>
      <DialogFooter>
        <Button
          color='green'
          disabled={category.length === 0}
          onClick={(event) => onSubmit(event)}
        >
          Submit
        </Button>
        <Button
          variant='filled'
          color='red'
          onClick={handleOpen}
          className='ml-3'
        >
          Cancel
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default AddDeviceCategory;

import KatCard from "../component/element/KatCard";
import CustomerAreaJobCardRequestChart from "../component/element/AreaCustomerCards";
import CustomCards from "../component/element/CustomCards";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getMetrics } from "../state/app.state";

const HomePage = () => {
  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(getMetrics())
  },[])
  
  return (
    <div className="mt-12 ">
      <div className="">
        <KatCard />
      </div>
      <div className="mb-12 grid gap-6">
        <div className="mb-6 grid grid-cols-1 gap-y-12 gap-x-3 md:grid-cols-2 xl:grid-cols-2">
          <CustomCards />
          <CustomerAreaJobCardRequestChart />
        </div>
      </div>
    </div>
  );
};

export default HomePage;

import { Fragment, useState } from "react";
import CustomSkeleton from "../component/layout/CustomSkeleton";
import DeviceTables from "../component/element/tables/DeviceTables";
import {
  Card,
  CardHeader,
  Button,
  Typography,
  CardBody,
} from "@material-tailwind/react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";
import { devicesTableDataSelector } from "../state/app.state";
import { useMaterialTailwindController } from "../context";
import AddStoreModal from "../component/element/Stocks/AddStoreModal";
import AddImportDevice from "../component/element/Stocks/AddImportDevice";
import WithFunctionGuard from "../guards/FunctionGuard";
import AddDeviceCategory from "../component/element/Stocks/AddDeviceCategory";
import ShowSerialNumber from "../component/element/Stocks/showSerialNumber";

const DevicePage = () => {
  const state = useSelector((reducer: IStore) => reducer.state);
  const devices = devicesTableDataSelector(state);
  const [trigger, setTrigger] = useState(0);
  const { discharge } = useMaterialTailwindController();

  console.log(devices)

  const filteredData = devices.map((storelist) => {
    return {
      model: storelist.model,
      serialNumber: storelist.serialNumber,
      deviceName: storelist.category,
      distributionDate: storelist.distributionDate
    };
  
  });

  const AddDeviceCategoryButton = WithFunctionGuard(() => (
    <Button
      onClick={() => discharge({ type: "ADD_CATEGORY", addCategory: false })}
      color="blue"
      key={"add-category"}
    >
      {" "}
      Add Device Category
      {" "}
    </Button>
  ));

  const AddDeviceButton = WithFunctionGuard(() => (
    <Button
      variant="gradient"
      color="blue"
      onClick={() =>
        discharge({ type: "DEVICE_MODAL", addDeviceModalModal: true })
      }
      key={"add-device"}
    >
      Add Device
    </Button>
  ));

  const ImportDeviceButton = WithFunctionGuard(() => (
    <Button
      variant="gradient"
      color="blue"
      key={"import-device"}
      onClick={() => discharge({ type: "IMPORT_MODAL", importModal: false })}
    >
      Import Device
    </Button>
  ));

  const ImportImageDeviceButton = WithFunctionGuard(() => (
    <Button variant="filled"
     color="cyan"
     key={"import-image"}
     onClick={() => console.log("Import Image")}
    >
    Import Device Image
    </Button>
  ))

  return (
    <Fragment>
      <div className="mt-12">
        <AddStoreModal />
        <div>
          {state.loading ? (
            <CustomSkeleton />
          ) : (
            <>
              <div className="mt-12">
                <AddImportDevice />
                <div className="mb-20 grid gap-y-10 gap-x-6 md:grid-cols-1 xl:grid-cols-1">
                  <div></div>
                  <div className="flex justify-end gap-10">
                    <AddDeviceButton />
                    <AddDeviceCategoryButton />
                    <AddDeviceCategory />
                    <ImportDeviceButton />
                    <ImportImageDeviceButton />
                    <CSVLink data={filteredData} filename={"devices.csv"}>
                      <Button variant="gradient" color="blue">
                        Export CSV
                      </Button>
                    </CSVLink>
                    <Button
                      variant="outlined"
                      color="blue"
                      onClick={() => {
                        setTrigger((trigger) => trigger + 1);
                      }}
                    >
                      Print
                    </Button>
                  </div>
                </div>

                <Card shadow={false}>
                  <CardHeader
                    variant="gradient"
                    color="blue"
                    className="mb-8 p-4"
                  >
                    <Typography variant="h6" color="white">
                      All Devices
                    </Typography>
                  </CardHeader>
                  <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                    {/* Add a check for whether the data is loaded before rendering */}
                    {state.loading ? (
                      <CustomSkeleton />
                    ) : (
                      <DeviceTables tableData={devices} trigger={trigger} />
                    )}
                  </CardBody>
                </Card>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default DevicePage;

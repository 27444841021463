import {Card} from "@material-tailwind/react";
import JobCardChart from "../Charts/Job Cards/JobCardChart";

const CustomCards = () => {
  return (
    <Card className="relative">
      <Card className="absolute top-[-30px] left-[20px] px-[8px] py-[8px] bg-[#1e88e5] shadow-md">
        <p className="text-[#FFFFFF]">Job Cards</p>
      </Card>
       <JobCardChart />
    </Card>
  )
}

export default CustomCards
import {
    Button,
    DialogFooter,
    DialogHeader,
    Typography,
  } from "@material-tailwind/react";
  import React, { useState, useEffect } from "react";
  import { useMaterialTailwindController } from "../../../context";
  import { Dialog, DialogContent } from "@mui/material";
  import $service from "../../../services/app.service";
  import { toast } from "react-toastify";
  import {  useForm } from 'react-hook-form';
import { IAddShop } from "../../../models/app.model";
import {  getAdmins, getShops } from "../../../state/app.state";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../state/app.reducer";

const AddShop = ({ loadCategory }: any) => {
    const { discharge, controller } = useMaterialTailwindController();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAdmin, setSelectedAdmin] = useState<any>(null);
    const [selectedPartner, setSelectedPartner] = useState<any>(null);
    const { addShop } = controller;
    const dispatch = useDispatch()
    const { admins } = useSelector((reducer: IStore) => reducer.state);
    const handleOpen = () =>
    discharge({ type: "ADD_SHOP", addShop: false });

    useEffect(() => {
      loadCategory();
    }, []);
    
    const onSubmit = async (data: IAddShop) => {
        try {
          if (
            !data.type ||
            !data.name ||
            !data.managedBy 
          ) {
            throw Error('Please fill the form completely');
          }
          const payload:Partial<IAddShop> = {
            type: data.type,
            name: data.name,
            managedBy: data.managedBy,
          };

          setLoading(true);
          const response = await $service.addShop!(payload);
          if (!response.success) {
            throw Error(response.message);
          }
          setLoading(false);
          discharge({ type: 'ADD_SHOP', add: false });
          toast.success(response.message);
        } catch (err) {
          setLoading(false);
          toast.error((err as Error).message);
        }
      };
    
      const { register, handleSubmit, watch } = useForm<IAddShop>();
      const type = watch("type");
  return (
    <Dialog open={addShop} onClose={handleOpen} sx={{ width: "100%" }}>
    <DialogHeader className="text-lg">Create a shop</DialogHeader>
    <DialogContent sx={{ width: "500px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <Typography className="text-sm font-semibold">Type</Typography>
          <select
              className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                id='default'
                {...register("type", {
                  required: true,
                })}>
                  <option value=''> Choose User</option>
                    <option value='PARTNER'>PARTNERS</option>
                    <option value='KAT'>KAT</option>
                </select>
        </div>
        <div className="mb-2">
          <Typography className="text-sm font-semibold">Manage By</Typography>
          <select
             value={selectedAdmin}
                id='default'
                {...register("managedBy", {
                  required: true,
                })}
                onChange={(e) => {
                  const admin = e.target.value;
                  setSelectedAdmin(admin);
                  setSelectedPartner(null);
                }}
                className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
              >
                <option value="">Choose Partner</option>
               {admins
                ?.filter(
                  (admin: any) =>
                    (type === "PARTNER" &&
                      admin.role === "KATCARE-PARTNER") ||
                    type === "KAT"
                )
                .map((admin: any) => (
                  <option key={admin?._id} value={admin?._id}>
                    {" "}
                    {admin?.first_name} {admin?.last_name}
                  </option>
                ))}
              </select>
        </div>
        <div className="mb-2">
          <Typography className="text-sm font-semibold">Company Name</Typography>
          <select
             value={selectedPartner}
                id='default'
                {...register("name", {
                  required: true,
                })}
                onChange={(e) => {
                  const id = e.target.value;
                  setSelectedPartner(id);
                }}
                className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
              >
                <option value="">Choose Company</option>
          {admins
    ?.filter((admin: any) => admin?._id === selectedAdmin)
    .map((admin: any) => (
      <option key={admin?._id} value={admin?.company_name}>
        {admin?.company_name}
      </option>
    ))}
              </select>
        </div>
        <Button color="green"  type='submit'  className="w-full">
        Submit
      </Button>
      </form>
    </DialogContent>
    <DialogFooter>
     
      <Button
        variant="filled"
        color="red"
        onClick={handleOpen}
        className="ml-3"
      >
        Cancel
      </Button>
    </DialogFooter>
  </Dialog>
  )
}

export default AddShop
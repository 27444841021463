import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import {
  XMarkIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import {
  Avatar,
  Button,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import { useMaterialTailwindController } from "../../context/index";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../state/app.state";


interface SidenavProps {
  brandImg?: any;
  brandName?: string;
  routex?: any;
}

export function Sidenav({ brandImg, brandName, routex }: SidenavProps) {
  const _dispatch = useDispatch();
  const { controller, discharge} = useMaterialTailwindController();
  const { sidenavColor, sidenavType, openSidenav } = controller;
  const { roles } = useSelector((reducer: any)=>reducer.state);
  
  const sidenavTypes: any = {
    dark: "bg-gradient-to-br from-blue-600 to-blue-900",
    white: "bg-white shadow-lg",
    transparent: "bg-transparent",
  };

  return (
    <aside
      className={`${sidenavTypes[sidenavType]} ${
        openSidenav ? "translate-x-0" : "-translate-x-80"
      } fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl transition-transform duration-300 xl:translate-x-0 overflow-y-scroll`}
    >
      <div
        className={`relative border-b ${
          sidenavType === "dark" ? "border-white/20" : "border-blue-gray-50"
        }`}
      >
        <Link to="/" className="flex items-center gap-4 py-6 px-8">
          <Avatar src={brandImg}  alt="logo" />
          <Typography
            variant="h6"
            color={sidenavType === "dark" ? "white" : "blue-gray"}
          >
            {brandName}
          </Typography>
        </Link>
        <div className="flex items-center gap-4  pb-4 px-8">
        <Typography
                    variant="small"
                    color="white"
                    className="font-black uppercase opacity-75"
                  >
                   {roles.name} 
                  </Typography>
        </div>

        <IconButton
          variant="text"
          color="white"
          size="sm"
          ripple={false}
          className="absolute right-0 top-0 grid rounded-br-none rounded-tl-none xl:hidden"
          onClick={() => discharge({ type: "OPEN_SIDENAV", openSidenav: false })}
        >
          <XMarkIcon strokeWidth={2.5} className="h-5 w-5 text-white" />
        </IconButton>
      </div>
      <div className="m-4">
        {routex?.map(({ layout, title, pages }: any, key: any) => {
          return (
            <ul key={pages} className="mb-4 flex flex-col gap-1">
              {title && (
                <li className="mx-3.5 mt-4 mb-2">
                  <Typography
                    variant="small"
                    color={sidenavType === "dark" ? "white" : "blue-gray"}
                    className="font-black uppercase opacity-75"
                  >
                    {title}
                  </Typography>
                </li>
              )}
              {pages?.map(({ icon, name, path }: any) => (
                <li key={name}>
                  <NavLink to={`/${layout}${path}`}>
                    {({ isActive }) => (
                      <Button
                        variant={isActive ? "gradient" : "text"}
                        color={
                          isActive
                            ? sidenavColor
                            : sidenavType === "dark"
                            ? "white"
                            : "blue-gray"
                        }
                        className="flex items-center gap-4 px-4 capitalize"
                        fullWidth
                      >
                        {icon}
                        <Typography
                          color="inherit"
                          className="font-medium capitalize"
                        >
                          {name}
                        </Typography>
                      </Button>
                    )}
                  </NavLink>
                </li>
              ))}
              <li>
                <Button
                  onClick={() => {
                    _dispatch(logout());
                  }}
                  variant="text"
                  className="flex mt-10 items-center gap-4 px-4 capitalize "
                  fullWidth
                >
                  <ArrowRightOnRectangleIcon className="h-5 w-5 text-white" />
                  <Typography
                    color="inherit"
                    className="font-medium capitalize text-white"
                  >
                    Logout
                  </Typography>
                </Button>
              </li>
            </ul>
          );
        })}
      </div>
    </aside>
  );
}

Sidenav.defaultProps = {
  brandImg: "/img/logo-ct.png",
  brandName: "KATCare",
};

Sidenav.propTypes = {
  brandImg: PropTypes.string,
  brandName: PropTypes.string,
  routex: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;

import { Bars3Icon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import KatCareLogo from '../../Assets/images/logo ktcare.png';

const LandingNavbar = () => {
    const [openNav, setOpenNav] = useState(false);
    const [myscroll, setScroll] = useState(false);

      const handleScroll = () => {
        if (window.scrollY >= 80) {
          setScroll(true);
        } else {
          setScroll(false);
        }
      };

      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <header
    className={`shadow-lg bg-transparent  w-full z-10 transition-colors duration-300 `}
  >
    <nav
      className={`border-gray-200 mx-auto max-w-screen-xl px-4 lg:px-6 py-4`}
    >
      <div className='flex flex-wrap justify-between items-center   max-w-screen-xl'>
        <Link to='/' className='flex items-center'>
          <img
            src={KatCareLogo}
            className='mr-5 h-5 cover sm:h-10'
            alt='Katcare Logo'
          />
          {/* <h1 className={`text-2xl font-bold `}>KATCARE</h1> */}
        </Link>

        {/* <Link to="/landing">
          <span
         
            className={` block py-2 pr-4 pl-3 mx-3  text-lg font-medium hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0`}
          >
            Log in
          </span>
          </Link> */}
        {/* <Link
            to="#"
            className="text-white hidden bg-blue-700 hover:bg-primary-800  font-medium rounded-lg text-md px-4 lg:px-5 py-2.5 lg:py-2.5 mr-2 "
          >
            Get started
          </Link> */}

        <button
          onClick={() => setOpenNav(!openNav)}
          data-collapse-toggle='mobile-menu-2'
          type='button'
          className={`${
            myscroll ? 'text-gray-900' : 'text-gray-500'
          } inline-flex items-center p-2 ml-1 text-lg rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200`}
          aria-controls='mobile-menu-2'
          aria-expanded='false'
        >
          <Bars3Icon className='h-7 w-7' aria-hidden='true' />
        </button>

        <div
          className={`show ${
            openNav ? 'show' : 'hidden'
          }  justify-between items-center w-full lg:flex lg:w-auto lg:order-1`}
          id='mobile-menu-2'
        >
          <ul className='flex flex-col mt-4 text-lg font-medium lg:flex-row lg:space-x-8 lg:mt-0 '>
            <li>
              <Link
                to='/'
                className={`${
                  myscroll ? 'text-gray-900 ' : 'text-gray-900'
                } block py-2 pr-4 pl-3 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 `}
                aria-current='page'
              >
                Home
              </Link>
            </li>
            <li>
              <a
                href='#about'
                className={` block py-2 pr-4 pl-3   0 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0`}
              >
                About
              </a>
            </li>
            <li>
              <a
                href='#services'
                className={` block py-2 pr-4 pl-3   0 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0`}
              >
                Services
              </a>
            </li>

            <li>
              <a
                href='https://www.katechnologiesgh.com/carecenters.html'
                className={` block py-2 pr-4 pl-3   0 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0`}
              >
                Partners
              </a>
            </li>

            <li>
              <a
                href='#contact'
                className={` block py-2 pr-4 pl-3   0 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0`}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  )
}

export default LandingNavbar
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from '../state/app.reducer';
import { getKatParners,getAdminRoles, getAdmins } from '../state/app.state';
import regions from '../services/region.service';
import $service from '../services/app.service';
import LandingFooter from '../component/layout/LandingFooter';
import LandingNavbar from '../component/layout/LandingNavbar';

function ServiceCenterPage() {
  const [values, setValues] = useState(regions);
  const dispatch = useDispatch();
  const { katPartners,admins } = useSelector((reducer: IStore) => reducer.state);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [selectedDistrict,setSelectedDistrict] = useState<any>(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://18.198.24.26:8000/api/staging/admin/get', {
          method: 'GET',
          headers: {
            'Authorization': ` ${$service.headers}`,
            'Content-Type': 'application/json',
            // Add other headers as needed
          },
        }); 
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        console.log(result);
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // 
  useEffect(() => {
    dispatch(getKatParners());
    dispatch(getAdmins());
  }, [dispatch]);



  return (
    <div className='main'>
      <LandingNavbar />
      <section className='pt-4'>
        <div className=' px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6'>
          <div className='grid grid-cols-1 gap-6 content-center md:grid-cols-4'>
            <div className=''>
              <h2 className='text-xl mt-2 font-semibold'>Service Center</h2>
            </div>
            <div>
              <select
                id='default'
                value={selectedRegion}
                onChange={(e) => {
                  const region = e.target.value;
                  setSelectedRegion(region);
                  setSelectedDistrict(null); // Reset the district when the region changes
                  dispatch(getKatParners(region));
                }}
                className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
              >
                {Object.keys(regions).map((region) => (
                  <option key={region} value={region}>
                    {' '}
                    {region}
                  </option>
                ))}
              </select>
            </div>
            <div>
            <select
  value={selectedDistrict}
  id='default'
  onChange={(e) => setSelectedDistrict(e.target.value)}
  className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
>
  {regions[selectedRegion]?.map((district: any) => (
    <option key={district} value={district}>
      {district}
    </option>
  ))}
</select>
            </div>
            <div>
              <a
                href='https://www.katechnologiesgh.com/carecenters.html'
                type='button'
                className='text-white bg-[#4285F4] w-full hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-lg px-5 py-2 text-center justify-center inline-flex items-center  mb-2'
              >
                Search
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className='py-4'>
        <div className='py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-2'>
          <div className='grid grid-cols-1 gap-8 md:grid-cols-3'>
            <div className='flex flex-col'>
              {katPartners?.map((officer: any) => (
                <div className='bg-white rounded-lg shadow-sm p-3 '>
                  <h2 className='font-bold text-xl mb-2'>
                    J-Klick IT Solutions Center
                  </h2>
                  <span className='flex my-2 gap-1'>
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-5 h-5'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z'
                        />
                      </svg>
                    </span>
                    <span className='text-sm'> {officer?.digital_address}</span>
                  </span>

                  <span className='flex my-2 gap-1'>
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-5 h-5'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M15 10.5a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z'
                        />
                      </svg>
                    </span>
                    <span className='text-sm'>
                      {' '}
                      KWAPONG, INFORMATION CENTER BUILDING, ASUNAFO NORTH, AHAFO
                    </span>
                  </span>

                  <span className='flex my-2 gap-1'>
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-5 h-5'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z'
                        />
                      </svg>
                    </span>
                    <span className='text-sm'>0249121636 /0203885099</span>
                  </span>
                </div>
              ))}
            </div>

            {/*  */}
            {/* <div className="map col-span-2">
      <div className="">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63529.14535584757!2d-0.21609367832028928!3d5.630077200000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf858154dca2eb%3A0x86b07007a00ee415!2sFrema%20House!5e0!3m2!1sen!2sgh!4v1692358144135!5m2!1sen!2sgh" 
     className='rounded-lg h-96 w-full'
      loading="lazy" referrerPolicy="no-referrer-when-downgrade">

      </iframe>
      </div>
    </div> */}
          </div>
        </div>
      </section>
      {/* Footer */}
    <LandingFooter />
    </div>
  );
}

export default ServiceCenterPage;

import Chart from "react-apexcharts";
import { CustomerJobCardRequestData } from "./Data/CustomerJobCardRequestData";
const CustomerJobCardRequestChart = () => {
    return (
        <>
        <Chart
            options={CustomerJobCardRequestData.options}
            series={CustomerJobCardRequestData.series}
            type="area"
            width="400"
        />
        </>
    )
}
export default CustomerJobCardRequestChart;
import { useEffect, Fragment, useState } from "react";
import {
  Card,
  CardHeader,
  Button,
  Typography,
  CardBody,
} from "@material-tailwind/react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";
import WithFunctionGuard from "../guards/FunctionGuard";
import ProductTable from "../component/element/tables/ProductTable";
import { useNavigate } from "react-router-dom";
import { getProducts } from "../state/app.state";
import AddDeviceAccessory from "../component/element/Stocks/addDeviceAccessory";
import { useMaterialTailwindController } from "../context";


const AccessoryListPage = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((reducer: IStore) => reducer.state);
  const [trigger, setTrigger] = useState(0);
 const {discharge} = useMaterialTailwindController()

  useEffect(() => {
   dispatch(getProducts())
},[]);

  const filteredData = products.map((products) => {
    return {
    name: products?.name,
     price: products?.price,
     color: products?.color,
     category: products?.category?.name,
     createdAt: products.createdAt,
    };
  });


  const AddProductButton = WithFunctionGuard(() => (
    <Button
      variant="gradient"
      color="blue"
      onClick={() => discharge({ type: 'ADD_DEVICEACCESSORY', addDeviceAccessory: false })}
      
      key={"add-device-accessory"}
    >
  Add Device Accessories
    </Button>
  ));




  return (
    <Fragment>
      <div className="mt-12">
        <div>
          {/* {stocks.loading ? (
            <CustomSkeleton />
          ) : ( */}
            <>
              <div className="mt-12">
                <AddDeviceAccessory />
                <div className="mb-20 grid gap-y-10 gap-x-6 md:grid-cols-1 xl:grid-cols-1">
                  <div></div>
                  <div className="flex justify-end gap-10">
                   <AddProductButton />
                 
                    <CSVLink data={filteredData} filename={"stockrequest.csv"}>
                      <Button variant="gradient" color="blue">
                        Export CSV
                      </Button>
                    </CSVLink>
                    <Button
                      variant="outlined"
                      color="blue"
                      onClick={() => {
                        setTrigger((trigger) => trigger + 1);
                      }}
                    >
                      Print
                    </Button>
                  </div>
                </div>

                {/* <Card shadow={false}>
                  <CardHeader
                    variant="gradient"
                    color="blue"
                    className="mb-8 p-4"
                  >
                    <Typography variant="h6" color="white">
                      Products List
                    </Typography>
                  </CardHeader> */}
                  {/* <CardBody className="overflow-x-scroll px-0 pt-0 pb-2"> */}
                    {/* Add a check for whether the data is loaded before rendering */}
                    {/* {stocks.loading ? (
                      <CustomSkeleton />
                    ) : ( */}
                      {/* <ProductTable tableData={products} trigger={trigger} /> */}
                    {/* )} */}
                  {/* </CardBody>
                </Card> */}
              </div>
            </>
          {/* )} */}
        </div>
      </div>
    </Fragment>
  );
};

export default AccessoryListPage ;

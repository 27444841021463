export interface IRole {
  menu: string[];
  functions: string[];
  status: string[];
}

export interface IRoles {
  [x: string]: IRole | {};
}

const roles: IRoles = {
  "KATCARE-PARTNER": {
    name: "KATCARE PARTNER",
    menu: "partnerRoutes",
    functions: [
      "add-jobcard",
      "add-customer",
      "add-device-customer",
      "create-accessory-request",
      "transfer-kat",
      "selection-button",
      "request-stock",
      "make-sale",
    ],
    approves: ["SERVICE-REQUEST"],
    status: ["COMPLETED"],
  },
  "QUALITY-CONTROL": {
    name: "QUALITY CONTROL",
    menu: "qcRoutes",
    functions: [
      "run-diagonsis",
      "rejected-button",
      "accept-button",
      "review-button",
      "accept-accessory",
      "reject-accesory",
      "selection-button"
    ],
    status: ["DEVICE-CHECKED", "DEVICE-FIX"],
    approves: ["KATCARE-GM-APPROVAL", ""],
  },
  "KATCARE-TECHNICIAN": {
    name: "TECHNICIAN",
    menu: "technicianRoutes",
    functions: ["run-diagonsis", "create-accessory-request", "selection-button"],
    status: ["QUALITY-CHECK"],
    approves: ["SERVICE-REQUEST"],
  },
  "GENERAL-MANAGER": {
    name: "KATCARE MANAGER",
    menu: "managerRoutes",
    functions: ["suspend-customer","accept-accessory","reject-accesory","partner-jobcard","approveStockButton","filter-box", "salescard-box",  "add-shop", "suspend-admin"],
    approves: ["PRODUCTION-MANAGER-APPROVAL"],
  },
  "PRODUCTION-MANAGER": {
    name: "PRODUCTION MANAGER",
    menu: "productionRoutes",
    functions: ["accept-accessory", "reject-accesory", "add-device", 
    "import-device",],
    approves: ["ACCESSORY-APPROVED"],
  },
  "STORE-MANAGER": {
    name: "STORE MANAGER",
    menu: "storeRoutes",
    functions: [
     "approveStockButton",
     "update-device",
     "add-category",
     "add-product",
     "add-device-accessory"
    ],
    approves: [],
  },
  "KATCARE-OFFICER": {
    name: "KATCARE OFFICER",
    menu: "officerRoutes",
    functions: [
      "add-customer",
      "mark-complete",
      "add-jobcard",
      "add-device-customer",
      "partner-jobcard",
      "selection-button",
      "add-category",
    ],
    status: ["REJECTED", "APPROVED", "SERVICE-REQUEST", "COMPLETED"],
    approves: [],
  },
};

export default roles;

import { Accordion, AccordionBody, AccordionHeader, Alert, Button, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMaterialTailwindController } from "../../../context";
import RejectModal from "../JobCardApprove/RejectModal";
import { IDevices } from "../../../models/app.model";
import $service from "../../../services/app.service";
import WithFunctionGuard from "../../../guards/FunctionGuard";
import { toast } from "react-toastify";

function JobCardApprovals({ item }: any) {
  const { discharge } = useMaterialTailwindController();
  const [device, setDevice] = useState<IDevices | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<number>(1);

  useEffect(() => {
    loadDetails();
  }, [item]);

  const handleopen = (value: React.SetStateAction<number>) => setOpen(open === value ? 0 : value)

  const reject = async () => {
    try {
      setLoading(true);
      const response = await $service.setApprovalStatus({
        id: item._id,
        status: "rejected",
      });
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast("Diagnosis successfully rejected");
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const approve = async () => {
    try {
      setLoading(true);
      const response = await $service.setApprovalStatus({
        id: item._id,
        status: "approved",
      });
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast("Diagnosis successfully approved");
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const loadDetails = async () => {
    try {
      const { _id } = item.requestId?.deviceId;
      const response = await $service.deviceDetails!(_id);
      if (!response.success) {
        throw Error(response.message);
      }
      setDevice(response.data.deviceId);
    } catch (err) {}
  };

  const RejectModalButton = WithFunctionGuard(() => (
    <Button
    color="red"
    onClick={reject}
    disabled={item.status === "rejected"}
    key={"rejected-button"}
  >
    Reject
  </Button>
  ))

  const AcceptModalButton = WithFunctionGuard(() => (
    <Button
    disabled={item.status === "approved"}
    onClick={approve}
    color="green"
    key={"accept-button"}
  >
    Accept
  </Button>
  ))


  const ReviewModalButton = WithFunctionGuard(() => (
    <Button onClick={() => discharge({ type: "REJECT_MODAL", rejectModal: false })} color="green" key={"review-button"}>
    Add Review
  </Button>
  ))

  return (
    <React.Fragment>
      <RejectModal />
      <Alert
        variant="filled"
        // color={item?.approvedBy === null ? "blue" : "green"}
        className="bg-[#2196f3]"
      >
          <div>
        <Typography
                          variant="h5"
                          className="text-lg font-bold leading-none mb-5 text-white"
                        >
                        Technician Infomation
                        </Typography>
        </div>
        <hr />
        <div className="grid grid-cols-3 my-2">
        <div>
           <Typography variant="h6">
            Techincian
           </Typography>
           <Typography variant="paragraph">
           {item.approver?.role} 
           </Typography>
           </div>
          <div>
           <Typography variant="h6">
            Officer Name
           </Typography>
           <Typography variant="paragraph">
           {`${item.approver?.first_name} ${item.approver?.last_name}`} 
           </Typography>
           </div>
           <div>
           <Typography variant="h6">
            Status
           </Typography>
              <Typography variant="paragraph" color="red" className="text-md font-bold">
                 <span>{item?.status}</span> 
              </Typography>
            </div>
        </div>
        <div className="flex justify-between items-baseline">
          <div>
            {/* <Typography variant="lead">
              {`${item?.requestId?.customerId?.firstName} ${item?.requestId?.customerId?.lastName}`}
            </Typography>
            <Typography variant="paragraph">
              {`${item?.requestId?.description}`}
            </Typography> */}
            {/* {item?.approvedBy !== null ? (
              <div>
                <div className="mb-[1.5rem]">
                  Approved By:{" "}
                  {`${item.approver?.first_name} ${item.approver?.last_name}`} 
                </div>
                <div className="my-1">
                <span className="text-white">Role: {""} {item.approver?.role} </span>
                </div>
                <div className="flex">
                  <div className="mr-[2rem]">Iphone</div>
                  <Link
                    className="text-[#4169E1]"
                    to="/"
                  >
                   View details
                  </Link>
                 
                </div>
                <div className="">
                    <p className="">{item.remarks}</p>
                  </div>

              </div>
            ) : null} */}
          </div>
          <div className="flex flex-col">
            {/* <div className="mb-[1rem]">
              <Typography variant="h2" className="text-md font-bold">
                Device Name:
              </Typography>
              <Typography
                variant="lead"
                className="text-xl font-semibold text-gray-800"
              >
                {device?.model}
              </Typography>
            </div> */}
            {/* <div className="">
              <Typography variant="h2" className="text-md font-bold">
                Serial #:
              </Typography>
              <Typography
                variant="lead"
                className="text-xl font-semibold text-gray-800"
              >
                {device?.serialNumber}
              </Typography>
            </div> */}   
          </div>
        </div>
        <div className="">
        <Accordion open={open === 1} >
        <AccordionHeader onClick={() => handleopen (1)} >
        <Typography
                          variant="h5"
                          className="text-lg font-bold leading-none mb-5 text-white"
                        >
                        Technician Diagnosis
                        </Typography>
        </AccordionHeader>
        <AccordionBody>
        <ul
                          className="list-none grid grid-cols-2"
                        >
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              Has the device been tampered with
                            </strong>
                            <p className="text-gray-300">{item?.HasDeviceBeenTamperedWith}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                             Missing Parts
                            </strong>
                           <p className="text-gray-300">{item.MissingParts}</p> 
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                            Remarks
                            </strong>
                           <p className="text-gray-300">{item.remarks}</p>
                          </li>
                         
                        </ul>
        </AccordionBody>
        </Accordion>
        <Accordion open={open === 2} >
        <AccordionHeader onClick={() => handleopen (2)}>
        <Typography
                          variant="h5"
                          className="text-lg font-bold leading-none mb-5 text-white"
                        >
                          Quality Control Manager Diagnosis
                        </Typography>
        </AccordionHeader>
        <AccordionBody>
                       
                        <ul className="list-none grid grid-cols-2" key={item.id}>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              Power On Check Whether LCD Display Is Normal
                            </strong>
                            <p className="text-gray-300">{item?.PowerOnCheckWhetherLCDDisplayIsNormal}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              the Logo When Power On And Animation Must Display
                            </strong>
                            <p className="text-gray-300">
                              {item?.TheLogoWhenPowerOnAndAnimationMustDisplay}
                            </p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              the LCD Display Contrast Quality Is Bad
                            </strong>
                            <p className="text-gray-300">{item?.TheLCDDisplayContrastQualityIsBad}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              lcd Display Check Symbols OnDesk
                            </strong>
                            <p className="text-gray-300"> {item?.CheckFlipByFlipingThreeTimes}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              check Flip By Fliping Three Times
                            </strong>
                            <p className="text-gray-300">{item?.CheckFlipByFlipingThreeTimes}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              key board Function Test
                            </strong>
                            <p className="text-gray-300">{item?.KeyboardFunctionTest}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              test To Check If TouchPad Is Working Or Has Rough
                              Surface
                            </strong>
                            <p className="text-gray-300">
                              { item?.TestToCheckIfTouchPadIsWorkingOrHasRoughSurface }
                            </p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              check Mouse Right Or Left Click
                            </strong>
                            <p className="text-gray-300">{item?.CheckMouseRightOrLeftClick}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              check Speaker If Sound Is Clear For Hearing
                            </strong>
                            <p className="text-gray-300">{item?.CheckSpeakerIfSoundIsClearForHearing}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              check Microphone By Recording A Voice Message To
                              Ensure Clarity
                            </strong>
                            <p className="text-gray-300">
                              {
                                item?.CheckMicrophoneByRecordingAVoiceMessageToEnsureClarity
                              }
                            </p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              check Lcd Colours
                            </strong>
                            <p className="text-gray-300">{item?.CheckLcdColours}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              camera Test No Abnormal Display After Enter
                              Preview Screen
                            </strong>
                            <p className="text-gray-300">
                              {
                                item?.CameraTestNoAbnormalDisplayAfterEnterPreviewScreen
                              }
                            </p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              check Software On Computer
                            </strong>
                            <p className="text-gray-300">{item?.CheckSoftwareOnComputer}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              check Wifi Symbol
                            </strong>
                            <p className="text-gray-300">{item?.CheckWifiSymbol}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              test For Bluetooth
                            </strong>
                            <p className="text-gray-300">{item?.TestForBluetooth}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              standard Detail Checking Of Systems Setting
                            </strong>
                            <p className="text-gray-300">{item?.StandardDetailCheckingOfSystemsSetting}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              physical Check To Test If Fan Is Working
                            </strong>
                            <p className="text-gray-300">{item?.PhysicalCheckToTestIfFanIsWorking}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              check Usb Slots If Its Functioning
                            </strong>
                            <p className="text-gray-300">{item?.CheckUsbSlotsIfItsFunctioning}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              Check Other Buttons
                            </strong>
                            <p className="text-gray-300">{item?.CheckOtherButtons}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              sim Slot Cannot Register SIM Card
                            </strong>
                            <p className="text-gray-300">{item?.SimSlotCannotRegisterSIMCard}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-white text-lg capitalize">
                              shutdown Laptop Or Desktop After Above Checking Done
                            </strong>
                            <p className="text-gray-300">
                              {
                                item?.ShutdownLaptopOrDesktopAfterAboveCheckingDone
                              }
                            </p>
                          </li>
                        </ul>
                    
          </AccordionBody>
          </Accordion>
        </div>
        <div className="flex flex-row  gap-5 mt-5">
              <ReviewModalButton />
              <AcceptModalButton />
             <RejectModalButton />
            </div>
      </Alert>
    </React.Fragment>
  );
}

export default JobCardApprovals;

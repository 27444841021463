import { Card, CardBody } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { IStore } from "../../../state/app.reducer";

export default function Profile() {
  const { user, loading } = useSelector((reducer: IStore) => reducer.state);

  return (
    <div className="py-10">
      <div>
        <div className="mb-4 flex flex-col">
          <Card className="p-2" shadow={false}>
            <CardBody>
              <div className="flex gap-10 justify-between my-4">
                <h1 className="text-lg font-bold">Full Name</h1>
                <h2 className="text-lg font-medium text-gray-500">
                  {`${user.first_name} ${user.last_name}`}{" "}
                </h2>
              </div>
              <hr />
              <div className="flex gap-10 justify-between my-4">
                <h1 className="text-lg font-bold">Role</h1>
                <h2 className="text-lg font-medium text-gray-500">
                  {user.role}
                </h2>
              </div>
              <hr />
              <div className="flex gap-10 justify-between my-4">
                <h1 className="text-lg font-bold">Email</h1>
                <h2 className="text-lg font-medium text-gray-500">
                  {user.email}
                </h2>
              </div>
              <hr />
              <div className="flex gap-10 justify-between my-4">
                <h1 className="text-lg font-bold">Phone</h1>
                <h2 className="text-lg font-medium text-gray-500">
                  {user.mobile}
                </h2>
              </div>
              <hr />
              <div className="flex gap-10 justify-between my-4">
                <h1 className="text-lg font-bold">Company</h1>
                <h2 className="text-lg font-medium text-gray-500">
                  {user.company_name}
                </h2>
              </div>
              <div className="flex gap-10 justify-between my-4">
                <h1 className="text-lg font-bold">Date Registered</h1>
                <h2 className="text-lg font-medium text-gray-500">
                  {new Date(user.createdAt!).toDateString()}
                </h2>
              </div>
              {user.adminType === "PARTNER" && (
                <>
                  <div className="flex gap-10 justify-between my-4">
                    <h1 className="text-lg font-bold">Company</h1>
                    <h2 className="text-lg font-medium text-gray-500">
                      {user.company_name}
                    </h2>
                  </div>
                  <hr />
                  <div className="flex gap-10 justify-between my-4">
                    <h1 className="text-lg font-bold">District</h1>
                    <h2 className="text-lg font-medium text-gray-500">
                      {user.district}
                    </h2>
                  </div>
                  <hr />
                  <div className="flex gap-10 justify-between my-4">
                    <h1 className="text-lg font-bold">Region</h1>
                    <h2 className="text-lg font-medium text-gray-500">
                      {user.region}
                    </h2>
                  </div>
                  <hr />
                  <div className="flex gap-10 justify-between my-4">
                    <h1 className="text-lg font-bold">Digital Address</h1>
                    <h2 className="text-lg font-medium text-gray-500">
                      {user.digital_address}
                    </h2>
                  </div>
                  <hr />
                  <div className="flex gap-10 justify-between my-4">
                    <h1 className="text-lg font-bold">Certified</h1>
                    <h2 className="text-lg font-medium text-gray-500">
                      {user.certified ? 'YES' : 'NO'}
                    </h2>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

import { Fragment, useState, useEffect } from 'react';
import { useMaterialTailwindController } from '../../../context';
import {
  Button,
  DialogHeader,
  DialogFooter,
  Typography,
  Input,
} from '@material-tailwind/react';
import { getCategory, getDevices, setLoading } from '../../../state/app.state';
import { toast } from 'react-toastify';
import { Spinner } from 'flowbite-react';
import $service from '../../../services/app.service';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IStore } from '../../../state/app.reducer';
import { Dialog, DialogContent, Autocomplete, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

const AddDevicetoCustomerModal = () => {
  const { customerId } = useParams<string>();
  const { controller, discharge } = useMaterialTailwindController();
  const { customerdeviceModal } = controller;
  const dispatch = useDispatch();
  const { loading, devices, categorys } = useSelector(
    (reducer: IStore) => reducer.state
  );
  const [form, setForm] = useState<any>();

  useEffect(() => {
    dispatch(getDevices());
    dispatch(getCategory());
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>();

  const validateSerialNumber = (value: string) => {
    return value.startsWith('KATT');
  }
  const handleOpen = () =>
    discharge({ type: 'CUSTOMER_MODAL', customerdeviceModal: false });

  const onSubmit = async (data: any) => {
    try {
      if (
        !data.category ||
        !data.color ||
        !data.serialNumber ||
        !data.model ||
        !data.year
      ) {
        throw Error('Please fill the form completely');
      }
      const payload = {
        customerId: customerId,
        serialNumber: data.serialNumber,
        category: data.category,
        model: data.model,
        color: data.color,
        year: data.year,
      };
      setLoading(true);
      const response = await $service.addCustomerDevice(payload);
      console.log(payload);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      discharge({ type: 'CUSTOMER_MODAL', customerdeviceModal: false });
      toast(response.message);
    } catch (err) {
      toast((err as Error)?.message);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Dialog
        open={customerdeviceModal}
        onClose={handleOpen}
        className='h-screen relative '
      >
        <DialogHeader className='text-lg'>Add Device To Customer</DialogHeader>
        <DialogContent sx={{ width: '500px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className=' w-full'>
              {/* <Autocomplete
                disablePortal
                className="mb-[1.5rem]"
                id="combo-box-demo"
                disableClearable
                options={devices?.map((d) => ({
                  label: `${d.model} ${d.serialNumber}`,
                  id: d._id,
                }))}
                onChange={(_, { id }: any) => setForm(id)}
                renderInput={(params) => (
                  <TextField {...params} label="Select Customer's Store Item" />
                )}
              /> */}
              <div className='mb-2'>
                <Typography className='text-sm font-semibold'>
                  Device Name Category
                </Typography>
                <Controller
                  name='category'
                  control={control}
                  defaultValue='' // Set the default value for the select input
                  rules={{ required: 'This field is required' }} // Add any validation rules
                  render={({ field }: any) => (
                    <select
                      {...field}
                      className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                    >
                        <option selected>Choose a device</option>
                      {categorys.map((category: any) => (
                        <option value={category?._id}>{category?.name}</option>
                      ))}
                    </select>
                  )}
                />
                {errors.category && (
                  <span className='text-red-500'>
                    This field is not required
                  </span>
                )}
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold'>Device Type</Typography>
                <Controller
                  name='model'
                  control={control}
                  defaultValue='' // Set the default value for the select input
                  rules={{ required: 'This field is required' }} // Add any validation rules
                  render={({ field }: any) => (
                    <select
                      {...field}
                      className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                    >
                        <option selected>Choose a device type</option>
                      <option value='laptop'>Laptop</option>
                      <option value='tablet'>Tablets</option>
                    </select>
                  )}
                />
                {errors.model && (
                  <span className='text-red-500'>
                    This field is not required
                  </span>
                )}
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold'>
                  Serial Number
                </Typography>
                <Input
                  type='text'
                  {...register('serialNumber', {
                    required: true,
                    minLength: 16,
                    maxLength: 16,
                    validate: validateSerialNumber,
                  })}
                />
              {errors.serialNumber && errors.serialNumber.type === 'validate' && (
          <span className="text-red-500">Serial Number must start with "KATT"</span>
        )}
        {errors.serialNumber && errors.serialNumber.type !== 'validate' && (
          <span className="text-red-500">Serial Number must be exactly 16 characters</span>
        )}
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold'>Color</Typography>
                <Controller
                  name='color'
                  control={control}
                  defaultValue='' // Set the default value for the select input
                  rules={{ required: 'This field is required' }} // Add any validation rules
                  render={({ field }: any) => (
                    <select
                      {...field}
                      className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                    >
                        <option selected>Choose a color</option>
                      <option value='black'>Black</option>
                      <option value='sliver'>Sliver</option>
                      <option value='blue'>Blue</option>
                      <option value='other'>Other</option>
                    </select>
                  )}
                />
                {errors.color && (
                  <span className='text-red-500'>
                    This field is not required
                  </span>
                )}
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold'>Year</Typography>
                <Controller
                  name='year'
                  control={control}
                  defaultValue='' // Set the default value for the select input
                  rules={{ required: 'This field is required' }} // Add any validation rules
                  render={({ field }: any) => (
                    <select
                      {...field}
                      className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                    >
                      <option value='2020'>2020</option>
                      <option value='2021'>2021</option>
                      <option value='2022'>2022</option>
                      <option value='2023'>2023</option>
                      <option value='2024'>2024</option>
                    </select>
                  )}
                />
                {errors.year && (
                  <span className='text-red-500'>
                    This field is not required
                  </span>
                )}
              </div>
            </div>

            <Button
              variant='filled'
              color='green'
              className='w-full mt-2'
              disabled={loading}
              type='submit'
            >
              {loading ? (
                <div className='flex gap-4 items-center justify-center'>
                  <Spinner size='sm' aria-label='Spinner button example' />
                  <Typography variant='h5' className='text-sm'>
                    Please Wait.....
                  </Typography>
                </div>
              ) : (
                'Add Device to Customer'
              )}
            </Button>
          </form>
        </DialogContent>
        <DialogFooter>
          <Button
            variant='text'
            color='red'
            onClick={handleOpen}
            className='mr-1'
          >
            Cancel
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default AddDevicetoCustomerModal;

import { useState } from "react";
import { Button } from "@material-tailwind/react";
import PowerONForm from "./standard/PowerONForm";
import FactoryTestForm from "./standard/FactoryTestForm";
import OtherCheckForm from "./standard/OtherCheckForm";
import SuccessForm from "./standard/SuccessForm";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStore } from "../../../state/app.reducer";
import $service from "../../../services/app.service";
import { toast } from "react-toastify";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  PowerOnCheckWhetherLCDDisplayIsNormal: Yup.string().required("Defects Severity is required"),
  TheLogoWhenPowerOnAndAnimationMustDisplay: Yup.string().required("Defects Severity is required"),
  TheLCDDisplayContrastQualityIsBad: Yup.string().required("Defects Severity is required"),
  CheckFlipByFlipingThreeTimes: Yup.string().required("Defects Severity is required"),
});


function LaptopStandardForm() {
  const { _id } = useParams<string>();
  const [form, setForm] = useState<any>({});
  const { user } = useSelector((reducer: IStore) => reducer.state);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState(1);

  const submit = async () => {
    try {
      await validationSchema.validate(form, { abortEarly: false });

      const payload: any = {
        jobcardId: _id,
        approver: user._id,
        questionaire: form,
      };
      setLoading(true);
      const response = await $service.addDiagnosis(payload);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast(response.message);
      nextStep();
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  function nextStep() {
    if (!form) {
      alert('Please select an option.'); // Display an alert if form is not selected
    } else {
      setStep(step + 1); // Proceed to the next step if form is selected
    }
  }

  function previousStep() {
    setStep(step - 1);
  }

  const setFormValue = (k: string, v: string) => {
    console.log(k, v);
    setForm({ ...form, [k]: v });
  };

  switch (step) {
    case 1:
      return (
        <>
          <PowerONForm setFormValue={setFormValue} />
          <Button
            color="teal"
            variant="gradient"
            className="w-40"
            onClick={nextStep}
          >
            Next
          </Button>
        </>
      );

    case 2:
      return (
        <>
          <FactoryTestForm setFormValue={setFormValue} />
          <div className="flex justify-between">
            <Button
              color="teal"
              variant="gradient"
              className="w-40"
              onClick={previousStep}
            >
              Back
            </Button>
            <Button
              color="teal"
              variant="gradient"
              className="w-40"
              onClick={nextStep}
            >
              Next
            </Button>
          </div>
        </>
      );
    case 3:
      return (
        <>
          <OtherCheckForm setFormValue={setFormValue} />
          <div className="flex justify-between">
            <Button
              color="teal"
              variant="gradient"
              className="w-40"
              onClick={previousStep}
            >
              Back
            </Button>
            <Button
              color="green"
              variant="gradient"
              className="w-40"
              onClick={submit}
            >
              Submit
            </Button>
          </div>
        </>
      );

    default:
      return (
        <>
          <SuccessForm />
        </>
      );
  }
}

export default LaptopStandardForm;

import Sidenav from "../component/layout/Sidenav";
import DashboardNavbar from "../component/layout/DashboardNavbar";
import Footer from "../component/layout/Footer";
import routes, { routex } from "../Routes/routes";
import { Routes, Route, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
  Button,
} from "@material-tailwind/react";
import DetailPageSkeleton from "../component/layout/DetailPageSkeleton";
import Logo from "../Assets/images/logo.png";
import WithFunctionGuard from "../guards/FunctionGuard";
import { useSelector, useDispatch } from "react-redux";
import { useMaterialTailwindController } from "../context/index";
import UpdateDeviceForm from "../component/element/Stocks/UpdateDeviceForm";
import { useEffect, useState } from "react";
import $service from "../services/app.service";

const StockDetails = () => {
  const { discharge } = useMaterialTailwindController();
  const { _id } = useParams<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { roles, status,  } = useSelector((reducer: any) => reducer.state);
  const dispatch = useDispatch()
  const [device, setDevice] = useState<any>([]);
  const [serialNumber, setSerialNumber] = useState([]);

  const storeItemId  = _id

  console.log(storeItemId);
   
  useEffect(() => {
    getDevices();
  }, [])

const getDevices = async(): Promise<void> => {
  try{
if(!storeItemId || storeItemId === '') return;
setLoading(true);
const response = await $service.getDeviceById!(storeItemId);
if (!response.success) {
  throw Error(response.message);
}
const d = response.data as any
setLoading(false);
setDevice(d)

const [serialNumber,] = await Promise.all([
  $service.getserialNumber(d._id)
])
   switch(true) {
    case serialNumber.success:
    setSerialNumber(serialNumber.data)
    break;
    default:
      break;
   }
  
setLoading(false)
  }catch(err) {
    setLoading(false)
}
};

const getMenu = () => {
  try {
    return roles.menu ? routex[roles.menu] : [];
  } catch (err) {
    return [];
  }
};

  const UpdateDeviceButton = WithFunctionGuard(() => (
    <Button
      color="green"
      key={"update-device"}
      onClick={() =>
        discharge({ type: "UPDATE_DEVICES", updateDevices: false })
      }
    >
      Update{" "}
    </Button>
  ));


  return (
    <div className="min-h-screen py-6 bg-blue-gray-50/50">
      <Sidenav routex={getMenu()} brandImg={Logo} />
      <div className="p-4 xl:ml-80">
        <DashboardNavbar />
        <Routes>
          {routes.map(
            ({ pages, layout }) =>
              layout === "katcare-developer" &&
              pages.map(({ path, element }) => (
                <Route path={path} element={element} key={path} />
              ))
          )}
        </Routes>
        {/* Card body */}
        <div className="mt-20">
          {isLoading ? (
            <DetailPageSkeleton />
          ) : (
            <div>
              <Card>
                <UpdateDeviceForm />
                <CardHeader className="h-48 p-3 flex items-center" color="blue">
                  <Typography variant="h2">{device?.model}</Typography>
                </CardHeader>
                <CardBody>
                  <div className="grid grid-cols-2">
                    <div className="flex gap-10 items-center">
                      <h2 className=" text-gray-500 truncate ">Category:</h2>
                      <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                       {device?.category}
                      </div>
                    </div>
                    <div className="flex justify-end gap-8">
                      <UpdateDeviceButton />
                    </div>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-12">
                <Card>
                  <CardBody>
                    <div className="grid grid-cols-1">
                      <div className="flex gap-10">
                        <h2 className=" text-gray-500 truncate ">Category:</h2>
                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                          Computer
                        </div>
                      </div>

                      <div className="flex gap-10">
                        <h2 className=" text-gray-500 truncate ">Type:</h2>
                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                          Laptop
                        </div>
                      </div>
                      <div className="flex gap-10">
                        <h2 className=" text-gray-500 truncate ">
                          Serial Number:
                        </h2>
                        <div className="inline-flex items-center text-base font-semibold text-orange-500 ">
                          ********
                        </div>
                      </div>

                      <div className="flex gap-10">
                        <h2 className=" text-gray-500 truncate ">Price:</h2>
                        <div className="inline-flex items-center text-base font-semibold text-blue-500 ">
                          20
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          )}
        </div>

        <div className="text-blue-gray-600">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default StockDetails;



export const jobCardData: any = {
  options: {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    legend: {
      show: false,
    },
  },
  series: [
    {
      name: "completed Job Cards",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
    {
      name: "Job Cards In Progress",
      data: [20, 44, 30, 23, 27, 33, 44, 91],
    },
    {
      name: "Job Cards Under Diagnosis",
      data: [15, 33, 22, 44, 55, 68, 29, 7],
    },
  ],
  responsive: [
    {
      breakpoint: 500,
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

import { useEffect, Fragment, useState } from 'react';
import {
  Card,
  CardHeader,
  Button,
  Typography,
  CardBody,
} from '@material-tailwind/react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../state/app.reducer';
import WithFunctionGuard from '../guards/FunctionGuard';
import ProductTable from '../component/element/tables/ProductTable';
import { useNavigate } from 'react-router-dom';
import { getProducts } from '../state/app.state';
import { useMaterialTailwindController } from '../context';
import AddProductShop from '../component/element/Stocks/addProductshop';

const ProductListPage = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((reducer: IStore) => reducer.state);
  const [trigger, setTrigger] = useState(0);
  const navigate = useNavigate();
  const {discharge} = useMaterialTailwindController();
  const [selectedProductIds, setSelectedProductIds] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getProducts());
  }, []);


  const handleCheckboxChange = (productId: string) => {
    // Toggle selection
    setSelectedProductIds((prevSelected) => {
      if (prevSelected.includes(productId)) {
        return prevSelected.filter((id) => id !== productId);
      } else {
        return [...prevSelected, productId];
      }
    });

    discharge({
      type: 'ADD_PRODUCT_SHOP',
      addProductToShop: false,
    });
  };


  const filteredData = products.map((products) => {
    return {
      name: products?.name,
      price: products?.price,
      color: products?.color,
      category: products?.category?.name,
      createdAt: products.createdAt,
    };
  });

  const AddProductButton = WithFunctionGuard(() => (
    <Button
      variant='gradient'
      color='blue'
      onClick={() => navigate('/add-product')}
      key={'add-product'}
    >
      Add new product
    </Button>
  ));

  const ImportImageDeviceButton = WithFunctionGuard(() => (
    <Button
      variant='filled'
      color='cyan'
      key={'import-image'}
      onClick={() => console.log('Import Image')}
    >
      Import Device Image
    </Button>
  ));

  return (
    <Fragment>
      <div className='mt-12'>
        <AddProductShop selectedProductIds={selectedProductIds}  />
        <div>
          {/* {stocks.loading ? (
            <CustomSkeleton />
          ) : ( */}
          <>
            <div className='mt-12'>
              <div className='mb-20 grid gap-y-10 gap-x-6 md:grid-cols-1 xl:grid-cols-1'>
                <div></div>
                <div className='flex justify-end gap-10'>
                  {/* <Button >
                      Add to Shop
                    </Button> */}
                  <AddProductButton />
                  <ImportImageDeviceButton />
                  <CSVLink data={filteredData} filename={'stockrequest.csv'}>
                    <Button variant='gradient' color='blue'>
                      Export CSV
                    </Button>
                  </CSVLink>
                  <Button
                    variant='outlined'
                    color='blue'
                    onClick={() => {
                      setTrigger((trigger) => trigger + 1);
                    }}
                  >
                    Print
                  </Button>
                </div>
              </div>

              <Card shadow={false}>
                <CardHeader
                  variant='gradient'
                  color='blue'
                  className='mb-8 p-4'
                >
                  <Typography variant='h6' color='white'>
                    Products List
                  </Typography>
                </CardHeader>
                <CardBody className='overflow-x-scroll px-0 pt-0 pb-2'>
                  {/* Add a check for whether the data is loaded before rendering */}
                  {/* {stocks.loading ? (
                      <CustomSkeleton />
                    ) : ( */}
                  <ProductTable tableData={products} trigger={trigger} 
                  selectedProductIds={selectedProductIds}
                    onCheckboxChange={handleCheckboxChange}
                    
                    
/>
                  {/* )} */}
                </CardBody>
              </Card>
            </div>
          </>
          {/* )} */}
        </div>
      </div>
    </Fragment>
  );
};

export default ProductListPage;

import React, { useEffect, useState } from "react";
import { Spinner } from "flowbite-react";
import $service from "../services/app.service";
import { Link, useNavigate, useLocation } from "react-router-dom";

function OtpPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<{
    email: string;
    password: string;
    otp: string;
    confirmPassword: string;
  }>({
    email: "",
    password: "",
    otp: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const { email } = location.state;
    if (!email) {
      navigate("/reset-password");
    }
    setFormValue("email", email);
  }, []);

  const setFormValue = (key: string, value: string) => {
    setForm({ ...form, [key]: value });
  };

  const reset = async () => {
    try {
      const { confirmPassword, password, otp, email } = form;
      if (
        confirmPassword === "" ||
        password === "" ||
        otp === "" ||
        email === ""
      ) {
        throw Error("Please provide all data");
      }
      if (confirmPassword !== password) {
        throw Error("Passwords does not match");
      }
      setLoading(true);
      const data = {
        email,
        password,
        otp,
      };
      const response = await $service.resetPassword!(data);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      navigate("/Login");
    } catch (err) {
      alert((err as Error).message);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
        <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
          Reset Password
        </h2>
        <p className="font-light text-gray-500 dark:text-gray-400">
          An OTP will be sent you to the provided email to verify your account
        </p>
        <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5">
          <div>
            <label
              id="otp"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              OTP
            </label>
            <input
              type="text"
              name="otp"
              id="otp"
              onChange={(e) => setFormValue("otp", e.target.value.trim())}
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="12345"
            />
          </div>
          <div>
            <label
              id="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              New Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              onChange={(e) => setFormValue("password", e.target.value.trim())}
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
            />
          </div>
          <div>
            <label
              id="confirm-password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Confirm password
            </label>
            <input
              type="confirm-password"
              name="confirm-password"
              id="confirm-password"
              placeholder="••••••••"
              onChange={(e) =>
                setFormValue("confirmPassword", e.target.value.trim())
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
            />
          </div>

          <button
            onClick={reset}
            disabled={loading}
            type="submit"
            className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
          >
            {loading ? <Spinner /> : <span>Reset</span>}
          </button>
          <p className="text-sm font-light text-gray-500 dark:text-gray-400">
            Login into your account?{" "}
            <Link to="/" className="font-medium text-blue-600 hover:underline ">
              Login
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default OtpPage;

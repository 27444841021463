import React from 'react'
import LandingNavbar from '../component/layout/LandingNavbar'
import LandingFooter from '../component/layout/LandingFooter'

const WarrantyCheckerPage = () => {
  return (
    <div className='main'>
    <LandingNavbar />
    <LandingFooter />
    </div>
  )
}

export default WarrantyCheckerPage
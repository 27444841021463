import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  IAccessoryRequestTypes,
  IAdmin,
  IAdminRoles,
  IApprovals,
  ICustomer,
  ICustomerDevices,
  IDevices,
  IJobcards,
  IProducts,
  IProductsCategory,
  IStatus,
  IStock,
  Iinventory,
  Iitems,
} from "../models/app.model";
import $service from "../services/app.service";
import deviceHelpers from "../helpers/devices.helpers";
import customerHelper from "../helpers/customers.helper";
import jobcardHelper from "../helpers/jobcard.helper";
import roles, { IRole } from "../helpers/roles";
import accessoryHelpers from "../helpers/accessory.helpers";


export interface IStateModel {
  loading: boolean;
  user: Partial<IAdmin>;
  token: string;
  refreshToken: string;
  approvals: Array<IApprovals>;
  jobcards: Array<IJobcards>;
  devices: Array<IDevices>;
  customers: Array<ICustomer>;
  customerDevices: Array<ICustomerDevices>;
  accessoryRequest: Array<IAccessoryRequestTypes>;
  status: IStatus[];
  roles: IRole;
  notifications: any[];
  admins: IAdmin[];
  adminRoles: IAdminRoles[];
  jobcardMetrics: {
    pending: number;
    total: number;
    completed: number;
    approved: number;
    rejected: number;
  };
  approvalMetrics: number;
  accessoryMetrics: {
    pending: number;
    total: number;
    completed: number;
    approved: number;
    rejected: number;
  };
  salesMetrics: any;
  stocks: Array<IStock>;
  stockItems: Array<Iitems>;
  inventory: Array<Iinventory>;
  sales: any;
  katPartners: any;
  serialNumber: any;
  categorys: any;
  products: Array<IProducts>;
  shops: any;
  sale: any;
}

const initialState: IStateModel = {
  loading: false,
  user: {},
  admins: [],
  adminRoles: [],
  notifications: [],
  roles: {
    menu: [],
    functions: [],
    status: [],
  },
  approvals: [],
  jobcards: [],
  devices: [],
  customers: [],
  status: [],
  token: "",
  refreshToken: "",
  customerDevices: [],
  accessoryRequest: [],
  jobcardMetrics: {
    pending: 0,
    approved: 0,
    completed: 0,
    rejected: 0,
    total: 0,
  },
  approvalMetrics: 0,
  accessoryMetrics: {
    pending: 0,
    approved: 0,
    completed: 0,
    rejected: 0,
    total: 0,
  },
  salesMetrics: {},
  stocks: [],
  stockItems: [],
  inventory: [],
  sales: [],
  katPartners: [],
  serialNumber: [],
  categorys: [],
  products: [],
  shops: [],
  sale: []
};

export const autoLogin: any = createAsyncThunk(
  "store/login",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const [_id, refreshToken] = await Promise.resolve([
        localStorage.getItem("kat:user:id"),
        localStorage.getItem("kat:refresh:token"),
      ]);
      if (typeof _id !== "string" || typeof refreshToken !== "string") {
        throw Error("Oops!");
      }
      const response = await $service.refreshToken!({ refreshToken });
      if (!response.success) {
        throw Error("Oops!");
      }
      const token = response.token;
      const rft = response.refreshToken;
      const role = response.role;
      $service.setToken!(token!);
      localStorage.setItem("kat:refresh:token", rft!);
      dispatch(setLoading(false));
      dispatch(setRole(role || ""));
      return {
        refreshToken: rft,
        _id,
      };
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getAdminRoles: any = createAsyncThunk(
  "store/get-admin-roles",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.getAdminRoles();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);


export const getCategory: any = createAsyncThunk(
  "store/categorys",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.getDeviceCategory();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getShops: any = createAsyncThunk(
  "store/shops",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.getShop();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);


export const getSalesOrders: any = createAsyncThunk(
  "store/sale",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.getSalesOrders();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getNotifications: any = createAsyncThunk(
  "store/notifications",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const { state }: any = getState();
      dispatch(setLoading(true));
      const response = await $service.getNotifications(state.user._id);
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getAdmins: any = createAsyncThunk(
  "store/admins",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.getAdmins();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

// export const getSerialNumber: any = createAsyncThunk(
//   "store/serialNumber",
//   async (_, { rejectWithValue, dispatch, getState }) => {
//     try {
//       dispatch(setLoading(true));
//       const response = await $service.getserialNumber();
//       if (!response.success) {
//         throw Error(response.message);
//       }
//       dispatch(setLoading(false));
//       return response.data;
//     } catch (err) {
//       dispatch(setLoading(false));
//       rejectWithValue(err);
//     }
//   }
// );

export const getApprovalStatus: any = createAsyncThunk(
  "store/approval-status",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.getStatus();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getMetrics: any = createAsyncThunk(
  "store/metrics",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      let [jcm, apm, acm] = await Promise.all([
        $service.jobcardMetrics(),
        $service.approvalMetrics(),
        $service.accessoryMetrics(),
      ]);
      dispatch(setLoading(false));
      jcm = jobcardHelper.formatMetrics(jcm.data || []);
      acm = accessoryHelpers.formatMetrics(acm.data || []);
      apm = apm.data || [];
      return { jcm, acm, apm };
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getCustomerDevices: any = createAsyncThunk(
  "store/customer-devices",
  async (id: string, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.getCustomerDevices!(id);
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getAccessoryRequest: any = createAsyncThunk(
  "store/accessory-request",
  async (roles: any, { rejectWithValue, dispatch, getState }) => {
    const { state }: any = getState();
    try {
      dispatch(setLoading(true));
      //   const response = typeof roles === 'object' && roles?.name !== 'KATCARE MANAGER'
      //   ? (await $service.getAccessoryRequestById(state.user._id)) as any // Use type assertion
      //   : (await $service.getAccessoryRequest!()) as any; // Use type assertion
      //   if(!response.success) {
      //     throw Error(response.message)
      // }
      const response = await $service.getAccessoryRequest!();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getCustomers: any = createAsyncThunk(
  "store/customers",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.getCustomers!();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getProducts: any = createAsyncThunk(
  "store/products",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.getProduct!();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getDevices: any = createAsyncThunk(
  "store/devices",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.getDevices!();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getapprovals: any = createAsyncThunk(
  "store/approvals",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const { state }: any = getState();
      dispatch(setLoading(true));
      const response = await $service.getDiagnosis!(state.user._id);
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getAllPartnerSales: any = createAsyncThunk(
  "store/sales",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const { state }: any = getState();
      dispatch(setLoading(true));
      const response = await $service.getSalesByPartnerId!(state.user._id);
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getJobcards: any = createAsyncThunk(
  "store/jobcards",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.allJobcards!();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getAllStock: any = createAsyncThunk(
  "store/stocks",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = await $service.getStockRequests!();
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getAllStockItems: any = createAsyncThunk(
  "store/stockItems",
  async (roles, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response =
        typeof roles === "string" && roles === "KATCARE-PARTNER"
          ? ((await $service.getStockItems!()) as any)
          : ((await $service.getStockRequests!()) as any);
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getOrders: any = createAsyncThunk(
  "store/orders",
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = (await $service.getOrders(params)) as any;
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);
export const getKatParners: any = createAsyncThunk(
  "store/katPartners",
  async (_region: string, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const response = (await $service.getPartners!(_region));
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getInventory: any = createAsyncThunk(
  "store/inventory",
  async (roles, { rejectWithValue, dispatch, getState }) => {
    const { state }: any = getState();
    try {
      dispatch(setLoading(true));
      const response =
        typeof roles === "string" && roles === "KATCARE-PARTNER"
          ? ((await $service.getInventoryRequestsById(state.user._id)) as any) // Use type assertion
          : ((await $service.getInventoryRequests()) as any); // Use type assertion
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);
// export const getInventory = createAsyncThunk(
//   "store/inventory",
//   async (roles, { rejectWithValue, dispatch, getState }) => {
//     const { state }: any = getState();
//     try {
//       dispatch(setLoading(true));
//       let response;

//       if ( typeof roles === 'string' && roles === 'KATCARE-PARTNER') {
//         const partnerResponse = await $service.getInventoryRequestsById(state.user._id);
//         response = await partnerResponse.data();
//       } else {
//         response = await $service.getInventoryRequests();
//       }

//       if (!response.success) {
//         throw new Error(response.message);
//       }

//       dispatch(setLoading(false));
//       return response.data;
//     } catch (err) {
//       dispatch(setLoading(false));
//       rejectWithValue(err);
//     }
//   }
// );

export const logout: any = createAsyncThunk(
  "store/revoke",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const refreshToken = localStorage.getItem("kat:refresh:token");
      if (
        !refreshToken ||
        typeof refreshToken !== "string" ||
        refreshToken === ""
      ) {
        throw Error("Invalid Token");
      }
      dispatch(setLoading(true));
      const response = await $service.revokeToken!(refreshToken);
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

export const getprofile: any = createAsyncThunk(
  "store/profile",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const { state }: any = getState();
      dispatch(setLoading(true));
      const response = await $service.profile!(state.user._id);
      if (!response.success) {
        throw Error(response.message);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      rejectWithValue(err);
    }
  }
);

const state = createSlice({
  name: "state",
  initialState,
  reducers: {
    setRole: (state: IStateModel, action: PayloadAction<string>) => {
      state.roles = roles[action.payload] as IRole;
    },
    setLoading: (state: IStateModel, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setRefreshToken: (state: IStateModel, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    setToken: (state: IStateModel, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUser: (state: IStateModel, action: PayloadAction<Partial<IAdmin>>) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [getJobcards.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<IJobcards>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.jobcards = action.payload;
      }
    },
    [getAllStock.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<IStock>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.stocks = action.payload;
      }
    },
    [getAllStockItems.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<Iitems>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.stockItems = action.payload;
      }
    },
    [getInventory.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<Iinventory>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.inventory = action.payload;
      }
    },
    [getprofile.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<IAdmin>
    ) => {
      state.user = action.payload;
    },
    [getapprovals.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<IApprovals>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.approvals = action.payload;
      }
    },
    [autoLogin.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<{ refreshToken: string; _id: string }>
    ) => {
      try {
        state.user._id = action.payload._id;
        state.refreshToken = action.payload.refreshToken;
      } catch (err) {}
    },
    [logout.fulfilled]: (state: IStateModel) => {
      state.user = {};
      state.refreshToken = "";
      localStorage.removeItem("kat:user:id");
      localStorage.removeItem("kat:refresh:token");
    },
    [getOrders.fulfilled]: (
      state: any,
      action: PayloadAction<Array<any>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.salesMetrics = action.payload;
      }
    },
    [getCustomers.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<ICustomer>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.customers = action.payload;
      }
    },
    [getDevices.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<IDevices>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.devices = action.payload;
      }
    },
    [getAllPartnerSales.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<any>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.sales = action.payload;
      }
    },
    [getMetrics.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<any>
    ) => {
      const { jcm, apm, acm } = action.payload;
      state.jobcardMetrics = jcm;
      state.approvalMetrics = apm;
      state.accessoryMetrics = acm;
    },
    [getCustomerDevices.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<ICustomerDevices>>
    ) => {
      state.customerDevices = action.payload;
    },
    [getAccessoryRequest.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<IAccessoryRequestTypes>>
    ) => {
      state.accessoryRequest = action.payload || [];
    },
    [getApprovalStatus.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<IStatus>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.status = action.payload;
      }
    },
    [getNotifications.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<any>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.notifications = action.payload;
      }
    },
    [getAdmins.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<IAdmin>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.admins = action.payload;
      }
    },
    [getAdminRoles.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<IAdminRoles>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.adminRoles = action.payload;
      }
    },
    [getCategory.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<IProductsCategory>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.categorys = action.payload;
      }
    },
    [getProducts.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<IProducts>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.products = action.payload;
      }
    },
    [getShops.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<any>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.shops = action.payload;
      }
    },
    [getSalesOrders.fulfilled]: (
      state: IStateModel,
      action: PayloadAction<Array<any>>
    ) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.sale = action.payload;
      }
    },
  },
});

// Define custom selectors here
const devicesTableData = (state: any) => {
  return deviceHelpers.remapDevices(state.devices);
};

const customersTableData = (state: IStateModel) => {
  return customerHelper.remapCustomers(state.customers);
};

// create custom selector here <--;
export const devicesTableDataSelector = createSelector(
  devicesTableData,
  (d) => {
    return d;
  }
);

export const customersTableDataSelector = createSelector(
  customersTableData,
  (d) => {
    return d;
  }
);

export const { setToken, setUser, setRefreshToken, setLoading, setRole } =
  state.actions;
export default state.reducer;

import { Button, DialogFooter, DialogHeader, Input, Typography } from '@material-tailwind/react'
import React from 'react'
import { useMaterialTailwindController } from '../../../context';
import { Dialog, DialogContent } from '@mui/material';


function UpdateDeviceForm() {
    const { discharge, controller } = useMaterialTailwindController();
    const { updateDevices } = controller;

    const handleOpen = () =>
    discharge({ type: "UPDATE_DEVICES", updateDevices: false });

  return (
    <Dialog open={updateDevices} onClose={handleOpen} sx={{ width: "100%" }}>
    <DialogHeader className="text-lg">Update Device Quantity </DialogHeader>
    <DialogContent sx={{ width: "500px" }}>
    <form>
                <div className="mb-2">
                  <Typography className="text-sm font-semibold">
                  Quantity
                  </Typography>
                  <Input  />
                </div>
                </form>
     </DialogContent>
     <DialogFooter>
        <Button color="green">
            Submit
        </Button>
            <Button
              variant="filled"
              color="red"
              onClick={handleOpen}
              className="ml-3"
            >
              Cancel
            </Button>
          </DialogFooter>
     </Dialog>
   
  )
}

export default UpdateDeviceForm
import React from "react";
import { Select, Option, Typography } from "@material-tailwind/react";

interface IPowerForm {
  setFormValue: (k: string, v: string) => void;
}

const OtherCheckForm = ({ setFormValue }: IPowerForm) => {
  return (
    <div className="">
      <Typography variant="lead" className="my-1">
        {" "}
        Other Checks{" "}
      </Typography>
      <div className="my-5 w-screen max-w-screen-md">
        <Typography variant="lead" className="my-1 text-md font-bold">
          software:
        </Typography>
        <div className="flex gap-5 my-2 ">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            check software on computer
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("CheckSoftwareOnComputer", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          Wifi
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            check wifi symbol
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("CheckWifiSymbol", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          Bluetooth
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            test for bluetooth
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("TestForBluetooth", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          System Settings
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            standard detail checking of systems setting
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("StandardDetailCheckingOfSystemsSetting", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          CPU/Fan
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            physical check to test if fan is working
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("PhysicalCheckToTestIfFanIsWorking", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          Usb Slot
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            check usb slots if its functioning
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("CheckUsbSlotsIfItsFunctioning", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          Buttons
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            check other buttons
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("CheckOtherButtons", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          Sim Card Slot
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            Sim slot cannot register SIM card
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("SimSlotCannotRegisterSIMCard", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          Shutdown
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            shutdown laptop or desktop after above checking done
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("ShutdownLaptopOrDesktopAfterAboveCheckingDone", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default OtherCheckForm;

import React, { useEffect, useState } from "react";
import { Spinner } from "flowbite-react";
import $service from "../services/app.service";
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/images/KATCarelogo.png"


function ResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const sendEmail = async () => {
    try {
      if (email === "") {
        throw Error("Email is not valid");
      }
      setLoading(true);
      const response = await $service.sendOtp!(email);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      navigate("/otp", { state: { email } });
    } catch (err) {
      setLoading(false);
      alert((err as Error).message);
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-10"
        >
         <img src={Logo} className=" h-16 contain sm:h-32" alt="Katcare Logo" />
        </a>
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Enter your email address here
          </h2>
          <p className="font-light text-gray-500 dark:text-gray-400">
            An OTP will be sent you to the provided email to verify your account
          </p>
          <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5">
            <div>
              <label
                id="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Your email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value.trim())}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
              />
            </div>

            <button
              onClick={sendEmail}
              disabled={loading}
              type="submit"
              className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            >
              {loading ? <Spinner /> : <span>Reset passwod</span>}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;

import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { IStore } from "../../../state/app.reducer"
import { useMaterialTailwindController } from "../../../context"
import {  Button, DialogFooter, DialogHeader } from "@material-tailwind/react"
import { DialogContent, Dialog } from "@mui/material"


const ShowSerialNumber = () => {
    const {serialNumber} = useSelector((reducer: IStore) => reducer.state)
    const dispatch = useDispatch()
    const { controller, discharge } = useMaterialTailwindController();
    const { showSerialNumber } = controller;



    const handleOpen = () =>
    discharge({ type: 'SHOW_SERIALNUMBER', showSerialNumber: false });

  return (
        <Dialog open={showSerialNumber} onClose={handleOpen} className="h-screen absolute ">
        <DialogHeader className='text-lg'>Add New Devices</DialogHeader>
        <DialogContent sx={{ width: "500px" }}>

        </DialogContent>
        <DialogFooter>
          <Button
            variant='filled'
            color='red'
            onClick={handleOpen}
            className='mr-1'
          >
            Cancel
          </Button>
        </DialogFooter>
        </Dialog>
  )
}

export default ShowSerialNumber
import React, { useContext, createContext, useReducer, useMemo } from "react";

interface MaterialTailwindState {
  openSidenav: boolean;
  sidenavColor: string;
  sidenavType: string;
  transparentNavbar: boolean;
  fixedNavbar: boolean;
  openConfigurator: boolean;
  openModal: boolean;
  jobModal: boolean;
  accessoryModal: boolean;
  detailaccesoryModal: boolean;
  customerdeviceModal: boolean;
  addDeviceModal: boolean;
  rejectModal: boolean;
  importModal: boolean;
  importPartner: boolean;
  transferredKat: boolean;
  stockRequest: boolean;
  updateDevices: boolean;
  addCategory: boolean;
  assignDelivery: boolean;
  showSerialNumber: boolean;
  addShop: boolean;
  addDeviceAccessory: boolean;
  addProductToShop: boolean;
}

interface Action {
  type?: string;
  value?: any;
}

interface MaterialTailwindControllerProviderProps {
  children: React.ReactNode;
  }

  const initialState: MaterialTailwindState = {
    openSidenav: false,
    sidenavColor: "blue",
    sidenavType: "dark",
    transparentNavbar: true,
    fixedNavbar: false,
    openConfigurator: false,
    openModal: false,
    jobModal: false,
    accessoryModal: false,
    detailaccesoryModal: false,
    customerdeviceModal: false,
    addDeviceModal: false,
    rejectModal: false,
    importModal: false,
    importPartner: false,
    transferredKat: false,
    stockRequest: false,
    updateDevices: false,
    addCategory: false,
    assignDelivery: false,
    showSerialNumber: false,
    addShop: false,
    addDeviceAccessory: false,
    addProductToShop: false,
  };


  const MaterialTailwind = createContext< MaterialTailwindState | any>(initialState);

export function reducer(state: MaterialTailwindState, action: Action):MaterialTailwindState {

  switch (action.type) {
    case "OPEN_SIDENAV": {
      return { ...state, openSidenav: !state.openSidenav };
    }
    case "SIDENAV_TYPE": {
      return { ...state, sidenavType: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "OPEN_MODAL" : {
      return { ...state, openModal: !state.openModal };
    }
    case "JOBS_MODAL" : {
        return { ...state, jobModal: !state.jobModal };
     }
     case "ACCESSORY_MODAL" : {
      return { ...state, accessoryModal: !state.accessoryModal };
     }
     case "DETAILS_MODAL" : {
       return { ...state, detailaccesoryModal: !state.detailaccesoryModal };
     }
     case "CUSTOMER_MODAL" : {
      return { ...state, customerdeviceModal: !state.customerdeviceModal };
     }
     case "DEVICE_MODAL" : {
       return { ...state, addDeviceModal: !state.addDeviceModal };
     }
     case "REJECT_MODAL" :{
      return { ...state, rejectModal: !state.rejectModal };
     }
     case "IMPORT_MODAL" : {
      return { ...state, importModal: !state.importModal };
     }
     case "IMPORT_PARTNER": {
      return { ...state, importPartner: !state.importPartner };
     }
     case "TRANSFER_MODAL" : {
      return { ...state, transferredKat: !state.transferredKat};
     }
     case "STOCK_REQUEST_MODAL" : {
      return { ...state, stockRequest: !state.stockRequest};
     }
     case "UPDATE_DEVICES" : {
        return { ...state, updateDevices: !state.updateDevices };
     }
     case "ADD_CATEGORY" : {
      return { ...state, addCategory: !state.addCategory };
     }
     case  "ASSIGN_DELIVERY" : { 
      return { ...state, assignDelivery: !state.assignDelivery };
     }
     case "SHOW_SERIALNUMBER" : {
       return { ...state, showSerialNumber: !state.showSerialNumber };
     }
     case "ADD_SHOP" : {
      return { ...state, addShop: !state.addShop};
     }
     case "ADD_DEVICEACCESSORY": {
      return { ...state, addDeviceAccessory: !state.addDeviceAccessory };
     }
     case "ADD_PRODUCT_SHOP": {
      return { ...state, addProductToShop: !state.addProductToShop };
     }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
export function MaterialTailwindControllerProvider({
  children,
}: MaterialTailwindControllerProviderProps) {
  const [controller, discharge] = useReducer(reducer, initialState);

  const value = useMemo(() => {
    return { controller, discharge };
  }, [controller, discharge]);


  return (
    <MaterialTailwind.Provider value={value}>
      {children}
    </MaterialTailwind.Provider>
  );
}

export function useMaterialTailwindController() {
  const context = useContext(MaterialTailwind);

  if (!context) {
    throw new Error(
      "useMaterialTailwindController should be used inside the MaterialTailwindControllerProvider."
    );
  }

  return context;
}


import React, { useEffect, useState } from 'react';
import DashboardLayout from '../component/layout/DashboardLayout';
import $service from '../services/app.service';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Input,
  Textarea,
  Typography,
} from '@material-tailwind/react';
import { Controller, useForm } from 'react-hook-form';
import { IStore } from '../state/app.reducer';
import { toast } from 'react-toastify';

const ProductDetails = () => {
  const { _id } = useParams<{ _id: string }>();
  const [product, setProduct] = useState<any>([]);
  const [imagePreview, setImagePreview] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    getProduct();
  }, [_id]);

  const updateProduct = async (data: any) => {
    try {
      const payload = {
        id: _id!,
        data: {
          name: data.name,
          price: data.price,
          color: data.color,
          catergory: data.category,
          specification: data.specification
          
        },
      };

      setLoading(true);
      const response = await $service.updateProduct(payload);
      if (!response.success) {
        throw new Error(response.message);
      }
      setLoading(false);
      toast.success(response.message);
      setDisabled(!disabled);
      getProduct();
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const getProduct = async () => {
    try {
      let response = await $service.getProductById(_id);
      if (!response.success) {
        throw new Error(response.message);
      }
  
      const p = response.data;
      setLoading(false);
      setProduct(p);
  
      // Fetch images asynchronously
      const imageResponse = await $service.getImage(p._id);
      
      if (imageResponse.success) {
        setImages(imageResponse.data);
      }
    } catch (err) {
      console.error("Error fetching product:", err);
    }
  };

  const { handleSubmit, register } = useForm<any>();

  return (
    <DashboardLayout>
      <div className='mt-12'>
        <Card className='p-5'>
          <CardBody>
            <div className='py-5 px-3'>
              <Typography variant='h4'>Product Details</Typography>
            </div>
            <hr />
            <form onSubmit={handleSubmit(updateProduct)}>
              <div className='grid grid-cols-3 gap-10 py-6'>
                <div className='flex flex-col'>
                  <div className='flex items-center justify-center w-full'>
                    <label
                      htmlFor='dropzone-file'
                      className='flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-8 '
                    >
                      <div className='flex flex-col items-center justify-center pt-5 pb-6'>
                        {imagePreview ? (
                          <>
                          {images.map((image: any) => (
                            <div key={image?._id}>
                            <img
                            src={image?.data}
                            alt='upload product'
                            className='w-32 h-32 mb-4'
                          />
                          </div>
                          ))

                          }
                          </>
                        
                        ) : (
                          <svg
                            className='w-8 h-8 mb-4 text-gray-500'
                            aria-hidden='true'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 20 16'
                          >
                            <path
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                            />
                          </svg>
                        )}
                        <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                          <span className='font-semibold'>Click to upload</span>{' '}
                          or drag and drop
                        </p>
                        <p className='text-xs text-gray-500 dark:text-gray-400'>
                          PNG or JPG
                        </p>
                      </div>
                      <input
                        id='dropzone-file'
                        type='file'
                        className='hidden'
                        // {...register('image')}
                        disabled
                      />
                    </label>
                  </div>
                </div>
                <div className='col-span-2'>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='mb-2'>
                      <Typography className='text-sm font-semibold'>
                        Product Name
                      </Typography>
                      <Input
                        {...register('name')}
                        disabled={!disabled}
                        defaultValue={product?.name}
                        className='text-black'
                      />
                    </div>
                    <div className='mb-2'>
                      <Typography className='text-sm font-semibold'>
                        Category
                      </Typography>
                      <Input
                        {...register('category')}
                        disabled={!disabled}
                        defaultValue={product?.category?.name}
                        className='text-black capitalize '
                      />
                    </div>
                    <div className='mb-2 col-span-2'>
                      <Typography className='text-sm font-semibold'>
                        Specifications
                      </Typography>
                      <Textarea
                        {...register('specification')}
                        disabled={!disabled}
                        defaultValue={product?.specification}
                        className='text-black capitalize'
                      />
                    </div>
                    <div className='mb-2'>
                      <Typography className='text-sm font-semibold'>
                        Price(in Ghs)
                      </Typography>
                      <Input
                        {...register('price')}
                        disabled={!disabled}
                        defaultValue={product?.price}
                        className='text-black capitalize'
                      />
                    </div>

                    <div className='mb-2'>
                      <Typography className='text-sm font-semibold'>
                        Color
                      </Typography>
                      <Input
                        {...register('color')}
                        disabled={!disabled}
                        defaultValue={product?.color}
                        className='text-black capitalize'
                      />
                    </div>
                    <div className='my-3 '>
                      <Button
                        className='w-full'
                        variant='filled'
                        color='blue'
                        type='button'
                        onClick={() => setDisabled(!disabled)}
                      >
                        Edit
                      </Button>
                    </div>
                    <div className='my-3'>
                      <Button
                        className='w-full'
                        variant='filled'
                        color='teal'
                        type='submit'
                        disabled={loading}
                      >
                        Publish Product
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default ProductDetails;

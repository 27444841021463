import React, { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { useMaterialTailwindController } from "../../../context";
import {
  Button,
  DialogFooter,
  DialogHeader,
  Textarea,
} from "@material-tailwind/react";
import $service from "../../../services/app.service";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function TransfertoKatModal() {
  const { discharge, controller } = useMaterialTailwindController();
  const { transferredKat } = controller;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("")
  const { _id } = useParams<string>();

  const handleClose = () =>
    discharge({ type: "TRANSFER_MODAL", transferredKat: false });

  const onTransfer = async () => {
    setLoading(true);
    try {
      const response = await $service.transferToKat({ jobcardId: _id!, reason});
      toast(response.message)
      handleClose()
    } catch (err) {
      toast('Error while transferring jobcard')
    }
  };

  return (
    <div>
      <Dialog
        open={transferredKat}
        onClose={handleClose}
        className=" relative w-screen"
        sx={{ width: "100%" }}
      >
        <DialogHeader className="text-lg">Transfer Job Card</DialogHeader>
        <DialogContent sx={{ width: "500px" }}>
          <form>
            <Textarea onChange={(e)=>{
              setReason(e.target.value)
            }} />
          </form>
        </DialogContent>
        <DialogFooter>
          <Button variant="filled" color="blue-gray" disabled={reason.length === 0} onClick={onTransfer}>
            Transfer
          </Button>

          <Button color="red" onClick={handleClose} className="mx-3">
            Cancel
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default TransfertoKatModal;

import React, { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { useMaterialTailwindController } from "../../../context";
import { Button, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { IDevices } from "../../../models/app.model";
import $service from "../../../services/app.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getDevices } from "../../../state/app.state";
import { FileInput } from "flowbite-react";

function AddImportDevice() {
  const { discharge, controller } = useMaterialTailwindController();
  const [loading, setLoading] = useState<boolean>(false);
  const { importModal } = controller;
  let payload: Array<Partial<IDevices | any>> = [];
  const dispatch = useDispatch()

  const submit = async (e:any) => {
    e.preventDefault();
    try {
      if (payload.length <= 0) {
        throw Error("Please import csv file");
      }
      setLoading(true);
      const response = await $service.uploadBulk(payload);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast(response.message);
      dispatch(getDevices())
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const upload = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.value = "";
    input.accept = ".csv";
    await new Promise((resolve) => {
    input.onchange = (e) => {
      const file = (e.target as any)?.files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        try {
          const _p = ["category._id", "model", "serialNumber", "distributionDate",];
          const res = fileReader.result as string;
          const data: Array<Array<any>> = res
            .split("\n")
            .map((d) => (d.includes(";") ? d.split(";") : d.split(",")))
            .filter((_, i) => i > 0)
            .filter((d) => d.length > 1);
          data.forEach((d) => {
            let device: any = {};
            d.forEach((_d, i) => {
              _d = (_d as string)?.replace(/['"]+/g, "");
              device[_p[i]] = _d;
            });
            payload.push(device);
          });

      
          payload = payload.map((d: Partial<IDevices | any>) => ({
            ...d,
       
          }));
        } catch (err) {
          resolve(err);
        }
      };

      fileReader.readAsText(file);
    };
    input.click();
  });

  };



  const handleOpen = () =>
    discharge({ type: "IMPORT_MODAL", importModal: false });
  return (
    <Dialog open={importModal} onClose={handleOpen} sx={{ width: "100%" }}>
      <DialogHeader className="text-lg">Import Device in CSV format</DialogHeader>
      <DialogContent sx={{ width: "500px" }}>
        <div
          className="flex items-center justify-center w-full"
          // onClick={upload}
        >   
         <div
      className="max-w-md"
      id="fileUpload"
     
    >
      <div className="mb-2 block">
      <label>Upload Device via CSV</label>
      </div>
      <div onClick={upload} className="cursor-pointer">
      <input
        className="block w-full text-lg  text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 "
        id="large_size"
        type='file'
        accept='.csv'
      />
      </div>
    </div> 
{/*        
          <label
            id="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
               
              </p>
            </div>
          </label> */}
      
    </div>
      </DialogContent>
      <DialogFooter>
        <Button
          variant="filled"
          color="blue-gray"
          className="mx-1"
          onClick={submit}
          disabled={loading}
        >
          Submit
        </Button>

        <Button
          variant="filled"
          color="red"
          onClick={handleOpen}
          disabled={loading}
          className="mx-1"
        >
          Cancel
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default AddImportDevice;

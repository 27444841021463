import { useParams, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Typography,
  Chip,
  Progress,
} from "@material-tailwind/react";
import DetailPageSkeleton from "../component/layout/DetailPageSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";
import { useEffect, useState } from "react";
import {
  EStatusTypes,
  IApprovals,
  IJobcards,
  IWarranty,
} from "../models/app.model";
import $service from "../services/app.service";
import AddDiagnosis from "../component/element/JobCardApprove/AddDiagnosis";
import JobCardApprovals from "../component/element/jobcards/JobCardApprovals";
import { getApprovalStatus } from "../state/app.state";
import { toast } from "react-toastify";
import WithFunctionGuard from "../guards/FunctionGuard";
import TransfertoKatModal from "../component/element/jobcards/TransfertoKatModal";
import { useMaterialTailwindController } from "../context";
import DashboardLayout from "../component/layout/DashboardLayout";

const JobCardDetailsPage = () => {
  const _dispatch = useDispatch();
  const { _id } = useParams<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [job, setDetails] = useState<Partial<IJobcards>>({});
  const [diagnos, setDiagnostic] = useState<Partial<any>>({});
  const [warranty, setWarranty] = useState<Partial<IWarranty>>({});
  const [approvals, setApproval] = useState<IApprovals[]>([]);
  const { status } = useSelector((reducer: IStore) => reducer.state);
  const { roles, devices } = useSelector((reducer: any) => reducer.state);
  const { discharge } = useMaterialTailwindController();

  const foundCategory = devices?.find((c: any) => c?._id === job?.deviceId?._id);



  useEffect(() => {
    loadDetails();
    _dispatch(getApprovalStatus());
    loadApprovals();
    diagonsisDetails();
  }, [_id]);

  const transfer = async (id: string) => {
    try {
      if (!id) throw Error("Id invalid");
      const p = {
        jobcardId: _id,
        statusId: id,
      };
      setLoading(true);
      const response = await $service.transfer(p);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast(
        `The job card approval has been updated successfully ${job?.status}`
      );
    } catch (err) {
      setLoading(false);
      toast("An unexpected error occurred while processing your request");
    }
  };

  const loadDetails = async () => {
    try {
      setLoading(true);
      const response = await $service.getJobcardById!(_id!);
      if (!response.success) {
        throw Error(response.message);
      }
      setDetails(response.data);
      // console.log(response.data);
      const device = response.data;
// console.log(device);
// const foundCategory = devices?.find((c: any) => c?._id === device?._deviceId?._id);
// console.log(foundCategory);

      const warranty = await $service.getWarranty!(foundCategory?.serialNumber);
      setLoading(false);
      setWarranty(warranty.data);
    } catch (err) {
      setLoading(false);
    }
  };



  const complete = async () => {
    try {
      setLoading(true);
      const response = await $service.completeJobcard!(_id!);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast("Jobcard completed");
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const loadApprovals = async () => {
    try {
      if (!_id) {
        throw Error("Invalid ID");
      }
      setLoading(true);
      const response = await $service.getDiagnosis(_id);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      setApproval(response.data);
      _dispatch(getApprovalStatus());
    } catch (err) {
      setLoading(false);
    }
  };

  const diagonsisDetails = async () => {
    try {
      setLoading(true);
      const response = await $service.getDiagnosis(_id!);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      setDiagnostic(response.data);
    } catch (err) {
      setLoading(false);
    }
  };

  const transferToKat = async () => {
    try {
      discharge({ type: "TRANSFER_MODAL", transferredtoKat: false });
    } catch (err) {
      setLoading(false);
    }
  };


  const RunDiagnosisButton = WithFunctionGuard(() => (
    <Link
      to={
        roles.menu === "qcRoutes"
          ? `/quality-check/${_id}`
          : `/technician-diagnosis/${_id}`
      }
      key={"run-diagonsis"}
    >
      <Button color="green" disabled={job?.status === "COMPLETED"}>
        Run Diagnosis
      </Button>
    </Link>
  ));

  const MarkCompleteButton = WithFunctionGuard(() => (
    <Button
      color="blue"
      disabled={job?.status === "COMPLETED"}
      onClick={complete}
      key={"mark-complete"}
    >
      {" "}
      Marked as Complete{" "}
    </Button>
  ));



  const TransferCompleteButton = WithFunctionGuard(() => (
    <Button
      color="blue"
      disabled={job?.status === "COMPLETED" || job?.requestType === "TRANSFER"}
      onClick={transferToKat}
      key={"transfer-kat"}
    >
      Transfer to KAT
    </Button>
  ));

  const ButtonMark = WithFunctionGuard(() => (
    <Chip
      key={"selection-button"}
      value={job?.status || "Default Status"}
      color="gray"
    />
  ));

  const JobCardPartner = WithFunctionGuard(() => (
    <div key="partner-jobcard">
      {job?.createdBy?.role === "KATCARE-PARTNER" ? (
        <Card className="w-full">
          <CardBody>
            <Typography
              variant="h5"
              className="text-lg font-bold leading-none mb-5 text-gray-900 dark:text-white"
            >
              Partner Details
            </Typography>
            <div className="">
              <ul
                className="list-none grid grid-cols-2"
                key={job?.createdBy?._id}
              >
                <li className="py-3 sm:py-4">
                  <strong className="text-slate-700 text-md">Partners</strong>
                  <p>
                    {job?.createdBy?.first_name} {job?.createdBy?.last_name}
                  </p>
                </li>
                <li className="py-3 text-md sm:py-4">
                  <strong className="text-slate-700 ">Company</strong>
                  <p>{job?.createdBy?.company_name} </p>
                </li>
                <li className="py-3 sm:py-4">
                  <strong className="text-slate-700 text-md">Email</strong>
                  <p> {job?.createdBy?.email} </p>
                </li>
                <li className="py-3 sm:py-4">
                  <strong className="text-slate-700 ">Phone Number</strong>
                  <p>{job?.createdBy?.mobile} </p>
                </li>
                <li className="py-3 text-md sm:py-4">
                  <strong className="text-slate-700 ">Region</strong>
                  <p>{job?.createdBy?.region} </p>
                </li>

                <li className="py-3 text-md sm:py-4">
                  <strong className="text-slate-700 ">District</strong>
                  <p>{job?.createdBy?.district} </p>
                </li>
                <li className="py-3 text-md sm:py-4">
                  <strong className="text-slate-700 ">Digital Address</strong>
                  <p>{job?.createdBy?.digital_address} </p>
                </li>
                <li className="py-3 text-md sm:py-4">
                  <strong className="text-slate-700 ">Date Created</strong>
                  <p>{new Date(job?.createdBy?.createdAt).toDateString()} </p>
                </li>
              </ul>
            </div>
          </CardBody>
        </Card>
      ) : null}
    </div>
  ));

  return (
 <DashboardLayout>
        <div className="my-12">
          {isLoading ? (
            <DetailPageSkeleton />
          ) : (
            <>
              <AddDiagnosis />
              <TransfertoKatModal />
              <Card
                className="w-full h-56 relative mb-24"
                color="gray"
                variant="gradient"
                key={job?._id}
              >
                <CardHeader className="h-40 relative left-0 top-40">
                  <div className="flex flex-row justify-between p-3 items-center">
                    <div className="flex flex-row gap-5 items-center py-3">
                      <div className="mt-3">
                        <Typography
                          variant="h5"
                          color="blue-gray"
                          className="mb-2 "
                        >
                          {job?.jobcardNumber}
                        </Typography>

                        <Typography variant="h6" color="gray" className="mb-2 ">
                          {job?.deviceId?.deviceId?.model}
                        </Typography>

                        <Typography variant="h6" color="gray" className="mb-2 ">
                          {new Date(job?.createdAt).toDateString()}
                        </Typography>
                        <div className="w-96"></div>
                      </div>
                    </div>

                    <div className="flex justify-between gap-5">
                      <MarkCompleteButton />
                      <TransferCompleteButton />
                      <ButtonMark />
                      <div>
                        {/* <label id="countries" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Transfer</label> */}
                        <select
                          id="transfers"
                          className="bg-gray-100 border-sm shadow-sm py-2.5 border-gray-500 text-gray-900 text-sm rounded-lg  block w-full p-2"
                          onChange={(e: any) => transfer(e.target.value)}
                          defaultValue={
                            status.find((s) => s.name === job.status)?._id
                          }
                        >
                          <option value=""></option>
                          {status
                            .filter((s) => s.type === EStatusTypes.JOBCARD)
                            .filter((s) => roles.status.includes(s.name))
                            .map((s) => (
                              <option value={s._id}>{s.name}</option>
                            ))}
                        </select>
                      </div>
                      {/* <Button
                        color="green"
                        onClick={approve}
                      >
                        {" "}
                        Approve{" "}
                      </Button> */}
                      {/* 
                      <Link to={`/quality-check/${_id}`}>
                        <Button color="green"> Run Diagnosis </Button>
                      </Link> */}
                      <RunDiagnosisButton />
                      {/* <Button
                        color="red"
                        onClick={reject}
                      >
                        {" "}
                        Reject{" "}
                      </Button> */}
                    </div>
                  </div>
                </CardHeader>
              </Card>

              <div className="grid grid-cols-1 my-8 gap-8">
                <Card className="w-full">
                  <CardBody>
                    <Typography
                      variant="h5"
                      className="text-lg font-bold leading-none mb-5 text-gray-900 dark:text-white"
                    >
                      Job Card Details
                    </Typography>
                    <div className="">
                      <ul className="list-none grid grid-cols-2">
                        <li className="py-3 sm:py-4">
                          <strong className="text-slate-700 text-md">
                            Job Card ID
                          </strong>
                          <p> {job?._id} </p>
                        </li>
                        <li className="py-3 sm:py-4">
                          <strong className="text-slate-700 text-md">
                            Customer
                          </strong>
                          <p>
                            {job?.customerId?.firstName}{" "}
                            {job?.customerId?.lastName}
                          </p>
                        </li>
                        <li className="py-3 sm:py-4">
                          <strong className="text-slate-700 ">Status</strong>
                          <p>{job?.status} </p>
                        </li>
                        <li className="py-3 text-md sm:py-4">
                          <strong className="text-slate-700 ">Progress</strong>
                          <div className="w-96">
                            <Progress
                              value={job?.progress}
                              color={job?.progress! <= 50 ? "red" : "green"}
                              label={true}
                            />
                          </div>
                        </li>
                        <li className="py-3 text-md sm:py-4">
                          <strong className="text-slate-700 ">
                            Description
                          </strong>
                          <p>{job?.description} </p>
                        </li>

                        <li className="py-3 text-md sm:py-4">
                          <strong className="text-slate-700 ">
                            Device Name
                          </strong>
                          <p>{job?.deviceId?.category?.name} </p>
                        </li>
                        <li className="py-3 text-md sm:py-4">
                          <strong className="text-slate-700 ">
                            Serial Number
                          </strong>
                          <p>{job?.deviceId?.serialNumber} </p>
                        </li>
                        <li className="py-3 text-md sm:py-4">
                          <strong className="text-slate-700 ">
                            Device Warranty
                          </strong>
                          <div>
                            <Chip
                              className="rounded-full py-1.5"
                              value={
                                warranty
                                  ? "Under Warranty"
                                  : "No"
                              }
                              color={
                                warranty && warranty._id ? "teal" : "orange"
                              }
                            />
                          </div>
                        </li>
                        <li className="py-3 text-md sm:py-4">
                          <strong className="text-slate-700 ">
                            Request Type
                          </strong>
                          <p className="text-teal-700">{job?.requestType} </p>
                        </li>
                        <li className="py-3 text-md sm:py-4">
                          <strong className="text-slate-700 ">
                            Delivery Type
                          </strong>
                          <p>{job?.deliveryType}</p>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
                <JobCardPartner />

                {approvals.map((a) => (
                  <JobCardApprovals item={a} />
                ))}

                {/* technician */}
                {/* <Card>
               
                      <CardBody>
                        <Typography
                          variant="h5"
                          className="text-lg font-bold leading-none mb-5 text-gray-900 dark:text-white"
                        >
                          Technician Diagnosis
                        </Typography>
                        <ul
                          className="list-none grid grid-cols-2"
                        >
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              Has the device been tampered with
                            </strong>
                            <p>{diagnos?.HasDeviceBeenTamperedWith}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                             Missing Parts
                            </strong>
                           <p>{diagnos.missingparts}</p> 
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                            Remarks
                            </strong>
                           {diagnos.remarks}
                          </li>
                         
                        </ul>
                      </CardBody>
                   
                </Card> */}

                {/* QC diagonis */}
                {/* <Card>
                      <CardBody>
                        <Typography
                          variant="h5"
                          className="text-lg font-bold leading-none mb-5 text-gray-900 dark:text-white"
                        >
                          Quality Control Manager Diagnosis
                        </Typography>
                        <ul className="list-none grid grid-cols-2" key={diagnos.id}>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              Power On Check Whether LCD Display Is Normal
                            </strong>
                            <p>{diagnos?.powerOnCheckWhetherLCDDisplayIsNormal}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              the Logo When Power On And Animation Must Display
                            </strong>
                            <p>
                              {diagnos?.theLogoWhenPowerOnAndAnimationMustDisplay}
                            </p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              the LCD Display Contrast Quality Is Bad
                            </strong>
                            <p>{diagnos?.theLCDDisplayContrastQualityIsBad}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              lcd Display Check Symbols OnDesk
                            </strong>
                            <p>{diagnos?.lcdDisplayCheckSymbolsOnDesk}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              check Flip By Fliping Three Times
                            </strong>
                            <p>{diagnos?.checkFlipByFlipingThreeTimes}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              key board Function Test
                            </strong>
                            <p>{diagnos?.keyboardFunctionTest}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              test To Check If TouchPad Is Working Or Has Rough
                              Surface
                            </strong>
                            <p>
                              { diagnos?.testToCheckIfTouchPadIsWorkingOrHasRoughSurface }
                            </p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              check Mouse Right Or Left Click
                            </strong>
                            <p>{diagnos?.checkMouseRightOrLeftClick}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              check Speaker If Sound Is Clear For Hearing
                            </strong>
                            <p>{diagnos?.checkSpeakerIfSoundIsClearForHearing}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              check Microphone By Recording A Voice Message To
                              Ensure Clarity
                            </strong>
                            <p>
                              {
                                diagnos?.checkMicrophoneByRecordingAVoiceMessageToEnsureClarity
                              }
                            </p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              check Lcd Colours
                            </strong>
                            <p>{diagnos?.checkLcdColours}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              camera Test No Abnormal Display After Enter
                              Preview Screen
                            </strong>
                            <p>
                              {
                                diagnos?.cameraTestNoAbnormalDisplayAfterEnterPreviewScreen
                              }
                            </p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              check Software On Computer
                            </strong>
                            <p>{diagnos?.checkSoftwareOnComputer}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              check Wifi Symbol
                            </strong>
                            <p>{diagnos?.checkWifiSymbol}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              test For Bluetooth
                            </strong>
                            <p>{diagnos?.testForBluetooth}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              standard Detail Checking Of Systems Setting
                            </strong>
                            <p>{diagnos?.standardDetailCheckingOfSystemsSetting}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              physical Check To Test If Fan Is Working
                            </strong>
                            <p>{diagnos?.physicalCheckToTestIfFanIsWorking}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              check Usb Slots If Its Functioning
                            </strong>
                            <p>{diagnos?.checkUsbSlotsIfItsFunctioning}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              check Other Buttons
                            </strong>
                            <p>{diagnos?.checkOtherButtons}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              simSlotCannotRegisterSIMCard
                            </strong>
                            <p>{diagnos?.simSlotCannotRegisterSIMCard}</p>
                          </li>
                          <li className="py-3 sm:py-4">
                            <strong className="text-slate-700 text-sm capitalize">
                              shutdownLaptopOrDesktopAfterAboveCheckingDone
                            </strong>
                            <p>
                              {
                                diagnos?.shutdownLaptopOrDesktopAfterAboveCheckingDone
                              }
                            </p>
                          </li>
                        </ul>
                      </CardBody>
                   
                </Card> */}

                {/* Reason for rejection */}
                {/* <div>
                  
                        <Card>
                          <CardBody>
                            <div className="">
                              <div className="flex items-center justify-between mb-4">
                                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                                  QC Review 
                                </ h5>
                              </div>
                              <div className="flow-root">
                                <ul className="divide-y divide-gray-200">
                                  <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                      <div className="flex-1 min-w-0">
                                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                          Job Card Id
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                          {diagnos.remarks}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      
                    
                </div> */}
              </div>
            </>
          )}
        </div>
  </DashboardLayout>
        
  );
};

export default JobCardDetailsPage;

import {useEffect, useState} from 'react'
import DashboardLayout from '../component/layout/DashboardLayout'
import { useParams } from 'react-router-dom';
import $service from '../services/app.service';
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import { useSelector } from 'react-redux';

const ShopDetails = () => {
    const { _id } = useParams<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<any>([]);
    const { shops} = useSelector((reducer: any) => reducer.state);
    
    const thisProduct = shops.find((prod: any) => prod._id === _id)

    useEffect(() => {
        getProducts()
    }, [])

    const getProducts = async(): Promise<void> => {
        try{
            if(!_id || _id === '') return 
            setLoading(true);
            let response = await $service.getShopProducts!(_id!);
            if(!response.success) {
                throw new Error(response.message)
            }
            const item = response.data;
            setLoading(false);
            setProducts(item);
        } catch(err) {
            setLoading(false);
        }
    }
  return (
    <DashboardLayout>
        <div className='mt-12'>
     {/* <Card  className="w-full h-56 relative mb-24"
                color="gray"
                variant="gradient"
                key={products?._id}
              >
                <CardHeader className="h-40 relative left-0 top-40">
                  <div className="flex flex-row justify-between p-3 items-center">
                    <div className="flex flex-row gap-5 items-center py-3">
                      <div className="mt-3">
                        <Typography
                          variant="h5"
                          color="blue-gray"
                          className="mb-2 "
                        >
                          {products?.name}
                        </Typography>

                        <Typography variant="h6" color="gray" className="mb-2 ">
                          {products?.active}
                        </Typography>
                        <div className="w-96"></div>
                      </div>
                    </div>
                    </div>
                    </CardHeader>
                    </Card> */}

                    <Card shadow={false}>
                  <CardBody>
                    <div className='py-2 pb-3'>
                      <h5 className='font-bold text-lg  text-gray-900'>
                        {thisProduct?.name} Shop Products 
                      </h5>
                    </div>
                    <div className='relative overflow-x-auto shadow-sm sm:rounded-lg'>
                      <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
                        <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                          <tr>
                            <th scope='col' className='px-6 py-3'>
                              Device Type
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Product Name
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Price
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Quantity
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Color
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Created At
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(products) &&
                            products?.map((jc: any) => (
                              <tr className='bg-white border-b' key={jc?._id}>
                                <th
                                  scope='row'
                                  className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap'
                                >
                                  {jc?.category?.name}
                                </th>
                               
                                <td className='px-6 py-4 text-teal-600'>
                                  {jc?.name}
                                </td>
                                <td className='px-6 py-4 text-gray-900'>
                                  {jc?.price}
                                </td>
                                <td className='px-6 py-4 text-gray-900'>
                                  {jc?.quantity}
                                </td>
                                <td className='px-6 py-4 text-gray-900'>
                                  {jc?.color}
                                </td>
                               
                                <td className='px-6 py-4'>
                                  {new Date(jc?.createdAt).toDateString()}
                                </td>
                                {/* <td px-6 py-4 text-right>
                                   <Link to={`/jobcard-details/${jc?._id}`}
                                   className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                   >
                                    View
                                   </Link>
                                  </td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>

        </div>
    </DashboardLayout>
  )
}

export default ShopDetails
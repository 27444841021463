import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";

const withFunctionGuard = (Component: any) => (props: any) => {
  const key = Component().key;
  const { roles } = useSelector((reducer: IStore) => reducer.state);
  return roles.functions.includes(key) ? <Component {...props} /> : null;
};

export default withFunctionGuard;

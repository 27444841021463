import { createElement, useEffect } from "react";
import { HiShoppingCart} from "react-icons/hi";
import { MdOutlinePending, MdCancel} from "react-icons/md";
import { Card, CardHeader, CardBody,Typography,} from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { IStore } from "../../state/app.reducer";

const SalesCard = () => {
  
let totalAmount = 0

  const {
    loading,
    salesMetrics,
  } = useSelector((reducer: IStore) => reducer.state);

  const total = salesMetrics[0]?.total;
  const ordersStatus = salesMetrics[0]?.status;
  const orders: any = {
    PENDING: {
      _id: "PENDING",
      count: 0,
      amount: 0,
    },
    CANCELLED: {
      _id: "CANCELLED",
      count: 0,
      amount: 0,
    },
  }

  ordersStatus?.forEach((order:any)=> {
    orders[order._id] = order
    totalAmount+=order.amount
    console.log(totalAmount)
  })

  // const unwind = (data: any, value?: string) => {
  //   let count: number = 0;
  //   if (Array.isArray(data?.status)) {
  //     data.forEach((d: { _id: string; count: number, amount: number}) => {
  //       if (value && typeof value === 'string') {
  //         if (d._id === value) {
  //           count = d.count;
  //         }
  //       } else {
  //         count = d.count;
  //       }
  //     });
  //   }
  //   return count;
  // }

  return (
    <div className="mb-12 grid gap-10 gap-x-6 md:grid-cols-2 lg:grid-cols-3">
      {total.length > 0 ? (
        <Card className="w-full">
          <CardHeader
            variant="gradient"
            className={`absolute -mt-4 grid h-10 w-10 place-items-center  bg-[#1e88e5]`}
          >
            {createElement(HiShoppingCart, { style: { fill: "#FFFFFF" } })}
          </CardHeader>
          <CardBody className="p-4 text-right">
          <Typography
            variant="small"
            className="mb-1 mt-3 font-semibold text-blue-gray-600"
          >
              Total Sales
            </Typography>
            <Typography variant="h4" color="blue-gray">
            GH₵{" "}
              {totalAmount}
              .00
            </Typography>
              <Typography variant="h5" color="teal" className="mt-3">
              {salesMetrics[0]?.total[0]?.count}
            <span className="font-medium mt-5  text-blue-gray-600 text-sm"> Sales</span>
          </Typography>
          </CardBody>
        </Card>
      ): (
        <Card className="w-full">
          <CardHeader
            variant="gradient"
            className={`absolute -mt-4 grid h-10 w-10 place-items-center  bg-[#1e88e5]`}
          >
            {createElement(HiShoppingCart, { style: { fill: "#FFFFFF" } })}
          </CardHeader>
          <CardBody className="p-4 text-right">
          <Typography
            variant="small"
            className="mb-1 mt-3 font-semibold text-blue-gray-600"
          >
              Total Sales
            </Typography>
            <Typography variant="h4" color="blue-gray">
            GH₵{" "}
              0
              .00
            </Typography>
              <Typography variant="h5" color="teal" className="mt-3">
              0
            <span className="font-medium mt-5  text-blue-gray-600 text-sm"> Sales</span>
          </Typography>
            </CardBody>
            </Card>
      )}

 
             <Card className="w-full">
             <CardHeader
               variant="gradient"
               className={`absolute -mt-4 grid h-10 w-10 place-items-center  bg-[#1e88e5]`}
             >
               {createElement(MdOutlinePending, {
                 style: { fill: "#FFFFFF" },
               })}
             </CardHeader>
             <CardBody className="p-4 text-right">
             <Typography
               variant="small"
               className="mb-1 mt-3 font-semibold text-blue-gray-600"
             >
                Total Amount
               </Typography>
               <Typography variant="h4" color="blue-gray" >
               GH₵ {orders["PENDING"]?.amount}.00
               </Typography>
   
                 <Typography variant="h5" color="teal" className="mt-3">
                 {orders["PENDING"]?.count}
               <span className="font-medium mt-5  text-blue-gray-600 text-sm"> {orders["PENDING"]?._id} Sales</span>
             </Typography>
               
             </CardBody>
           </Card> 

      <Card className="w-full">
        <CardHeader
          variant="gradient"
          className={`absolute -mt-4 grid h-10 w-10 place-items-center  bg-[#1e88e5]`}
        >
          {createElement(MdCancel, { style: { fill: "#FFFFFF" } })}
        </CardHeader>
        <CardBody className="p-4 text-right">
          <Typography
            variant="small"
            className="mb-1 mt-3 font-semibold text-blue-gray-600"
          >
            Total Amount
          </Typography>
          <Typography variant="h4" color="blue-gray" >
          GH₵ {orders["CANCELLED"]?.amount}.00
          </Typography>
          <Typography variant="h5" color="teal" className="mt-3">
            {orders["CANCELLED"]?.count}
            <span className="font-medium mt-5  text-blue-gray-600 text-sm"> {orders["CANCELLED"]?._id} Sales</span>
          </Typography>
        </CardBody>
      </Card>
    </div>
  );
};

export default SalesCard;

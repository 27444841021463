import React, { useEffect } from 'react';
import {
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react';
import Profile from '../component/element/setting/Profile';
import PasswordSetting from '../component/element/setting/PasswordSetting';
import { useDispatch, useSelector } from 'react-redux';
import { getprofile } from '../state/app.state';
import DashboardLayout from '../component/layout/DashboardLayout';
import UpdateProfile from '../component/element/setting/UpdateProfile';

const SettingPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getprofile());
  }, []);
  const { roles } = useSelector((reducer: any) => reducer.state);

  return (
    <DashboardLayout>
      <div className=''>
        <div className='flex flex-row  justify-center items-center '>
          <Tabs id='custom-animation' value='profile' className='w-full'>
            <TabsHeader className='w-full'>
              <Tab value='profile'>
                <Typography variant='lead' className='font-semibold text-md'>
                  Personal Information
                </Typography>
              </Tab>
              <Tab value='passwords'>
                <Typography variant='lead' className='font-semibold text-md'>
                  Passwords
                </Typography>
              </Tab>
              <Tab value='update'>
                <Typography variant='lead' className='font-semibold text-md'>
                  Update Profile Info
                </Typography>
              </Tab>
            </TabsHeader>
            <TabsBody
              animate={{
                // initial: { y: 250 },
                mount: { y: 0 },
                unmount: { y: 250 },
              }}
            >
              <TabPanel value='profile'>
                <Profile />
              </TabPanel>
              <TabPanel value='passwords'>
                <PasswordSetting />
              </TabPanel>
              <TabPanel value='update'>
                <UpdateProfile />
              </TabPanel>
            </TabsBody>
          </Tabs>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SettingPage;

import { useEffect, useState } from 'react';
import DashboardLayout from '../component/layout/DashboardLayout';
import DetailPageSkeleton from '../component/layout/DetailPageSkeleton';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Progress,
  Typography,
} from '@material-tailwind/react';
import $service from '../services/app.service';
import { getAdmins } from '../state/app.state';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import WithFunctionGuard from '../guards/FunctionGuard';
import { useSelector} from 'react-redux';
import { NoSymbolIcon } from '@heroicons/react/24/solid';
import Avatar from 'react-avatar';
import { IAdmin, IJobcards } from '../models/app.model';

const AdminDetailsPage = () => {
  const { _id } = useParams<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { admin } = useSelector((reducer: any) => reducer.state);
  const [admins, setAdmins] = useState<any>([]);
  const [jobcards, setJobcards] = useState<any>([]);
  const [accessory, setAccessory] = useState<any>([]);


  
  useEffect(() => {
    getUsers();
  }, []);

  // const getUsers = async (): Promise<void> => {
  //   try {
  //     if (!_id || _id === '') return;
  //     setLoading(true);
  //     let response = await $service.getAdminId!(_id);
  //     if (!response.success) {
  //       throw Error(response.message);
  //     }
  //     const b = response.data as IAdmin;
  //     setLoading(false);
  //     setAdmins(b);
  //     const [jobcardsResponse, accessoryResponse] = await Promise.all([
  //       $service.getAdminJobcardId!(b._id),
  //       $service.getAdminAccessoryId!(b._id),
  //     ]);
  //     switch (true) {
  //       //@ts-ignore
  //       case accessoryResponse.success:
  //         setAccessory(accessoryResponse?.data);
  //       //@ts-ignore
  //       case jobcardsResponse.success:
  //         setJobcards(jobcardsResponse?.data);
  //         break;
  //       default:
  //         break;
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //   }
  // };

  const getUsers = async (): Promise<void> => {
    try {
      if (!_id || _id === '') return;
      setLoading(true);
      let response = await $service.getAdminId!(_id);
      if (!response.success) {
        throw Error(response.message);
      }
      const b = response.data as IAdmin;
      setLoading(false);
      setAdmins(b);
      const [jobcardsResponse, accessoryResponse,] = await Promise.all([
        $service.getAdminJobcardId!(b._id),
        $service.getAdminAccessoryId!(b._id),
      ]);
  
      switch (true) {
              //@ts-ignore
              case accessoryResponse.success:
                setAccessory(accessoryResponse?.data);
              //@ts-ignore
              case jobcardsResponse.success:
                setJobcards(jobcardsResponse?.data);
                break;
              default:
                break;
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // Handle the error
    }
  };
  

  const suspend = async (blocked: boolean = true) => {
    try {
      const data = { id: _id! };
      setLoading(true);
      const response = await $service.adminSuspend(data);
      console.log(response);
      if (!response.success) {
        throw Error(response.message);
      }
      getAdmins();
      setLoading(false);
      toast.success(response.message);
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const activate = async (blocked: boolean = false) => {
    try {
      const data = { id: _id! };
      setLoading(true);
      const response = await $service.adminActivate(data);
      console.log(response);
      if (!response.success) {
        throw Error(response.message);
      }
      getAdmins();
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const SuspendCustomerButton = WithFunctionGuard(() => (
    <Button
      className={`${admins?._id ? 'bg-green-800' : 'bg-red-800'}`}
      onClick={() => (admins?._id ? suspend() : activate())}
      key={'suspend-admin'}
    >
      {admin?.id ? 'Activate Account' : 'Suspend Account'}
    </Button>
  ));

  return (
    <DashboardLayout>
      <div className='my-12'>
        {loading ? (
          <DetailPageSkeleton />
        ) : (
          <>
            {/* Personal Information */}
            <Card
              className='w-full h-56 relative mb-24'
              color='blue'
              variant='gradient'
              key=''
            >
              <CardHeader className='h-32 relative left-0 top-40'>
                <div className='flex flex-row justify-between p-3 items-center'>
                  <div className='flex flex-row gap-5 items-center'>
                    <div className=''>
                      <Avatar
                        name={`${admins?.first_name} ${admins?.last_name}`}
                        size='100'
                        round={true}
                      />
                    </div>
                    <div className='mt-3'>
                      <Typography
                        variant='h5'
                        color='blue-gray'
                        className='mb-2 '
                      >
                        {admins?.first_name} {admins?.last_name}
                      </Typography>
                      <Typography
                        variant='paragraph'
                        color='blue'
                        className='font-sm'
                        textGradient
                      >
                        {admins?.role ===  "KATCARE-PARTNER" ? "KATCARE-PARTNER" : "KAT"}
                      </Typography>
                    </div>
                  </div>
                  <div className='flex justify-between gap-3'>
                    <SuspendCustomerButton />
                  </div>
                </div>
              </CardHeader>
            </Card>

            <div className='grid grid-cols-1 my-8 gap-8'>
              {/* Personal Information */}
              <Card className='w-full' shadow={false}>
                <CardBody>
                  <div>
                    <div className='flex justify-between'>
                      <Typography
                        variant="h4"
                        className='text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white'
                      >
                        Personal Information
                      </Typography>
                    </div>
                    <ul className='list-none'>
                      <li className='py-3 sm:py-4'>
                        <div className='grid grid-cols-2'>
                          <h2 className='text-gray-500 truncate '>
                            First Name:
                          </h2>
                          <p className='inline-flex items-center text-base font-semibold text-gray-900'>
                            {admins?.first_name}
                          </p>
                        </div>
                      </li>

                      <li className='py-3 sm:py-4 grid grid-cols-2'>
                        <h2 className='text-gray-500 truncate '>Last Name:</h2>
                        <p className='inline-flex items-center text-base font-semibold text-gray-900'>
                          {admins?.last_name}
                        </p>
                      </li>
                      <li className='py-3 sm:py-4 grid grid-cols-2'>
                        <h2 className='text-gray-500 truncate '>Email:</h2>
                        <p className='inline-flex items-center text-base font-semibold text-gray-900'>
                          {admins?.email}
                        </p>
                      </li>
                      <li className='py-3 sm:py-4 grid grid-cols-2'>
                        <h2 className='text-gray-500 truncate '>
                          Phone Number:
                        </h2>
                        <p className='inline-flex items-center text-base font-semibold text-gray-900'>
                          {admins?.mobile}
                        </p>
                      </li>
                      <li className='py-3 sm:py-4  grid grid-cols-2'>
                        <h2 className='text-gray-500 truncate '>Region:</h2>
                        <p className='inline-flex items-center text-base font-semibold text-gray-900'>
                          {admins?.region}
                        </p>
                      </li>
                      <li className='py-3 sm:py-4  grid grid-cols-2'>
                        <h2 className='text-gray-500 truncate '>District:</h2>
                        <p className='inline-flex items-center text-base font-semibold text-gray-900'>
                          {admins?.district}
                        </p>
                      </li>
                      <li className='py-3 sm:py-4  grid grid-cols-2'>
                        <h2 className='text-gray-500 truncate '>
                          Company Number:
                        </h2>
                        <p className='inline-flex items-center text-base font-semibold text-gray-900'>
                          {admins?.company_name}
                        </p>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
              {/* Customer Device */}
            </div>
            {/* Customer Job Cards */}
            <>
              {jobcards?.length === 0 ? (
                <div className='flex flex-col items-center py-4 justify-center'>
                  <NoSymbolIcon className='w-8 h-8 text-gray-600' />
                  <p className='text-lg mt-4 text-gray-600'>
                    No customer job cards available
                  </p>
                </div>
              ) : (
                <Card shadow={false}>
                  <CardBody>
                    <div className='py-2 pb-3'>
                      <h5 className='font-bold text-lg  text-gray-900'>
                        Officer Job Cards
                      </h5>
                    </div>
                    <div className='relative overflow-x-auto shadow-sm sm:rounded-lg'>
                      <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
                        <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                          <tr>
                            <th scope='col' className='px-6 py-3'>
                              Job Card Name
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Device Progress
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Status
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Delivery Mode
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Request Type
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Created At
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(jobcards) &&
                            jobcards?.map((jc: any) => (
                              <tr className='bg-white border-b' key={jc?._id}>
                                <th
                                  scope='row'
                                  className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap'
                                >
                                  #{jc?.jobcardNumber}
                                </th>
                                <td className='px-6 py-4'>
                                  <Progress
                                    value={jc?.progress}
                                    color={jc?.progress > 50 ? 'green' : 'red'}
                                  />
                                </td>
                                <td className='px-6 py-4 text-teal-600'>
                                  {jc?.status}
                                </td>
                                <td className='px-6 py-4 text-gray-900'>
                                  {jc?.deliveryType}
                                </td>
                                <td className='px-6 py-4 text-gray-900'>
                                  {jc?.requestType}
                                </td>
                                <td className='px-6 py-4'>
                                  {new Date(jc?.createdAt).toDateString()}
                                </td>
                                {/* <td px-6 py-4 text-right>
                                   <Link to={`/jobcard-details/${jc?._id}`}
                                   className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                   >
                                    View
                                   </Link>
                                  </td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              )}
            </>

            {/* Warranty Card */}
            <>
              {accessory?.length === 0 ? (
                <div className='flex flex-col items-center py-4 justify-center'>
                  <NoSymbolIcon className='w-8 h-8 text-gray-600' />
                  <div className='text-lg mt-4 text-gray-600'>
                    No accessory request available
                  </div>
                </div>
              ) : (
                <Card className='my-5' shadow={false}>
                  <CardBody>
                    <div className='py-2 pb-3'>
                      <h5 className='font-bold text-lg  text-gray-900'>
                        Accessory Request
                      </h5>
                    </div>
                    <div className='relative overflow-x-auto shadow-sm  sm:rounded-lg'>
                      <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
                        <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                          <tr>
                            <th scope='col' className='px-6 py-3'>
                              Job Card Number
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Officer Name
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Officer Role
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Reason
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Status
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Created At
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(accessory) && accessory?.map?.((ac: any) => (
                                <tr className='bg-white border-b' key={ac?._id}>
                                  <th
                                    scope='row'
                                    className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap'
                                  >
                                    #{ac?.jobcardNumber}
                                  </th>
                                  <td className='px-6 py-4 text-teal-600'>
                                    {ac?.adminId?.first_name} {''}{' '}
                                    {ac?.adminId?.last_name}
                                  </td>
                                  <td className='px-6 py-4 text-teal-600'>
                                    {ac?.adminId?.role}
                                  </td>
                                  <td className='px-6 py-4 text-gray-900'>
                                    {ac?.reason}
                                  </td>
                                  <td className='px-6 py-4 text-gray-900'>
                                    {ac?.status}
                                  </td>
                                  <td className='px-6 py-4'>
                                    {new Date(ac?.createdAt).toDateString()}
                                  </td>
                                </tr>
                              ))
                            }
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              )}
            </>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default AdminDetailsPage;

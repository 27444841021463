import { Fragment, useEffect, useState } from "react";
import {
  Button,
  DialogHeader,
  DialogFooter,
  Input,
  Typography,
} from "@material-tailwind/react";
import { Dialog, DialogContent } from "@mui/material";
import { useMaterialTailwindController } from "../../../context";
import { useForm } from "react-hook-form";
import { CustomerProps } from "../../../data/types";
import { toast } from "react-toastify";
import { Spinner } from "flowbite-react";
import { ICustomer } from "../../../models/app.model";
import $service from "../../../services/app.service";
import { getCustomers } from "../../../state/app.state";
import { useDispatch } from "react-redux";
import regions from "../../../services/region.service";

const AddCustomerModal = () => {
  const { controller, discharge } = useMaterialTailwindController();
  const { openModal } = controller;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomers());
  }, []);

  const onSubmit = async (data: CustomerProps) => {
    try {
      if (
        !data.firstname ||
        !data.lastname ||
        !data.email ||
        !data.phonenumber
      ) {
        throw Error("Please fill the form completely");
      }
      const payload: Partial<ICustomer> = {
        firstName: data.firstname,
        lastName: data.lastname,
        email: data.email,
        phone: data.phonenumber,
        address: data.address,
        middleName: data.middlename,
      };
      setLoading(true);
      const response = await $service.addCustomer!(payload);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      discharge({ type: "OPEN_MODAL", openModal: false });
      toast.success(response.message);
      dispatch(getCustomers());
    } catch (err) {
      setLoading(false);
      toast.error((err as Error).message);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomerProps>();
  const handleOpen = () => discharge({ type: "OPEN_MODAL", openModal: false });

  return (
    <Fragment>
      <Dialog
        open={openModal}
        onClose={handleOpen}
        className="h-screen relative "
      >
        <DialogHeader className="text-lg ">
          Register Katcare Customers
        </DialogHeader>
        <DialogContent sx={{ width: "500px" }}>
          <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  First Name
                </Typography>
                <Input {...register("firstname", { required: true })} />
              </div>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  Middle Name
                </Typography>
                <Input {...register("middlename")} />
              </div>

              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  Last Name
                </Typography>
                <Input {...register("lastname", { required: true })} />
              </div>

              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  Email Address
                </Typography>
                <Input
                  type="email"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
              </div>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  Phone Number
                </Typography>
                <Input
                  {...register("phonenumber", {
                    required: true,
                    maxLength: 10,
                  })}
                />
                {errors.phonenumber && (
                  <span className="text-red-500">
                    This field is required & must be 10 numbers
                  </span>
                )}
              </div>

              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  Region
                </Typography>
                {/* <Input
                  {...register("address", {
                    required: true,
                  })}
                /> */}
                   <select
                id='default'
                {...register("address", {
                  required: true,
                })}
                value={selectedRegion}
                onChange={(e) => {
                  const region = e.target.value;
                  setSelectedRegion(region);
                }}
                className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
              >
                {Object.keys(regions).map((region) => (
                  <option key={region} value={region}>
                    {' '}
                    {region}
                  </option>
                ))}
              </select>
              </div>

              <Button
                type="submit"
                variant="filled"
                color="blue"
                className="w-full mt-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex gap-4 items-center justify-center">
                    <Spinner size="sm" />
                    <Typography variant="h5" className="text-sm">
                      Please wait...
                    </Typography>
                  </div>
                ) : (
                  "Create Customer"
                )}
              </Button>
            </form>
          </Fragment>
        </DialogContent>

        <DialogFooter>
  
          <Button color="red" onClick={handleOpen} className="mx-3">
            Cancel
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default AddCustomerModal;

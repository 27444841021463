import { CustomerIDPropsGroup } from "../data/types";
import { ICustomer } from "../models/app.model";
import { FaEdit, FaEye } from "react-icons/fa";


const remapCustomers = (customers: Array<ICustomer>) => {
  return [...customers]
    .sort(
      (a: Partial<CustomerIDPropsGroup>, b: Partial<CustomerIDPropsGroup>) => {
        const dateA = new Date(a.createdAt!);
        const dateB = new Date(b.createdAt!);
        return dateB.getTime() - dateA.getTime();
      }
    )
    .map<Partial<CustomerIDPropsGroup>>((customer: Partial<CustomerIDPropsGroup>) => {
      const date = new Date(customer.createdAt!);
      const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "short",
        year: "numeric" as const,
      };
      return {
        ...customer,
        key: customer._id,
        createdAt: date.toLocaleDateString("en-US", options),
        Buttons: [
          {
            text: FaEye,
            onClick: () => console.log("Button 1 clicked"),
            disabled: false,
            link: "/customer-details/:customerId",
          },
          {
            text: FaEdit,
            onClick: () => {
            //   dispatch({
            //     type: "PARTNER_MODAL",
            //     partnerModal: false,
            //     customerId: customerId,
            //   });
            //   setCustomerId(customerId);
            },
            disabled: false,
          },
        ],
      };
    });
};


export default {
  remapCustomers
}
import { useEffect, Fragment, useState, useRef } from "react";
import CustomSkeleton from "../component/layout/CustomSkeleton";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { CSVLink } from "react-csv";
import RecentAccessoryRequestTable from "../component/element/tables/RecentAccessoryRequests";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";
import {
  getAccessoryRequest,
  getCategory,
  getDevices,
  getJobcards,
} from "../state/app.state";
import { useReactToPrint } from "react-to-print";
import CreateAccessoryRequest from "../component/element/accessory/CreateAccessoryRequest";
import { useMaterialTailwindController } from "../context";
import WithFunctionGuard from "../guards/FunctionGuard";

const AccessoryRequestPage = () => {
  let filteredData: Array<any> = [];
  const componentToPrint = useRef(null);
  const dispatch = useDispatch();
  const { loading, accessoryRequest ,roles} = useSelector(
    (reducer: any) => reducer.state
  );

  const loadCategory = () => {
    dispatch(getCategory())
  }

  // console.log(accessoryRequest);
  const { discharge } = useMaterialTailwindController();
  const generatePDFtoPrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  const onRef = (ref: any) => (componentToPrint.current = ref);

  try {
    filteredData = accessoryRequest.map((accessory: any) => {
      return {
        accessories: [],
        jobCard: accessory.jobcardId?._id,
        technician: accessory.adminId?._id,
        reason: accessory.reason,
        accessoryRequestStatus: accessory.status,
        createdAt: accessory.createdAt,
        
      };
    });
  } catch (err) {}

  const CreateAccessoryButton =  WithFunctionGuard(() => (
    <Button
    key={"create-accessory-request"}
    variant="gradient"
    color="blue"
    onClick={() =>
      discharge({
        type: "ACCESSORY_MODAL",
        accessoryModal: false,
      })
    }
  >
    Create Accessory Request
  </Button>
  ));

  return (
    <Fragment>
      <div className="mt-12">
      <CreateAccessoryRequest loadCategory={loadCategory}/>
        <div>
          {loading ? (
            <CustomSkeleton />
          ) : (
            <>
              <div className="mt-12">
               
                <div className="mb-20">
                  <div className="flex justify-end gap-10">
                  <CreateAccessoryButton />
                    <CSVLink
                      data={filteredData}
                      filename={"accessoryrequest.csv"}
                    >
                      <Button variant="gradient" color="blue">
                        Export CSV
                      </Button>
                    </CSVLink>
                    <Button
                      variant="outlined"
                      color="blue"
                      onClick={generatePDFtoPrint}
                    >
                      Print
                    </Button>
                  </div>
                </div>

                <Card shadow={false}>
                  <CardHeader
                    variant="gradient"
                    color="blue"
                    className="mb-8 p-4"
                  >
                    <Typography variant="h6" color="white">
                      Accessory Requests
                    </Typography>
                  </CardHeader>
                  
                  <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                  {roles?.menu === "KATCARE-PARTNER" ? 
                  (
                    <RecentAccessoryRequestTable
                    tableData={accessoryRequest}
                    tableRef={onRef}
                  />
                  ): (
                    <RecentAccessoryRequestTable
                    tableData={accessoryRequest}
                    tableRef={onRef}
                  />
                  )

                  }
                  </CardBody>
                </Card>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AccessoryRequestPage;

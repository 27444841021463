import {
  HomeIcon,
  ComputerDesktopIcon,
  WrenchScrewdriverIcon,
  UserGroupIcon,
  TableCellsIcon,
  ShoppingBagIcon,
} from '@heroicons/react/24/solid';
import HomePage from '../Pages/HomePage';
import JobCardPage from '../Pages/JobCardPage';
import ApprovalsPage from '../Pages/Approvals';
import CustomerScreen from '../Pages/CustomerScreen';
import DevicePage from '../Pages/DevicePage';
import AccessoryRequestPage from '../Pages/AccessoryRequestPage';
import AdministratorsPage from '../Pages/AdministratorsPage';
import ReportsPage from '../Pages/ReportsPage';
import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import SalesPage from '../Pages/SalesPage';
import StockRequest from '../Pages/StockRequest';
import InventoryPage from '../Pages/InventoryPage';
import PartnerSalesPage from '../Pages/PartnerSalesPage';
import PartnerAdministratorsPage from '../Pages/PartnerAdministratorsPage';
import ProductListPage from '../Pages/ProductListPage';
import AccessoryListPage from '../Pages/AccessoryListPage';
import DeviceCategoryPage from '../Pages/DeviceCategory';


const QUALITY_CONTROL = true;

interface Page {
  icon: JSX.Element;
  name: string;
  path: string;
  element: React.ReactNode;
}
const icon = {
  className: 'w-5 h-5 text-inherit',
};

// qc routes
export const qcRoutes = [
  {
    layout: 'katcare-admin',
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: 'Dashboard',
        path: '/',
        element: <HomePage />,
      },
      {
        icon: <WrenchScrewdriverIcon {...icon} />,
        name: 'Job Cards',
        path: '/job-cards',
        element: <JobCardPage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Accessory Requests',
        path: '/accessories',
        element: <AccessoryRequestPage />,
      },
      {
        icon: <ComputerDesktopIcon {...icon} />,
        name: 'Final Approvals',
        path: '/approvals',
        element: <ApprovalsPage />,
      },
    ],
  },
];

// technician router
export const technicianRoutes = [
  {
    layout: 'katcare-admin',
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: 'Dashboard',
        path: '/',
        element: <HomePage />,
      },
      {
        icon: <WrenchScrewdriverIcon {...icon} />,
        name: 'Job Cards',
        path: '/job-cards',
        element: <JobCardPage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Accessory Requests',
        path: '/accessories',
        element: <AccessoryRequestPage />,
      },
      {
        icon: <ComputerDesktopIcon {...icon} />,
        name: 'Final Approvals',
        path: '/approvals',
        element: <ApprovalsPage />,
      },
    ],
  },
];

// Katcare officer route
export const officerRoutes = [
  {
    layout: 'katcare-admin',
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: 'Dashboard',
        path: '/',
        element: <HomePage />,
      },
      {
        icon: <UserGroupIcon {...icon} />,
        name: 'Customers',
        path: '/customers',
        element: <CustomerScreen />,
      },
      {
        icon: <WrenchScrewdriverIcon {...icon} />,
        name: 'Job Cards',
        path: '/job-cards',
        element: <JobCardPage />,
      },
      {
        icon: <ShoppingBagIcon {...icon} />,
        name: 'Products',
        path: '/products',
        element: <ProductListPage />,
      },
      {
         icon: <TableCellsIcon {...icon} />,
          name: "Sales",
          path: "/sales",
         element: <SalesPage />,
         },
         {
          icon: <TableCellsIcon {...icon} />,
          name: "Device Category",
          path: "/device-category",
         element: <DeviceCategoryPage />,
         }
     
    ],
  },
];

// Katcare partner route
export const partnerRoutes = [
  {
    layout: 'katcare-admin',
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: 'Dashboard',
        path: '/',
        element: <HomePage />,
      },
      {
        icon: <UserGroupIcon {...icon} />,
        name: 'Customers',
        path: '/customers',
        element: <CustomerScreen />,
      },
      {
        icon: <WrenchScrewdriverIcon {...icon} />,
        name: 'Job Cards',
        path: '/job-cards',
        element: <JobCardPage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Accessory Requests',
        path: '/accessories',
        element: <AccessoryRequestPage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Products Requests',
        path: '/stocks-request',
        element: <StockRequest />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Sales',
        path: '/sales',
        element: <PartnerSalesPage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Shop ',
        path: '/inventory',
        element: <InventoryPage />,
      },
      // {
      //   icon: <TableCellsIcon {...icon} />,
      //   name: "Transactions",
      //   path: "/transactions",
      //   element: <TransactionsPage />
      // }
    ],
  },
];

// katcare store manager route
export const storeRoutes = [
  {
    layout: 'katcare-admin',
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: 'Dashboard',
        path: '/',
        element: <HomePage />,
      },
      {
        icon: <ComputerDesktopIcon {...icon} />,
        name: 'Devices ',
        path: '/devices-and-accessories',
        element: <DevicePage />,
      },
      {
        icon: <ShoppingBagIcon {...icon} />,
        name: 'Products',
        path: '/products',
        element: <ProductListPage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Shop',
        path: '/inventory',
        element: <InventoryPage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Products Requests',
        path: '/stocks-request',
        element: <StockRequest />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Accessorys',
        path: '/accessories',
        element: <AccessoryListPage />,
      },
      // {
      //   icon: <TableCellsIcon {...icon} />,
      //   name: "Sales",
      //   path: "/sales",
      //   element: <SalesPage />,
      // },
      // {
      //   icon: <TableCellsIcon {...icon} />,
      //   name: "Transactions",
      //   path: "/transactions",
      //   element: <TransactionsPage />
      // },
    ],
  },
];
// production manager
export const productionRoutes = [
  {
    layout: 'katcare-admin',
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: 'Dashboard',
        path: '/',
        element: <HomePage />,
      },
      {
        icon: <ComputerDesktopIcon {...icon} />,
        name: 'Devices ',
        path: '/devices-and-accessories',
        element: <DevicePage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Accessory Requests',
        path: '/accessories',
        element: <AccessoryRequestPage />,
      },
      // {
      //   icon: <ComputerDesktopIcon {...icon} />,
      //   name: "Final Approvals",
      //   path: "/approvals",
      //   element: <ApprovalsPage />,
      // },
    ],
  },
];

// General Katcare
export const managerRoutes = [
  {
    layout: 'katcare-admin',
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: 'Dashboard',
        path: '/',
        element: <HomePage />,
      },
      {
        icon: <UserGroupIcon {...icon} />,
        name: 'Customers',
        path: '/customers',
        element: <CustomerScreen />,
      },
      {
        icon: <UserGroupIcon {...icon} />,
        name: 'Managers',
        path: '/managers',
        element: <AdministratorsPage />,
      },
      {
        icon: <UserGroupIcon {...icon} />,
        name: 'Partners',
        path: '/partners',
        element: <PartnerAdministratorsPage />,
      },
      {
        icon: <WrenchScrewdriverIcon {...icon} />,
        name: 'Job Cards',
        path: '/job-cards',
        element: <JobCardPage />,
      },

      {
        icon: <ComputerDesktopIcon {...icon} />,
        name: 'Devices ',
        path: '/devices-and-accessories',
        element: <DevicePage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Accessory Requests',
        path: '/accessories',
        element: <AccessoryRequestPage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Product Requests',
        path: '/stocks-request',
        element: <StockRequest />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Shop ',
        path: '/inventory',
        element: <InventoryPage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: 'Sales',
        path: '/sales',
        element: <SalesPage />,
      },
      // {
      //   icon: <TableCellsIcon {...icon} />,
      //   name: "Transactions",
      //   path: "/transactions",
      //   element: <TransactionsPage />
      // },
      // {
      //   icon: <DocumentArrowUpIcon {...icon} />,
      //   name: "Reports",
      //   path: "/reports",
      //   element: <ReportsPage />,
      // }
    ],
  },
];

export const routes: Array<{ layout: string; pages: Page[] }> = [
  ...(QUALITY_CONTROL ? [] : storeRoutes),
  ...(QUALITY_CONTROL ? [] : productionRoutes),
  ...(QUALITY_CONTROL ? [] : managerRoutes),
  ...(QUALITY_CONTROL ? [] : technicianRoutes),
  ...(QUALITY_CONTROL ? [] : officerRoutes),
  ...(QUALITY_CONTROL ? [] : partnerRoutes),
  ...qcRoutes,
];

export const routex: any = {
  storeRoutes,
  productionRoutes,
  managerRoutes,
  technicianRoutes,
  officerRoutes,
  partnerRoutes,
  qcRoutes,
};

export default routes;

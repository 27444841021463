import Sidenav from "../component/layout/Sidenav";
import DashboardNavbar from "../component/layout/DashboardNavbar";
import Footer from "../component/layout/Footer";
import  { routex } from "../Routes/routes";
import { Routes, Route, useParams} from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Typography,
   Chip,
   MenuHandler,
   Menu,
   MenuList,
   MenuItem
} from "@material-tailwind/react";
import DetailPageSkeleton from "../component/layout/DetailPageSkeleton";
import {  useSelector } from "react-redux";
import {  useState } from "react";
import { NoSymbolIcon } from "@heroicons/react/24/solid";
import moment from 'moment';
import DashboardLayout from "../component/layout/DashboardLayout";

const PartnerSalesDetails = () => {
  const { _id } = useParams<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { sales, devices} = useSelector((reducer: any) => reducer.state);

  const thisProduct = sales.find((prod: any) => prod._id === _id)

  return (
        <DashboardLayout>
        <div className="my-12">
          {isLoading ? (
            <DetailPageSkeleton />
          ) : (
            <div>
              <Card
                className="w-full h-56 relative mb-24"
                color="blue"
                variant="gradient"
              >
                <CardHeader className="h-48 relative left-0 top-40">
                  <div className="flex flex-row justify-between p-3 items-center gap-5">
                    <div className="flex flex-row gap-5 items-center py-3">
                      <div className="mt-3">
                        <Typography
                          variant="h5"
                          color="blue-gray"
                          className="mb-2 "
                        >
                          #{thisProduct?._id}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="blue-gray"
                          className="mb-2 text-md"
                          textGradient
                        >
                          Order Created at:{" "}
                          <span className="text-md text-gray-700">
                          {moment(thisProduct?.createdAt).format('MMMM D, YYYY, h:mm:ss a')}
                          </span>
                        </Typography>   
                        <Typography
                          variant="h5"
                          color="blue-gray"
                          className="mb-2 text-md"
                          textGradient
                        >
                          Status:{" "}
                          <span className="text-md text-gray-700">
                          <Chip value={thisProduct?.status} />
                          </span>
                        </Typography>      
                      </div>
                    </div>

                    <div className="flex justify-between gap-3">
                        <Menu>
                    <MenuHandler>
        <Button variant="outlined">Status</Button>
      </MenuHandler>
      <MenuList>
        <MenuItem>Confirm</MenuItem>
        <MenuItem>Cancelled</MenuItem>
        <MenuItem>Delivery</MenuItem>
      </MenuList>
    </Menu>
                    </div>
                  </div>
                </CardHeader>
              </Card>

              <div className="grid grid-cols-2 my-8 gap-8 mt-52">
              <Card className="w-full">
                  <CardBody>
                    <div className="">
                      <Typography
                        variant="h5"
                        className="text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white"
                      >
                       Sales Info
                      </Typography>
                      <ul className="list-none">
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Status
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                            <Chip value={thisProduct?.status} />
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Quantity: 
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {thisProduct?.quantity}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Total Amount: 
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {thisProduct?.totalAmount}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Tracking Number
                            </h2>
                            <div className="inline-flex items-center text-sm font-semibold text-gray-900 dark:text-white">
                              {thisProduct?.trackingNumber}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>

                <Card className="w-full">
                  <CardBody>
                    <div className="">
                      <Typography
                        variant="h5"
                        className="text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white"
                      >
                       Customer Info
                      </Typography>
                      <ul className="list-none">
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Name:
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                            {thisProduct?.customerDetails?.firstName} {""} {thisProduct?.customerDetails?.lastName}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Phone Number:
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {thisProduct?.customerDetails?.phone}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Email
                            </h2>
                            <div className="inline-flex items-center text-sm font-semibold text-gray-900 dark:text-white">
                              {thisProduct?.customerDetails?.email}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    {thisProduct?.paymentType?.accountType !== "cash"  ? 
                    (
<div className="">
                      <Typography
                        variant="h5"
                        className="text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white"
                      >
                      Payment Info
                      </Typography>
                      <ul className="list-none">
                      <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">Payment Method</h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {thisProduct?.paymentType?.accountType}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Full Name:
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {thisProduct?.paymentType?.accountName}
                              
                            </div>
                          </div>
                        </li>
                      
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">
                              Account Number
                            </h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {thisProduct?.paymentType?.accountNumber}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="grid grid-cols-2">
                            <h2 className=" text-gray-500 truncate ">Network</h2>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {thisProduct?.paymentType?.accountIssuer}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    ): (
                        <div className="">
                        <Typography
                          variant="h5"
                          className="text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white"
                        >
                        Payment Info
                        </Typography>
                        <ul className="list-none">
                        <li className="py-3 sm:py-4">
                            <div className="grid grid-cols-2">
                              <h2 className=" text-gray-500 truncate ">Payment Method</h2>
                              <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                {thisProduct?.paymentType?.accountType}
                              </div>
                            </div>
                          </li>
                          </ul>
                          </div>
                    )}
                    
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    {thisProduct?.deliveryDetails !== null ? (
   <div className="">
   <Typography
     variant="h5"
     className="text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white"
   >
   Delivery Info
   </Typography>
   <ul className="list-none">
   <li className="py-3 sm:py-4">
       <div className="grid grid-cols-2">
         <h2 className=" text-gray-500 truncate ">Full Name</h2>
         <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
           {thisProduct?.deliveryDetails?.fullname}
         </div>
       </div>
     </li>
     <li className="py-3 sm:py-4">
       <div className="grid grid-cols-2">
         <h2 className=" text-gray-500 truncate ">
           Region
         </h2>
         <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
           {thisProduct?.deliveryDetails?.region}
           
         </div>
       </div>
     </li>
   
     <li className="py-3 sm:py-4">
       <div className="grid grid-cols-2">
         <h2 className=" text-gray-500 truncate ">
         City
         </h2>
         <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
           {thisProduct?.deliveryDetails?.city}
         </div>
       </div>
     </li>
     <li className="py-3 sm:py-4">
       <div className="grid grid-cols-2">
         <h2 className=" text-gray-500 truncate ">Address</h2>
         <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
           {thisProduct?.deliveryDetails?.address}
         </div>
       </div>
     </li>
     <li className="py-3 sm:py-4">
       <div className="grid grid-cols-2">
         <h2 className=" text-gray-500 truncate ">Phone Number</h2>
         <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
           {thisProduct?.deliveryDetails?.phone}
         </div>
       </div>
     </li>
   </ul>
 </div>
                    ): (
<div className="">
   <Typography
     variant="h5"
     className="text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white"
   >
   Delivery Info
   </Typography>
   <ul className="list-none">
   <li className="py-3 sm:py-4">
       <div className="grid grid-cols-2">
         <h2 className=" text-gray-500 truncate ">Delivery</h2>
         <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
           No Delivery
         </div>
       </div>
     </li>
     </ul>
     </div>
                    )}
                 
                  </CardBody>
                </Card>
              </div>

              {/* Customer Order Items */}
              <>
                {thisProduct.cart && thisProduct.cart.length === 0 ? (
                  <div className="flex flex-col items-center py-4 justify-center">
                    <NoSymbolIcon className="w-8 h-8 text-gray-600" />
                    <p className="text-lg mt-4 text-gray-600">
                      No customer order items available
                    </p>
                  </div>
                ) : (
                  <Card shadow={false}>
                    <CardBody>
                      <div className="py-2 pb-3">
                        <h5 className="font-bold text-lg  text-gray-900">
                          Order Items
                        </h5>
                      </div>
                      <div className="relative overflow-x-auto shadow-sm sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Device Name
                              </th>
                              <th scope="col" className="px-6 py-3">
                              Quantity
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Price
                              </th>
                             
                            </tr>
                          </thead>

                          {thisProduct?.cart && thisProduct?.cart .map(
                            (storeItem: any) => (
                              <tbody key={storeItem?._id}>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                  <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                  >
                                    {storeItem?.device === devices?.[0].model}
                                  </th>
                                  <td className="px-6 py-4">
                                    {storeItem?.quantity}
                                  </td>
                                  <td className="px-6 py-4">
                                    {storeItem?.price}
                                  </td>

                                </tr>
                              </tbody>
                            )
                          )}
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </>
            </div>
          )}
        </div>
        </DashboardLayout>
  );
};

export default PartnerSalesDetails 

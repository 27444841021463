import { Card, Input, Button, Typography } from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { PasswordProps } from "../../../data/types";
import { ToastContainer } from "react-toastify";
import { EyeIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../state/app.reducer";
import $service from "../../../services/app.service";
import { logout } from "../../../state/app.state";

export default function PasswordSetting() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useSelector((reducer: IStore) => reducer.state);
  const [showPassword, setShowPassword] = useState(false);
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data: PasswordProps) => {
    try {
      const { NewPassword, ConfirmNewPassword, CurrentPassword } = data;
      if (
        NewPassword === "" ||
        ConfirmNewPassword === "" ||
        CurrentPassword === ""
      ) {
        throw Error("Please provide all required data");
      }
      if(NewPassword !== ConfirmNewPassword){
        throw Error(
          'Passwords does not match'
        )
      }
      const payload={
        id: user._id,
        oldpassword: CurrentPassword,
        newpassword: NewPassword
      }
      setLoading(true);
      const response=await $service.updatePassword!(payload);
      if(!response.success){
        throw Error(
          response.message
        )
      }
      setLoading(false);
      alert('Password updated successfully');
      dispatch(logout());
    } catch (err) {
      setLoading(false);
      alert((err as Error).message);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordProps>({
    resolver: yupResolver(
      yup.object().shape({
        CurrentPassword: yup.string().required("old password is required"),
        NewPassword: yup
          .string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters"),
        ConfirmNewPassword: yup
          .string()
          .required("Confirm password is required")
          .oneOf([yup.ref("NewPassword")], "Passwords must match"),
      })
    ),
  });

  return (
    <div className="flex justify-center  items-center py-10">
      <ToastContainer />
      <Card color="transparent" shadow={false}>
        <form
          className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-4 flex flex-col gap-6">
            <div className="mb-2">
              <Typography className="text-sm font-semibold mb-1">
                Old Password
              </Typography>
              <Input
                size="lg"
                type={showPassword ? "text" : "password"}
                label="Old Password"
                {...register("CurrentPassword")}
                icon={<EyeIcon onClick={handlePassword} />}
              />
              {errors.CurrentPassword && (
                <p className="text-black">{errors.CurrentPassword?.message}</p>
              )}
            </div>

            <div className="mb-2">
              <Typography className="text-sm font-semibold mb-1">
                New Password
              </Typography>
              <Input
                size="lg"
                label="New Password"
                type={showPassword ? "text" : "password"}
                {...register("NewPassword")}
                icon={<EyeIcon onClick={handlePassword} />}
              />
              {errors.NewPassword && (
                <p className="text-red">{errors.NewPassword?.message}</p>
              )}
            </div>
            <div className="mb-2">
              <Typography className="text-sm font-semibold mb-1">
                Confirm Password
              </Typography>
              <Input
                size="lg"
                label="Confirm Password"
                type={showPassword ? "text" : "password"}
                {...register("ConfirmNewPassword")}
                icon={<EyeIcon onClick={handlePassword} />}
              />
              {errors.ConfirmNewPassword && (
                <p>{errors.ConfirmNewPassword?.message}</p>
              )}
            </div>
          </div>

          <Button type="submit" className="mt-6" fullWidth>
            Change Password
          </Button>
        </form>
      </Card>
    </div>
  );
}

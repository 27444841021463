import { BASEURL } from "../constants/baseurl";
import { IAdmin, ICustomer, IDevices } from "../models/app.model";

let subscriptions$: Function[] = [];

interface IServiceResponse {
  success: boolean;
  message: string;
  data: any;
  token?: string;
  refreshToken?: string;
  role?: string;
}

interface IService {
  token: string;
  headers: () => { [x: string]: string };
  setToken: (token: string) => void;
  allJobcards: () => Promise<IServiceResponse>;
  jobcardMetrics: () => Promise<IServiceResponse>;
  metrics: (id: string) => Promise<IServiceResponse>;
  getDevices: () => Promise<IServiceResponse>;
  getCustomerDevices: (id: string) => Promise<IServiceResponse>;
  getCustomers: () => Promise<IServiceResponse>;
  getCustomer: (id: string) => Promise<IServiceResponse>;
  deviceDetails: (id: string) => Promise<IServiceResponse>;
  addCustomer: (data: Partial<ICustomer>) => Promise<IServiceResponse>;
  addAdmin: (data: Partial<IAdmin>) => Promise<IServiceResponse>;
  addCustomerDevice: (data: any) => Promise<IServiceResponse>;
  warrantyCards: (id: string) => Promise<IServiceResponse>;
  getJobcardById: (id: string) => Promise<IServiceResponse>;
  getStatus: () => Promise<IServiceResponse>;
  transfer: (data: any) => Promise<IServiceResponse>;
  transferToKat: (data: { jobcardId: string, reason: string }) => Promise<IServiceResponse>;
  transferAccessoryRequest: (data: any) => Promise<IServiceResponse>;
  onError: (res: Response) => any;
  accessoryMetrics: () => Promise<IServiceResponse>;
  uploadBulk: (data: Array<Partial<IDevices>>) => Promise<IServiceResponse>;
  approvalMetrics: () => Promise<IServiceResponse>;
  next: (args: any) => void;
  getCustomerJobcards: (
    id: string,
    limit?: number
  ) => Promise<IServiceResponse>;
  completeJobcard: (id: string) => Promise<IServiceResponse>;
  getWarranty: (number:any) => Promise<IServiceResponse>;
  getDiagnosis: (id: string) => Promise<IServiceResponse>;
  addDiagnosis: (data: any) => Promise<IServiceResponse>;
  setJobcardStatus: (data: any) => Promise<IServiceResponse>;
  setApprovalStatus: (data: any) => Promise<IServiceResponse>;
  setDiagnosisStatus: (data: any) => Promise<IServiceResponse>;
  getNotifications: (id: string) => Promise<IServiceResponse>;
  getAdmins: () => Promise<IServiceResponse>;
  getAdminId: (id:string) => Promise<IServiceResponse>;
  getAdminRoles: () => Promise<IServiceResponse>;
  getAccessoryRequest: () => Promise<IServiceResponse>;
  getAccessoryRequestById: (id: string) => Promise<IServiceResponse>;
  getserialNumber: (id: string) => Promise<IServiceResponse>;
  getAccessoryDevices: (id: string) => Promise<IServiceResponse>;
  newAccessoryRequest: (data: any) => Promise<IServiceResponse>;
  addStockRequest: (data: any) => Promise<IServiceResponse>;
  getStockRequests: () => Promise<IServiceResponse>;
  getStockItems: () => Promise<IServiceResponse>;
  getStockItemsByPartnerId: (id: string) => Promise<IServiceResponse>;
  getInventoryRequests: () => Promise<IServiceResponse>;
  getInventoryRequestsById: (id: string) => Promise<IServiceResponse>;
  getSalesByPartnerId: (id: string) => Promise<IServiceResponse>
  setListener: (args: Function) => void;
  login: (data: {
    email: string;
    password: string;
  }) => Promise<IServiceResponse>;
  updatePassword: (payload: any) => Promise<IServiceResponse>;
  profile: (id: string) => Promise<IServiceResponse>;
  requestJobcard: (payload: Partial<any>) => Promise<IServiceResponse>;
  revokeToken: (token: string) => Promise<IServiceResponse>;
  refreshToken: (data: any) => Promise<IServiceResponse>;
  resetPassword: (data: any) => Promise<IServiceResponse>;
  sendOtp: (email: string) => Promise<IServiceResponse>;
  approveStockRequest: (data: { id: string }) => Promise<IServiceResponse>;
  addDeviceCategory: (data: { name: string }) => Promise<IServiceResponse>;
  addDevice: (data: any) => Promise<IServiceResponse>;
  updateDevice: (data: { id: string }) => Promise<IServiceResponse>;
  getOrders: (params: any) => Promise<IServiceResponse>;
  suspend: (data: {
    id: string;
    blocked: boolean;
  }) => Promise<IServiceResponse>;
  checkout: (data: any) => Promise<IServiceResponse>;
  getPartners: (region: any) => Promise<IServiceResponse>;
  getDeviceById: (id: string) => Promise<IServiceResponse>;
  getDeviceCategory: () => Promise<IServiceResponse>;
  getProduct:() => Promise<IServiceResponse>;
  addProducts: (data:any) => Promise<IServiceResponse>;
  getProductById: (id:any) => Promise<IServiceResponse>;
  updateProduct: (data: any) => Promise<IServiceResponse>
  deleteProduct: (id:string) => Promise<IServiceResponse>
  getShop: () => Promise<IServiceResponse>;
  addShop: (data:any) => Promise<IServiceResponse>
  adminSuspend:(
    data: {
      id: string;
      // blocked: boolean;
    }
   ) => Promise<IServiceResponse>
  adminActivate:(
    data: {
      id: string;
      // blocked: boolean;
    }
  ) => Promise<IServiceResponse>
  getAdminJobcardId:(id: string) => Promise<IServiceResponse>;
  getAdminAccessoryId:(id: string) => Promise<IServiceResponse>;
  addDeviceAccessories:(data: any) => Promise<IServiceResponse>;
  partnerBulk: (data: any) => Promise<IServiceResponse>;
  accessoryListById: (id: string) => Promise<IServiceResponse>;
  warrantyChecker: (data: any) => Promise<IServiceResponse>;
  getImage:(id: string) => Promise<IServiceResponse>;
  deleteCategory:(id: string) => Promise<IServiceResponse>;
  addShopProduct: (data: any) => Promise<IServiceResponse>;
  createMainShop: () => Promise<IServiceResponse>;
  getShopProducts: (id: string) => Promise<IServiceResponse>;
  getSalesOrders: () => Promise<IServiceResponse>;
  updateProfile: (data:any) => Promise<IServiceResponse>;
}

const $service: IService = {
  token: "",
  headers: function (): { [x: string]: string } {
    throw new Error("Function not implemented.");
  },
  setToken: function (token: string): void {
    throw new Error("Function not implemented.");
  },
  allJobcards: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  jobcardMetrics: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  metrics: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getDevices: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getCustomerDevices: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getCustomers: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getCustomer: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  deviceDetails: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getDeviceById: function (id: string): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
  addCustomer: function (data: Partial<ICustomer>): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  addCustomerDevice: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  warrantyCards: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getJobcardById: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getAdminId: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getserialNumber: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getSalesByPartnerId: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getCustomerJobcards: function (
    id: string,
    limit?: number | undefined
  ): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  completeJobcard: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getWarranty: function (
    number: any
  ): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getDiagnosis: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  addDiagnosis: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  setJobcardStatus: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  setDiagnosisStatus: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getNotifications: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getAccessoryRequest: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  newAccessoryRequest: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  login: function (data: {
    email: string;
    password: string;
  }): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  updatePassword: function (payload: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  profile: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  requestJobcard: function (payload: Partial<any>): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  revokeToken: function (token: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  refreshToken: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  resetPassword: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  sendOtp: function (email: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  suspend: function (data: {
    id: string;
    blocked: boolean;
  }): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getStatus: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  setApprovalStatus: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  transfer: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getAdmins: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  addAdmin: function (data: Partial<IAdmin>): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getAdminRoles: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  onError: function () {
    throw new Error("Function not implemented.");
  },
  setListener: function (args: Function): void {
    throw new Error("Function not implemented.");
  },
  next: function (args: any): void {
    throw new Error("Function not implemented.");
  },
  accessoryMetrics: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  approvalMetrics: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getAccessoryRequestById: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getAccessoryDevices: function (id: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  transferAccessoryRequest: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  transferToKat: function (data: {
    jobcardId: string;
    reason: string;
  }): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  uploadBulk: function (data: Partial<IDevices>[]): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getStockRequests: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  getStockItems: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented.");
  },
  addStockRequest: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented");
  },
  getStockItemsByPartnerId: function (data: any): Promise<IServiceResponse> {
    throw new Error("Function not implemented");
  },
  getInventoryRequests: function (): Promise<IServiceResponse> {
    throw new Error("Function not implemented");
  },
  getInventoryRequestsById: function (data: string): Promise<IServiceResponse> {
    throw new Error("Function not implemented");
  },
  approveStockRequest: function (data: {
    id: string;
  }): Promise<IServiceResponse> {
    throw Error("Function not implemented");
  },
  addDeviceCategory: function(data: { 
    name: string
  }): Promise<IServiceResponse> {
    throw Error("Function not implemented");
  },
  addDevice: function(data: any): Promise<IServiceResponse> {
    throw Error("Function not implemented");
  },
  updateDevice: function(id: any): Promise<IServiceResponse> {
    throw Error("Function not implemented");
  },
  checkout: function (data: any): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
  getOrders: function (params: any): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
  getPartners: function (any: any): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
  getDeviceCategory: function (): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
  getProduct: function (): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
  addProducts: function(data: any): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
  getProductById: function(id: string): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
  updateProduct: function(data:string): Promise<IServiceResponse> {
     throw Error("Function not implemented")
  },
    deleteProduct: function(id:any): Promise<IServiceResponse> {
         throw Error("Function not implemented")
    },
  getShop: function(): Promise<IServiceResponse> {
       throw Error("Function not implemented")
  },
  addShop: function(data: any): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
  adminSuspend: function(
    data: {
      id: string;
      // blocked: boolean;
    }
  ): Promise<IServiceResponse> {
     throw Error("Function not implemented")
  },
  adminActivate: function(
    data: {
      id: string;
      // blocked: boolean;
    }
  ): Promise<IServiceResponse> {
       throw Error("Function not implemented")
  },
  getAdminJobcardId: function(id: string): Promise<IServiceResponse>{
    throw Error("Function not implemented")
  },
  getAdminAccessoryId: function(id: string): Promise<IServiceResponse>{
    throw Error("Function not implemented")
  },
   addDeviceAccessories: function(data:any): Promise<IServiceResponse>{
    throw Error("Function not implemented")
   },
  partnerBulk: function(data:any): Promise<IServiceResponse>{
    throw Error("Function not implemented")
  },
  accessoryListById: function(id:string): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
  warrantyChecker: function(data:any): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
  getImage: function(id: string): Promise<IServiceResponse> {
    throw Error("Function not implemented")
  },
    deleteCategory: function(id:string): Promise<IServiceResponse> {
      throw Error("Function not implemented")
    },
  addShopProduct: function(data: any): Promise<IServiceResponse> {
     throw Error("Function not implemented")
  },
  createMainShop: function(): Promise<IServiceResponse> {
      throw Error("Function not implemented")
  },
  getShopProducts: function(id: string): Promise<IServiceResponse> {
     throw Error("Function not implemented")
  },
  getSalesOrders: function(): Promise<IServiceResponse> {
    throw Error ("Function not implemented")
  },
  updateProfile: function(data:any): Promise<IServiceResponse> {
    throw Error ("Function not implemented")
  }
};

$service.next = (args: typeof Object) => {
  for (const listener of subscriptions$) {
    if (typeof listener === "function") {
      listener(args); 
    }
  }
};

$service.setListener = (args) => {
  if (!subscriptions$.includes(args)) {
    subscriptions$.push(args);
  }
};

$service.headers = (): any => ({
  "Content-type": "application/json",
  Authorization: "Bearer " + $service.token,
});

$service.setToken = (token: string) => {
  $service.token = token;
};

$service.onError = (res: Response) => {
  switch (res.status) {
    case 200:
      return res.json();
    case 401:
      $service.next({ refreshToken: true });
      return { success: false };
    default:
      break;
  }
};

$service.uploadBulk = (data) => {
  return fetch(`${BASEURL}/devices/bulk`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

$service.partnerBulk = (data) => {
  return fetch(`${BASEURL}/admin/partners/import`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

$service.addDeviceAccessories= (data: any) =>
  fetch(`${BASEURL}/products/accessory/add`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

  $service.addStockRequest = (data: any) =>
  fetch(`${BASEURL}/product-request/new`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

  $service.addDevice = (data: any) =>
  fetch(`${BASEURL}/devices/new/v2`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

$service.sendOtp = (email: any) => {
  return fetch(`${BASEURL}/otp/sendotp`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify({ email }),
  }).then((res) => res.json());
};

$service.addCustomerDevice = (data: any) => {
  return fetch(`${BASEURL}/devices/customer/new`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

$service.addCustomer = (data: Partial<ICustomer>) => {
  return fetch(`${BASEURL}/customers/new`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

$service.addAdmin = (data: Partial<IAdmin>) => {
  return fetch(`${BASEURL}/admin/new`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

$service.addProducts = (data: any) => {
   return fetch(`${BASEURL}/products/add`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
   }).then((res) => res.json())
  };

$service.resetPassword = (data: any) => {
  return fetch(`${BASEURL}/admin/password/reset`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

$service.refreshToken = (data: any) => {
  return fetch(`${BASEURL}/tokens/refresh`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

$service.revokeToken = (token: string) => {
  return fetch(`${BASEURL}/tokens/revoke`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify({ refreshToken: token }),
  }).then((res) => res.json());
};

$service.login = (payload: { email: string; password: string }) => {
  return fetch(`${BASEURL}/admin/login`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

$service.setApprovalStatus = (payload: any) => {
  return fetch(`${BASEURL}/approvals/status`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

$service.setJobcardStatus = (payload: any) => {
  return fetch(`${BASEURL}/jobcards/status`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

$service.setDiagnosisStatus = (payload: any) => {
  return fetch(`${BASEURL}/jobcards/diagnosis/status`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

$service.transferToKat = (payload) => {
  return fetch(`${BASEURL}/jobcards/transfer-to-kat`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

$service.transfer = (payload: any) => {
  return fetch(`${BASEURL}/jobcards/transfer`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

$service.transferAccessoryRequest = (payload: any) => {
  return fetch(`${BASEURL}/accessory-request/transfer`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

$service.deleteProduct = (id: any) => {
  return fetch(`${BASEURL}/products/delete/${id}`, {
    method: "DELETE",
    headers: $service.headers!(),
    // body: JSON.stringify(payload),
  }).then((res) => res.json());
};

$service.getShopProducts = (id: any) => {
  return fetch(`${BASEURL}/shop/products/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());
};


$service.deleteCategory = (id: any) => {
  return fetch(`${BASEURL}/devices/category/${id}`, {
    method: "DELETE",
    headers: $service.headers!(),
    // body: JSON.stringify(payload),
  }).then((res) => res.json());
};

$service.addDiagnosis = (payload: any) => {
  return fetch(`${BASEURL}/jobcards/diagnose`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

$service.getAdmins = () =>
  fetch(`${BASEURL}/admin/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getAdminRoles = () =>
  fetch(`${BASEURL}/admin-roles/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getStatus = () =>
  fetch(`${BASEURL}/status/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getDiagnosis = (id: string) =>
  fetch(`${BASEURL}/approvals/get/${id}`, {
    headers: $service.headers!()
  }).then((res) => res.json());

  $service.getImage = (id: string) =>
  fetch(`${BASEURL}/documents/image/${id}`, {
    headers: $service.headers!()
  }).then((res) => res.json());

  $service.accessoryListById = (id: string) =>
  fetch(`${BASEURL}/products/accessories/get/${id}`, {
    headers: $service.headers!()
  }).then((res) => res.json());

  $service.getAdminId = (id: string) =>
  fetch(`${BASEURL}/admin/get/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

  $service. getAdminJobcardId =( id: string) => 
    fetch(`${BASEURL}/jobcards/admin/${id}`, {
    headers: $service.headers!(),
    }).then((res) => res.json());
  
    $service. getAdminAccessoryId =( id: string) => 
    fetch(`${BASEURL}/accessory-request/admin/${id}`, {
    headers: $service.headers!(),
    }).then((res) => res.json());
  

  $service.getserialNumber = (id: string) =>
  fetch(`${BASEURL}/devices/serialnumbers/device/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

  $service.getDeviceById  = (id: string) =>
  fetch(`${BASEURL}/devices/get/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getCustomerDevices = (id: string) =>
  fetch(`${BASEURL}/customer-devices/get/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.deviceDetails = (id: string) =>
  fetch(`${BASEURL}/customer-devices/details/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getJobcardById = (id: string) =>
  fetch(`${BASEURL}/jobcards/get/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getCustomerJobcards = (id: string, limit = 1000) =>
  fetch(`${BASEURL}/jobcards/customer/get?id=${id}&limit=${limit}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getWarranty = (number : any) =>
  fetch(
    `${BASEURL}/devices/check-warranty/${number}`,
    {
      headers: $service.headers!(),
    }
  ).then((res) => res.json());

$service.newAccessoryRequest = (data: any) =>
  fetch(`${BASEURL}/accessory-request/new`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

  $service.addShopProduct = (data: any) =>
  fetch(`${BASEURL}/shop/products/add`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

  $service.createMainShop = () =>
  fetch(`${BASEURL}/shop/main/create`, {
    method: "POST",
    headers: $service.headers!(),
    // body: JSON.stringify(),
  }).then((res) => res.json());


$service.getAccessoryRequest = () =>
  fetch(`${BASEURL}/accessory-request/role/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getAccessoryRequestById = (id: string) =>
  fetch(`${BASEURL}/accessory-request/get/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getAccessoryDevices = (id: string) =>
  fetch(`${BASEURL}/accessory-request-devices/get/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getDevices = () =>
  fetch(`${BASEURL}/devices/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getCustomers = () =>
  fetch(`${BASEURL}/customers/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.jobcardMetrics = () =>
  fetch(`${BASEURL}/jobcards/metrics/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.accessoryMetrics = () =>
  fetch(`${BASEURL}/accessory-request/metrics/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.approvalMetrics = () =>
  fetch(`${BASEURL}/approvals/metrics/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.warrantyCards = (id: string) =>
  fetch(`${BASEURL}/warranty/customer/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getNotifications = (id: string) =>
  fetch(`${BASEURL}/notifications/get/${id}`, {
    headers: $service.headers!(),
  }).then($service.onError);

$service.allJobcards = () =>
  fetch(`${BASEURL}/jobcards/role/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

  $service.getProduct = () =>
  fetch(`${BASEURL}/products/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());


$service.profile = (id: string) =>
  fetch(`${BASEURL}/admin/get/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getCustomer = (id: string) =>
  fetch(`${BASEURL}/customers/get/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.completeJobcard = (id: string) =>
  fetch(`${BASEURL}/jobcards/complete/${id}`, {
    method: "PUT",
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.requestJobcard = (payload: any) =>
  fetch(`${BASEURL}/jobcards/add`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());

$service.suspend = (data) =>
  fetch(`${BASEURL}/customers/suspend`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

  $service.adminSuspend = (data) =>
  fetch(`${BASEURL}/admin/suspend`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

  $service.adminActivate = (data) =>
  fetch(`${BASEURL}/admin/activate`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

$service.updatePassword = (payload: any) =>
  fetch(`${BASEURL}/admin/password/update`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(payload),
  }).then((res) => res.json());

$service.getStockRequests = () =>
  fetch(`${BASEURL}/product-request/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

  $service.getProductById = (id: any) => 
    fetch(`${BASEURL}/products/get/${id}`, {
       headers: $service.headers!()
    }).then(res => res.json())
  

$service.getStockItems = () =>
  fetch(`${BASEURL}/stock-request/partner/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getStockItemsByPartnerId = (id: string) =>
  fetch(`${BASEURL}/stock-request/items/stock/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getSalesByPartnerId = (id: string) =>
  fetch(`${BASEURL}/orders/partner/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

  $service.getSalesOrders= () =>
  fetch(`${BASEURL}/orders/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());



$service.getInventoryRequests = () =>
  fetch(`${BASEURL}/inventory/partner/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

  $service.getShop = () =>
  fetch(`${BASEURL}/shop/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.getInventoryRequestsById = (id: string) =>
  fetch(`${BASEURL}/inventory/partner/get/${id}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

  $service.getPartners = (region: any) =>
  fetch(`${BASEURL}/admin/region/${region}`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

  $service.getDeviceCategory = () => 
     fetch(`${BASEURL}/devices/categories/get`, {
    headers: $service.headers!(),
  }).then((res) => res.json());

$service.approveStockRequest = (data) =>
  fetch(`${BASEURL}/stock-request/approve`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

  $service.updateProduct = (data) =>
  fetch(`${BASEURL}/products/update`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());


  $service.updateProfile= (data) =>
  fetch(`${BASEURL}/admin/update`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

  $service.updateDevice = (data) =>
  fetch(`${BASEURL}/devices/update`, {
    method: "PUT",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());

$service.addDeviceCategory = (data) => {
  return fetch(`${BASEURL}/devices/categories/add`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());
}

$service.addShop = (data) => {
  return fetch(`${BASEURL}/shop/add`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data),
  }).then((res) => res.json());
}

$service.checkout = (data) => {
  return fetch(`${BASEURL}/orders/admin/checkout`, {
    method: "POST",
    headers: $service.headers!(),
    body: JSON.stringify(data)
  }).then((res)=> res.json())
}

$service.getOrders = (params: any) => {
  const expectedParams = ["startDate", "endDate", "status"]
  let paramsString = "?"
  expectedParams.forEach(param=>{
    if(params.hasOwnProperty(param)) {
      paramsString += `${param}=${params[param]}&`
    }
  })

  let url = `${BASEURL}/orders/filter`

  url = paramsString.length > 3 ? url+paramsString : url
  
  return fetch(url, {
    headers: $service.headers!(),
  }).then((res) => res.json());
}

export default $service;

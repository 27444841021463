import React from 'react'
import Sidenav from './Sidenav'
import DashboardNavbar from './DashboardNavbar'
import { Route, Routes } from 'react-router-dom'
import Footer from './Footer'
import { useSelector } from 'react-redux'
import { routex } from '../../Routes/routes'
import Logo from "../../Assets/images/logo.png";

interface DashboardProps {
    children: React.ReactNode;
}

const DashboardLayout = ({children}: DashboardProps) => {
    const { roles} = useSelector((reducer: any) => reducer.state);

  const getMenu = () => {
    try {
      return roles.menu ? routex[roles.menu] : [];
    } catch (err) {
      return [];
    }
  };

  return (
    <div className="min-h-screen py-5 bg-blue-gray-50/50">
      <Sidenav routex={getMenu()} brandImg={Logo} />
      <div className="p-4 xl:ml-80">
        <DashboardNavbar />
        <Routes>
          {getMenu().map(
            ({ pages, layout }: any) =>
              layout === "katcare-developer" &&
              pages.map(({ path, element }: any) => (
                <Route path={path} element={element} key={path} />
              ))
          )}
        </Routes>
       {children}

        <div className="text-blue-gray-600">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
import { createElement } from "react";
import { FaBriefcase } from "react-icons/fa";
import { RiGitPullRequestFill } from "react-icons/ri";
import { TbCircleCheck } from "react-icons/tb";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { IStore } from "../../state/app.reducer";

const KatCard = () => {
  const { loading, jobcardMetrics, approvalMetrics, accessoryMetrics } =
    useSelector((reducer: IStore) => reducer.state);

  return (
    <div className="mb-12 grid gap-10 gap-x-6 md:grid-cols-2 lg:grid-cols-3">
      <Card className="w-full">
        <CardHeader
          variant="gradient"
          className={`absolute -mt-4 grid h-10 w-10 place-items-center  bg-[#1e88e5]`}
        >
          {createElement(FaBriefcase, { style: { fill: "#FFFFFF" } })}
        </CardHeader>
        <CardBody className="p-4 text-right">
          <Typography
            variant="small"
            className="font-normal mt-5 text-blue-gray-600"
          >
            Job Cards
          </Typography>
          <Typography variant="h4" color="blue-gray">
            {jobcardMetrics.total}
          </Typography>
        </CardBody>
      </Card>
      <Card className="w-full">
        <CardHeader
          variant="gradient"
          className={`absolute -mt-4 grid h-10 w-10 place-items-center  bg-[#1e88e5]`}
        >
          {createElement(RiGitPullRequestFill, { style: { fill: "#FFFFFF" } })}
        </CardHeader>
        <CardBody className="p-4 text-right">
          <Typography
            variant="small"
            className="font-normal mt-5 text-blue-gray-600"
          >
            Accessory Request
          </Typography>
          <Typography variant="h4" color="blue-gray">
            {accessoryMetrics?.total}
          </Typography>
        </CardBody>
      </Card>
      <Card className="w-full">
        <CardHeader
          variant="gradient"
          className={`absolute -mt-4 grid h-10 w-10 place-items-center  bg-[#1e88e5]`}
        >
          {createElement(TbCircleCheck, { style: { fill: "#FFFFFF" } })}
        </CardHeader>
        <CardBody className="p-4 text-right">
          <Typography
            variant="small"
            className="font-normal mt-5 text-blue-gray-600"
          >
            Approvals
          </Typography>
          <Typography variant="h4" color="blue-gray">
            {approvalMetrics}
          </Typography>
        </CardBody>
      </Card>
    </div>
  );
};

export default KatCard;

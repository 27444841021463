import React from 'react'
import { Skeleton } from '@mui/material'
import { CardBody, Card, CardHeader, CardFooter } from '@material-tailwind/react'

const CustomSkeleton = () => {
  return (
    <div className="">
          <div className="mb-10 content-center grid  gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
          <div className=''>
          <Skeleton variant="rounded" width={210} height={40} />
            </div>
            <div className="flex justify-between">
            <div className=''>
            <Skeleton variant="rounded" width={210} height={40} />
            </div>
            <div className=''>
            <Skeleton variant="rounded" width={210} height={40} />
            </div>
        </div>
          </div>
          <Card>
         <CardHeader className="mb-8 p-6">
         <Skeleton variant="rounded" width={'100%'} height={40} />
         </CardHeader>
         <CardBody className='overflow-x-scroll px-2 pt-0 pb-2'>
         <Skeleton variant="rounded" width={'100%'} height={40} style={{ marginBottom: 6 }} />
         <Skeleton variant="rounded" width={'100%'} height={40}  style={{ marginBottom: 6 }}/>
         <Skeleton variant="rounded" width={'100%'} height={40} style={{ marginBottom: 6 }} />
         <Skeleton variant="rounded" width={'100%'} height={40} style={{ marginBottom: 6 }}/>
         <Skeleton variant="rounded" width={'100%'} height={40} />
         </CardBody>
         <CardFooter>
          <div className="flex justify-between">
          <Skeleton variant="rounded" width={210} height={40} />
          <Skeleton variant="rounded" width={210} height={40} />
          </div>
         </CardFooter>
         </Card>
    </div>
  )
}

export default CustomSkeleton
import { configureStore } from "@reduxjs/toolkit";
import state, { IStateModel } from "./app.state";

export interface IStore {
  state: IStateModel;
}

const provider = configureStore({
  reducer: {
    state,
  },
});

export default provider;

import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../layout/DashboardLayout';
import $service from '../../../services/app.service';
import {
  Button,
  Card,
  CardBody,
  Input,
  Textarea,
  Typography,
} from '@material-tailwind/react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { IStore } from '../../../state/app.reducer';
import { useForm } from 'react-hook-form';

const UpdateProfile = () => {
  const { user } = useSelector((reducer: IStore) => reducer.state);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const updateUserProfile = async (data: any) => {
    try {
      const payload = {
        id: user._id,
        data: {
          first_name: data.first_name,
          last_name: data.last_name,
          middle_name: data.middle_name,
          email: data.email,
          mobile: data.mobile,
          company_name: data.company_name,
          district: data.district,
          region: data.region,
          digital_address: data.digital_address,
          lat: data.lat,
          lng: data.lng,
          locationAddress: data.locationAddress,
        },
      };
      setLoading(false);
      const response = await $service.updateProfile(payload);
      if (!response.success) {
        throw new Error(response.message);
      }
      setLoading(false);
      toast.success(response.message);
      setDisabled(!disabled);
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const { handleSubmit, register } = useForm<any>();

  const handleDisabled = () => {
    setDisabled(!disabled);
  };

  return (
    <div className='mt-12'>
      <Card className='p-5'>
        <CardBody>
          <div className='py-5 px-3'>
            <Typography variant='h4'>Profile Info Update</Typography>
          </div>
          <hr />
          <form onSubmit={handleSubmit(updateUserProfile)} className='mt-5'>
            <div className='grid grid-cols-2 gap-8'>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  First Name
                </Typography>
                <Input
                  {...register('first_name')}
                  disabled={!disabled}
                  defaultValue={user?.first_name}
                  className='text-black'
                />
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  Last Name
                </Typography>
                <Input
                  {...register('last_name')}
                  disabled={!disabled}
                  defaultValue={user?.last_name}
                  className='text-black'
                />
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  Middle Name
                </Typography>
                <Input
                  {...register('middle_name')}
                  disabled={!disabled}
                  defaultValue={user?.middle_name}
                  className='text-black'
                />
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  Email
                </Typography>
                <Input
                  {...register('email')}
                  disabled={!disabled}
                  defaultValue={user?.email}
                  className='text-black'
                />
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  Phone Number
                </Typography>
                <Input
                  {...register('mobile')}
                  disabled={!disabled}
                  defaultValue={user?.mobile}
                  className='text-black'
                />
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  Company Name
                </Typography>
                <Input
                  {...register('company_name')}
                  disabled={!disabled}
                  defaultValue={user?.company_name}
                  className='text-black'
                />
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  Region
                </Typography>
                <Input
                  {...register('region')}
                  disabled={!disabled}
                  defaultValue={user?.region}
                  className='text-black'
                />
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  District
                </Typography>
                <Input
                  {...register('district')}
                  disabled={!disabled}
                  defaultValue={user?.district}
                  className='text-black'
                />
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  Digital Address
                </Typography>
                <Input
                  {...register('digital_address')}
                  disabled={!disabled}
                  defaultValue={user?.digital_address}
                  className='text-black'
                />
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  Location Address
                </Typography>
                <Input
                  {...register('locationAddress')}
                  disabled={!disabled}
                  defaultValue={user?.locationAddress}
                  className='text-black'
                />
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  Latitude
                </Typography>
                <Input
                  {...register('lat')}
                  disabled={!disabled}
                  defaultValue={user?.lat}
                  className='text-black'
                />
              </div>
              <div className='mb-2'>
                <Typography className='text-sm font-semibold mb-1'>
                  Longitude
                </Typography>
                <Input
                  {...register('lng')}
                  disabled={!disabled}
                  defaultValue={user?.lng}
                  className='text-black'
                />
              </div>
              <div className='my-3 '>
              <Button
                        className='w-full'
                        variant='filled'
                        color='blue'
                        type='button'
                        onClick={() => setDisabled(!disabled)}
                      >
                        Edit
                      </Button>
                  
              </div>
              <div className='my-3'>
                      <Button
                        className='w-full'
                        variant='filled'
                        color='teal'
                        type='submit'
                        disabled={loading}
                      >
                        Update Profile
                      </Button>
                    </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default UpdateProfile;

import { useLocation, Link } from "react-router-dom";
import {
  Navbar,
  Typography,
  IconButton,
  Breadcrumbs,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Button,
} from "@material-tailwind/react";
import {
  Cog6ToothIcon,
  BellIcon,
  ClockIcon,
  Bars3Icon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import { useMaterialTailwindController } from "../../context/index";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../state/app.reducer";
import { useEffect } from "react";
import { getNotifications } from "../../state/app.state";
import moment from "moment";
import WithFunctionGuard from "../../guards/FunctionGuard";


export function DashboardNavbar() {
  const dispatch = useDispatch();
  const { notifications } = useSelector((reducer: IStore) => reducer.state);
  const { controller, discharge } = useMaterialTailwindController();
  const { fixedNavbar } = controller;
  const { pathname } = useLocation();
  const [layout, page] = pathname.split("/").filter((el) => el !== "");


  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const AddJobCardButton = WithFunctionGuard(() => (
    <Button
      onClick={() => discharge({ type: "JOBS_MODAL", jobModal: true })}
      variant="filled"
      color="blue"
      className="hidden items-center gap-1 mr-2 px-4 xl:flex"
      key={"add-jobcard"}
    >
      <PlusCircleIcon className="h-5 w-5 text-white" />
      Add JobCard
    </Button>
  ));

  return (
    <Navbar
      color={fixedNavbar ? "white" : "transparent"}
      className={`rounded-xl transition-all ${
        fixedNavbar
          ? "sticky top-4 z-40 py-3 shadow-2xl shadow-blue-gray-500/5"
          : "px-0 py-1"
      }`}
      fullWidth
      blurred={fixedNavbar}
    >
      <div className="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
        <div className="capitalize">
          <Breadcrumbs
            className={`bg-transparent p-0 transition-all ${
              fixedNavbar ? "mt-1" : ""
            }`}
          >
            <Link to={`/${layout}`}>
              <Typography
                variant="h6"
                color="blue-gray"
                className="font-normal opacity-50 transition-all hover:text-blue-500 hover:opacity-100"
              >
                {layout}
              </Typography>
            </Link>
            {/* <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              {page}
            </Typography> */}
          </Breadcrumbs>
        </div>

        <div className="flex items-center gap-3">
          <AddJobCardButton />

          <IconButton
            variant="text"
            color="blue-gray"
            className="grid xl:hidden"
            onClick={() =>
              discharge({ type: "OPEN_SIDENAV", openSidenav: true })
            }
          >
            <Bars3Icon strokeWidth={3} className="h-6 w-6 text-blue-gray-500" />
          </IconButton>

          <Link to="/settings">
            <IconButton variant="text" color="blue-gray">
              <Cog6ToothIcon className="h-5 w-5 text-blue-gray-500" />
            </IconButton>
          </Link>

          <Menu placement="left-start">
            <MenuHandler>
              <IconButton variant="text" color="blue-gray">
                <BellIcon className="h-5 w-5 text-blue-gray-500" />
              </IconButton>
            </MenuHandler>
            <MenuList className="realtive border-0">
              {notifications &&
                notifications.length > 0 &&
                notifications.map((n) => (
                  <MenuItem className="flex items-center gap-3" key={n?._id}>
                    <Avatar
                      src="https://demos.creative-tim.com/material-dashboard/assets/img/team-2.jpg"
                      alt="item-1"
                      size="sm"
                      variant="circular"
                    />
                    <div>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-normal "
                      >
                        <strong>New message from</strong>{" "}
                        {n.type !== "BROADCAST"
                          ? ` ${n.senderId?.first_name}`
                          : "Broadcast"}
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="flex items-left gap-1  font-normal my-1"
                      >
                        <p className="line-clamp-3 w-72"> {n.message} </p>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="flex items-center gap-1 text-xs font-normal opacity-60"
                      >
                        <ClockIcon className="h-3.5 w-3.5" />{" "}
                        {moment(new Date(n.createdAt)).fromNow()}
                      </Typography>
                    </div>
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        </div>
      </div>
    </Navbar>
  );
}

export default DashboardNavbar;

import Sidenav from "../component/layout/Sidenav";
import DashboardNavbar from "../component/layout/DashboardNavbar";
import Footer from "../component/layout/Footer";
import routes, { routex } from "../Routes/routes";
import { Routes, Route } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Typography } from "@material-tailwind/react";
import { Box } from "@mui/material";
import ComesticStandardForm from "../component/element/QualityForm/CosmeticStandardForm";
import LaptopStandardForm from "../component/element/QualityForm/LaptopStandardForm";
import StandardPackageForm from "../component/element/QualityForm/StandardPackageForm";

import Logo from "../Assets/images/logo.png";
import { useSelector } from "react-redux";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const QualityCheckPage = () => {
  const { roles }=useSelector((reducer: any)=>reducer.state);
  const getMenu = () => {
    try {
      return roles.menu ?  routex[roles.menu] : []
    }
    catch (err) {
      return []
    }
  }

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="min-h-screen py-5 bg-blue-gray-50/50">
      <Sidenav routex={getMenu()} brandImg={Logo} />
      <div className="p-4 xl:ml-80">
        <DashboardNavbar />
        <Routes>
          {getMenu().map(
            ({ pages, layout }:any) =>
              layout === "katcare-developer" &&
              pages.map(({ path, element }:any) => (
                <Route path={path} element={element} key={path} />
              ))
          )}
        </Routes>
        <div className="">
          <div className="flex">
            <aside className=" z-50 h-screen w-56  border-r-2 border-teal-400">
              <Tabs
                orientation="vertical"
                variant="scrollable"
                textColor="inherit"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ paddingTop: 10, fontSize: "11px" }}
              >
                <Tab label=" Function Standard" {...a11yProps(0)} />
              </Tabs>
            </aside>

            <TabPanel value={value} index={0}>
              <LaptopStandardForm />
            </TabPanel>
          </div>
        </div>

        <div className="text-blue-gray-600">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default QualityCheckPage;

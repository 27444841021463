import { Routes, Route, useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Typography,
  Chip,
  MenuHandler,
  Menu,
  MenuList,
  MenuItem,
} from '@material-tailwind/react';
import DetailPageSkeleton from '../component/layout/DetailPageSkeleton';
import { useSelector } from 'react-redux';
import { JSXElementConstructor, Key, ReactElement, ReactFragment, ReactPortal, useState } from 'react';
import { NoSymbolIcon } from '@heroicons/react/24/solid';
import { useMaterialTailwindController } from '../context';
import AssignDeliveryModal from '../component/element/AssignDeliveryModal';
import DashboardLayout from '../component/layout/DashboardLayout';

const OrderDetailsPage = () => {
  const { _id } = useParams<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { roles, salesMetrics,sale,products } = useSelector((reducer: any) => reducer.state);
  const { discharge } = useMaterialTailwindController();



  const thisProduct = sale?.find(
    (prod: any) => prod._id === _id
  );
  const thisDevice = products?.find((pd: any) => pd?._id === thisProduct?.cart?.device);

  console.log(thisDevice)


  return (
    <DashboardLayout>
      <div className='my-12'>
        <AssignDeliveryModal />
        {isLoading ? (
          <DetailPageSkeleton />
        ) : (
          <div>
            <Card
              className='w-full h-56 relative mb-24'
              color='blue'
              variant='gradient'
            >
              <CardHeader className='h-64 relative left-0 top-40'>
                <div className='flex flex-row justify-between p-3 items-center gap-5'>
                  <div className='flex flex-row gap-5 items-center py-3'>
                    <div className='mt-3'>
                      {/* <Typography
                        variant='h5'
                        color='blue-gray'
                        className='mb-2 '
                      >
                        #{thisProduct?._id}
                      </Typography> */}
                      <Typography
                        variant='h5'
                        color='blue-gray'
                        className='mb-2 text-md'
                        textGradient
                      >
                        Order Created At:{' '}
                        <span className='text-md text-gray-700'>
                          { new Date(thisProduct?.createdAt).toDateString()}
                        </span>
                      </Typography>
                      <div className='flex gap-2 mb-4 items-baseline'>
                        <Typography
                          variant='h5'
                          color='blue-gray'
                          className='mb-2 text-md '
                          textGradient
                        >
                          Total:
                        </Typography>
                        <span className='text-md text-gray-700 '>
                          Ghs {thisProduct?.totalAmount}.00
                        </span>
                      </div>
                      <div className='flex gap-2 mb-4 items-baseline'>
                        <Typography
                          variant='h5'
                          color='blue-gray'
                          className='mb-2 text-md '
                          textGradient
                        >
                          Quantity
                        </Typography>
                        <span className='text-md text-gray-700 '>
                          {thisProduct?.quantity} 
                        </span>
                      </div>
                      <div className='flex gap-2 mb-4 items-baseline'>
                        <Typography
                          variant='h5'
                          color='blue-gray'
                          className='mb-2 text-md '
                          textGradient
                        >
                          Tracking Number
                        </Typography>
                        <span className='text-md text-gray-700 '>
                          {thisProduct?.trackingNumber}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between gap-3'>
                    <Menu>
                      <MenuHandler>
                        <Button variant='outlined'>Status</Button>
                      </MenuHandler>
                      <MenuList>
                        <MenuItem>Confirm</MenuItem>
                        <MenuItem>Cancelled</MenuItem>
                      </MenuList>
                    </Menu>
                    <Button
                      onClick={() =>
                        discharge({
                          type: 'ASSIGN_DELIVERY',
                          assignDelivery: false,
                        })
                      }
                    >
                      Delivery
                    </Button>
                  </div>
                </div>
              </CardHeader>
            </Card>
            <div className='grid grid-cols-2 my-8 gap-8 mt-52'>
              <Card className='w-full'>
                <CardBody>
                  <div className=''>
                    <Typography
                      variant='h5'
                      className='text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white'
                    >
                      Customer Info
                    </Typography>
                    <ul className='list-none'>
                      <li className='py-3 sm:py-4'>
                        <div className='grid grid-cols-2'>
                          <h2 className=' text-gray-500 truncate '>Name:</h2>
                          <div className='inline-flex items-center text-base font-semibold text-gray-900 dark:text-white'>
                            {thisProduct?.customerId?.firstName} {''}{' '}
                            {thisProduct?.customerId?.lastName}
                          </div>
                        </div>
                      </li>
                      <li className='py-3 sm:py-4'>
                        <div className='grid grid-cols-2'>
                          <h2 className=' text-gray-500 truncate '>
                            Phone Number:
                          </h2>
                          <div className='inline-flex items-center text-base font-semibold text-gray-900 dark:text-white'>
                            {thisProduct?.customerId?.phone}
                          </div>
                        </div>
                      </li>
                      <li className='py-3 sm:py-4'>
                        <div className='grid grid-cols-2'>
                          <h2 className=' text-gray-500 truncate '>Email</h2>
                          <div className='inline-flex items-center text-sm font-semibold text-gray-900 dark:text-white'>
                            {thisProduct?.customerId?.email}
                          </div>
                        </div>
                      </li>
                      <li className='py-3 sm:py-4'>
                        <div className='grid grid-cols-2'>
                          <h2 className=' text-gray-500 truncate '>Address:</h2>
                          <div className='inline-flex items-center text-sm font-semibold text-gray-900 dark:text-white'>
                            {thisProduct?.customerId?.address}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className=''>
                    <Typography
                      variant='h5'
                      className='text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white'
                    >
                      Payment Info
                    </Typography>
                    <ul className='list-none'>
                      <li className='py-3 sm:py-4'>
                        <div className='grid grid-cols-2'>
                          <h2 className=' text-gray-500 truncate '>
                            Payment Method
                          </h2>
                          <div className='inline-flex items-center text-base font-semibold text-gray-900 dark:text-white'>
                            {thisProduct?.paymentType?.accountType}
                          </div>
                        </div>
                      </li>
                      <li className='py-3 sm:py-4'>
                        <div className='grid grid-cols-2'>
                          <h2 className=' text-gray-500 truncate '>
                            Full Name:
                          </h2>
                          <div className='inline-flex items-center text-base font-semibold text-gray-900 dark:text-white'>
                            {thisProduct?.paymentType?.accountName}
                          </div>
                        </div>
                      </li>

                      <li className='py-3 sm:py-4'>
                        <div className='grid grid-cols-2'>
                          <h2 className=' text-gray-500 truncate '>
                            Account Number
                          </h2>
                          <div className='inline-flex items-center text-base font-semibold text-gray-900 dark:text-white'>
                            {thisProduct?.paymentType?.accountNumber}
                          </div>
                        </div>
                      </li>
                      <li className='py-3 sm:py-4'>
                        <div className='grid grid-cols-2'>
                          <h2 className=' text-gray-500 truncate '>Network</h2>
                          <div className='inline-flex items-center text-base font-semibold text-gray-900 dark:text-white'>
                            {thisProduct?.paymentType?.accountIssuer}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </div>

            {/* Customer Job Cards */}
            <>
              {thisProduct?.cart && thisProduct?.cart?.length === 0 ? (
                <div className='flex flex-col items-center py-4 justify-center'>
                  <NoSymbolIcon className='w-8 h-8 text-gray-600' />
                  <p className='text-lg mt-4 text-gray-600'>
                    No customer order items available
                  </p>
                </div>
              ) : (
                <Card shadow={false}>
                  <CardBody>
                    <div className='py-2 pb-3'>
                      <h5 className='font-bold text-lg  text-gray-900'>
                        Order Items
                      </h5>
                    </div>
                    <div className='relative overflow-x-auto shadow-sm sm:rounded-lg'>
                      <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
                        <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                          <tr>
                            <th scope='col' className='px-6 py-3'>
                              Device Name
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Quantity
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Price
                            </th>
                          </tr>
                        </thead>
                           {thisProduct?.cart?.map((product: { _id: Key | null | undefined; device: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; quantity: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; price: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; }) => (
 <tbody key={product?._id}>
 <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'>
   <th
     scope='row'
     className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
   >
     {product?.device}
   </th>
   <td className='px-6 py-4'>
     {product?.quantity}
   </td>
   <td className='px-6 py-4'>
     {product?.price}
   </td>
 </tr>
</tbody>
                           ))

                           }
                       
                      </table>
                    </div>
                  </CardBody>
                </Card>
              )}
            </>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default OrderDetailsPage;

import { DeviceItemElementProps } from "../data/types";
import { IDevices } from "../models/app.model";
import { FaEye } from "react-icons/fa";


const remapDevices = (devices: Array<IDevices>): Array<Partial<any>> => {

  return devices
    .filter((x) => x.serialNumber !== null)
    .map<Partial<DeviceItemElementProps>>(
      (device: Partial<DeviceItemElementProps>) => ({
        _id: device._id,
        model: device.model,
        serialNumber: device.serialNumber,
        category: device.category?.name,
        distributionDate: device.distributionDate,
        // storeItemStatus: device.sold ? "sold" : "not sold",
        Buttons: [
          {
            text: FaEye,
            onClick: () => console.log("Button 1 clicked"),
            disabled: false,
            link: `/stock-details/:storeItemId`,
          },
        ],
      })
    );
};

export default {
  remapDevices,
};

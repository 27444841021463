import React from "react";
import { Typography } from "@material-tailwind/react";

const SuccessForm = () => {
  return (
    <div className=" items-center justify-center mt-11">
      <Typography variant="h1" className="text-green-900">
        {" "}
        Quality Check Complete
      </Typography>
    </div>
  );
};

export default SuccessForm;

import { Button, Card, CardHeader, Typography } from "@material-tailwind/react";
import JobCardTable from "../component/element/tables/JobCardTable";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";
import { useEffect, useRef } from "react";
import { getJobcards } from "../state/app.state";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";


const JobCardPage = () => {
  let filteredData: Array<any> = [];
  const componentToPrint = useRef(null);
  const dispatch = useDispatch();
  const { jobcards } = useSelector((reducer: IStore) => reducer.state);

  useEffect(() => {
    dispatch(getJobcards());
  }, []);
  const generatePDFtoPrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  const onRef = (ref: any) => (componentToPrint.current = ref);

  try {
    filteredData = jobcards.map((jobcard) => {
      return {
        jobcardNumber: jobcard.jobcardNumber,
        deviceName: jobcard.deviceId?.category,
        status: jobcard.status,
        progress: jobcard.progress,
        description: jobcard.description,
        requestType: jobcard.requestType,
        createdAt: jobcard.createdAt,
        deliveryType: jobcard.deliveryType,
      };
    });
  } catch (err) {}

  return (
    <div className="mt-12">
      <div className="mb-20 px-6">
        <div className="flex justify-end gap-10">
          <CSVLink data={filteredData} filename={"jobcards.csv"}>
            <Button variant="gradient" color="blue">
              Export CSV
            </Button>
          </CSVLink>
          <Button variant="outlined" color="blue" onClick={generatePDFtoPrint}>
            Print
          </Button>
        </div>
      </div>

      <Card shadow={false}>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-4">
          <Typography variant="h6" color="white">
            Job Cards
          </Typography>
        </CardHeader>
        <JobCardTable tableData={jobcards} tableRef={onRef} />
      </Card>
    </div>
  );
};

export default JobCardPage;

import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import DashboardPage from './Pages/DashboardPage';
import LandingPage from './Pages/LandingPage';
import NotFound from './Pages/NotFound';
import JobCardDetailsPage from './Pages/JobCardDetailsPage';
import QualityCheckPage from './Pages/QualityCheckFormPage';
import SettingPage from './Pages/SettingPage';
import LoginPage from './Pages/LoginPage';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';
import { IStore } from './state/app.reducer';
import { useDispatch, useSelector } from 'react-redux';
import OtpPage from './Pages/Otp';
import CustomerDetailsPage from './Pages/CustomerDetailsPage';
import StockDetails from './Pages/DeviceDetails';
import TechnicianDiagnosis from './Pages/TechnicianDiagnosis';
import AddJobCard from './component/element/jobcards/AddJobCard';
import AccessoryRequestDetails from './Pages/AccessoryRequestDetails';
import { useEffect } from 'react';
import $service from './services/app.service';
import { autoLogin } from './state/app.state';
import CustomerLandingPage from './Pages/CustomerLandingPage';
import 'react-toastify/dist/ReactToastify.css';
import ServiceCenterPage from './Pages/ServiceCenterPage';
import StockRequestDetails from './Pages/StockRequestDetails';
import InventoryDetailsPage from './Pages/InventoryDetailsPage';
import SaleCheckOut from './Pages/SaleCheckOut';
import OrderDetailsPage from './Pages/OrderDetailsPage';
import PartnerSalesDetails from './Pages/PartnerSalesDetails';
import SerialNumberPage from './Pages/SerialNumberPage';
import AddProductPage from './Pages/AddProductPage';
import ProductDetails from './Pages/ProductDetails';
import AdminDetailsPage from './Pages/AdminDetailsPage';
import WarrantyCheckerPage from './Pages/WarrantyCheckerPage';
import ShopDetails from './Pages/ShopDetails';


function App() {
  const dispatch = useDispatch();
  const { user, refreshToken } = useSelector(
    (reducer: IStore) => reducer.state
  );

  useEffect(() => {
    $service.setListener(subscription$);
  }, []);

  const subscription$ = (e: any) => {
    if (e.refreshToken) {
      dispatch(autoLogin());
    }
  };

  return user && user._id && refreshToken !== '' ? (
    <>
      <AddJobCard />
      <Routes>
        <Route path='/katcare-admin/*' element={<DashboardPage />} />
        <Route path='/settings/*' element={<SettingPage />} />
        <Route path='/not-user/*' element={<NotFound />} />
        <Route
          path='/customer-details/:customerId/*'
          element={<CustomerDetailsPage />}
        />
        <Route
          path='/jobcard-details/:_id/*'
          element={<JobCardDetailsPage />}
        />
        <Route path='/quality-check/:_id/*' element={<QualityCheckPage />} />
        <Route
          path='/technician-diagnosis/:_id/*'
          element={<TechnicianDiagnosis />}
        />
        <Route
          path='/stock-details/:storeItemId/*'
          element={<StockDetails />}
        />
        <Route
          path='/accessory-request-details/:_id/*'
          element={<AccessoryRequestDetails />}
        />
        <Route
          path='/stock-request-details/:_id/*'
          element={<StockRequestDetails />}
        />
        <Route
          path='/inventory-details/:_id/*'
          element={<InventoryDetailsPage />}
        />
        <Route path='/sell/*' element={<SaleCheckOut />} />
        <Route path='/order-details/:_id/*' element={<OrderDetailsPage />} />
        <Route
          path='/partner-sales-details/:_id/*'
          element={<PartnerSalesDetails />}
        />
        <Route path='*' element={<Navigate to='/katcare-admin' replace />} />
        <Route path='/serial-number/:_id/*' element={<SerialNumberPage />} />
        <Route path='/add-product/*' element={<AddProductPage />} />
        <Route path='/product-details/:_id/*' element={<ProductDetails />} />
        <Route path='/admin-details/:_id/*'  element={<AdminDetailsPage />} />
        <Route path='/warranty-checker/*' element={<WarrantyCheckerPage />} />
        <Route path='/shops-details/:_id/*' element={<ShopDetails />} />
      </Routes>
    </>
  ) : (
    <Routes>
      <Route path='/' element={<CustomerLandingPage />} />
      <Route path='/landing' element={<LandingPage />} />
      <Route path='/service-centers' element={<ServiceCenterPage />} />
      <Route path='/login' element={<LoginPage />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='*' element={<Navigate to='/landing' replace />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/otp' element={<OtpPage />} />
    </Routes>
  );
}

export default App;

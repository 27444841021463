import React from "react";
import { Select, Option, Typography } from "@material-tailwind/react";

interface IPowerForm {
  setFormValue: (k: string, v: string) => void;
}

const FactoryTestForm = ({ setFormValue }: IPowerForm) => {
  return (
    <div className="">
      <Typography variant="lead" className="my-1">
        {" "}
        Factory test{" "}
      </Typography>
      <div className="my-5 w-screen max-w-screen-md">
        <Typography variant="lead" className="my-1 text-md font-bold">
          keyboard:
        </Typography>
        <div className="flex gap-5 my-2 ">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            {" "}
            keyboard function test{" "}
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("KeyboardFunctionTest", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          Touch pad
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            {" "}
            test to check if touch pad is working or has rough surface{" "}
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue(
                "TestToCheckIfTouchPadIsWorkingOrHasRoughSurface",
                e
              );
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          Mouse
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            check (mouse)right or left click{" "}
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("CheckMouseRightOrLeftClick", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          Speaker
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            check speaker if sound is clear for hearing{" "}
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("CheckSpeakerIfSoundIsClearForHearing", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          Mircophone
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            check microphone by recording a voice message to ensure clarity{" "}
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue(
                "CheckMicrophoneByRecordingAVoiceMessageToEnsureClarity",
                e
              );
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          LCD
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            check lcd colours
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue("CheckLcdColours", e);
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>

      <div className="my-5">
        <Typography variant="lead" className="my-1 text-md font-bold">
          Camera
        </Typography>
        <div className="flex gap-5 my-2">
          <Typography variant="small" className="text-gray-700 text-sm w-full">
            Camera test: no abnormal display after enter preview screen
          </Typography>
          <Select
            label="Defects Severity"
            onChange={(e: any) => {
              setFormValue(
                "CameraTestNoAbnormalDisplayAfterEnterPreviewScreen",
                e
              );
            }}
          >
            <Option value="ACC">ACC</Option>
            <Option value="Min">Min</Option>
            <Option value="Maj">Maj</Option>
            <Option value="Cri">Cri</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default FactoryTestForm;

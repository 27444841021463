const formatMetrics = (metrics: { [x: string]: Array<{ total?: number }> }) => {
    let mtx: any = {};
    metrics = Array.isArray(metrics) ? metrics[0] : metrics;
    Object.keys(metrics).map((k: string) => {
      const m = metrics[k];
      if (Array.isArray(m) && m.length > 0 && m[0].total) {
        mtx[k] = m[0].total;
      } else {
        mtx[k] = 0;
      }
    });
    return mtx;
  };
  
  export default { formatMetrics };
  
import { Fragment, useEffect, useState } from "react";
import {
  Button,
  DialogFooter,
  DialogHeader,
  Input,
  Typography,
} from "@material-tailwind/react";
import { useMaterialTailwindController } from "../../../context";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import $service from "../../../services/app.service";
import { IAdmin } from "../../../models/app.model";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../state/app.reducer";
import { Dialog, DialogContent} from "@mui/material";
import {  getAdmins } from "../../../state/app.state";
import regions from "../../../services/region.service";

const CreateOfficer = () => {
  const { adminRoles }=useSelector((reducer: IStore)=>reducer.state);
  const [loading, setLoading] = useState<boolean>(false);
  const { controller, discharge } = useMaterialTailwindController();
  const { openModal } = controller;
  const dispatch = useDispatch();
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [selectedDistrict,setSelectedDistrict] = useState<any>(null);

  const onSubmit = async (data: Partial<IAdmin>) => {
    try {
      setLoading(true);
      const officer = {
        ...data,
      };
      const response = await $service.addAdmin(officer);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      discharge({ type: "OPEN_MODAL", openModal: false })
      toast("Admin account added successfully");
      dispatch(getAdmins());
    } catch (err) {
      toast((err as Error)?.message);
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<IAdmin>>();
  const handleOpen = () => discharge({ type: "OPEN_MODAL", openModal: false });

  return (
    <Fragment>
      <ToastContainer />
      <Dialog open={openModal} onClose={handleOpen}>
        <DialogHeader className="text-lg">Register Admin Managers</DialogHeader>
        <DialogContent sx={{ width: "500px" }}>
          <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  First Name
                </Typography>
                <Input
                  type="text"
                  {...register("first_name", { required: true })}
                />
                {errors.first_name && (
                  <span className="text-red-500">This field is required</span>
                )}
              </div>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                Middle Name <span className="text-gray-600 text-xs">(Optional)*</span>
                </Typography>
                <Input
                  type="text"
                  {...register("middle_name", )}
                />
                {errors.first_name && (
                  <span className="text-red-500">This field is not required</span>
                )}
              </div>

              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  Last Name
                </Typography>
                <Input
                  type="text"
                  {...register("last_name", { required: true })}
                />
                {errors.last_name && (
                  <span className="text-red-500">This field is required</span>
                )}
              </div>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  Company Name
                </Typography>
                <Input
                  type="text"
                  {...register("company_name", )}
                />
                {errors.company_name && (
                  <span className="text-red-500">This field is required</span>
                )}
              </div>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  Email Address
                </Typography>
                <Input
                  type="email"
                  {...register("email", {
                    required: true,
                  })}
                />
                {errors.email && (
                  <span className="text-red-500">This field is required</span>
                )}
              </div>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  Phone Number
                </Typography>
                <Input
                  type="tel"
                  {...register("mobile", {
                    required: true,
                  })}
                />
                {errors.mobile && (
                  <span className="text-red-500">This field is required & must 10 figures </span>
                )}
              </div>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                  Digital Address
                </Typography>
                <Input
                  type="text"
                  {...register("digital_address", {
                    required: true,
                  })}
                />
                {errors.digital_address && (
                  <span className="text-red-500">
                    This field is required
                  </span>
                )}
              </div>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                Latitude <span className="text-gray-600 text-xs">(Optional for KAT officers)*</span>
                </Typography>
                <Input
                  type="text"
                  {...register("lat")}
                />
                {errors.digital_address && (
                  <span className="text-red-500">
                    This field is required
                  </span>
                )}
              </div>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                Longitude <span className="text-gray-600 text-xs">(Optional for KAT officers)*</span>
                </Typography>
                <Input
                  type="text"
                  {...register("lng")}
                />
                {errors.digital_address && (
                  <span className="text-red-500">
                    This field is required
                  </span>
                )}
              </div>
              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                Location Address
                </Typography>
                <Input
                  type="text"
                  {...register("locationAddress")}
                />
                {errors.digital_address && (
                  <span className="text-red-500">
                    This field is required
                  </span>
                )}
              </div>       

              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                Region
                </Typography>
                <select
                id='default'
                {...register("region", {
                  required: true,
                })}
                value={selectedRegion}
                onChange={(e) => {
                  const region = e.target.value;
                  setSelectedRegion(region);
                  setSelectedDistrict(null);
                }}
                className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
              >
                {Object.keys(regions).map((region) => (
                  <option key={region} value={region}>
                    {' '}
                    {region}
                  </option>
                ))}
              </select>
                {/* <Input
                  type="text"
                  {...register("region", {
                    required: true,
                  })}
                /> */}
                {errors.region && (
                  <span className="text-red-500">
                    This field is required
                  </span>
                )}
              </div>

              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                District
                </Typography>
                <select
  value={selectedDistrict}
  id='default'    {...register("district", {
    required: true,
  })}
  onChange={(e) => setSelectedDistrict(e.target.value)}
  className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
>
  {regions[selectedRegion]?.map((district: any) => (
    <option key={district} value={district}>
      {district}
    </option>
  ))}
</select>
                {/* <Input
                  type="text"
                  {...register("district", {
                    required: true,
                  })}
                /> */}
                {errors.district && (
                  <span className="text-red-500">
                    This field is required
                  </span>
                )}
              </div>

              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                Certified
                </Typography>
                <select  id="" {...register("certified")} className="block py-2.5 w-full p-2 mb-6 text-sm text-gray-900 border border-gray-400 rounded-lg bg-gray-50 ">
                  <option value={"true"}>True</option>
                  <option value={"false"}>False</option>
                </select>
                {errors.region && (
                  <span className="text-red-500">
                    This field is required
                  </span>
                )}
              </div>

              <div className="mb-2">
                <Typography className="text-sm font-semibold">
                Role
                </Typography>
                <select  id="" {...register("role")} className="block py-2.5 w-full p-2 mb-6 text-sm text-gray-900 border border-gray-400 rounded-lg bg-gray-50 ">
                  {adminRoles?.map(a=>(<option value={a._id}>{a.name}</option>))}
                </select>
                {errors.role && (
                  <span className="text-red-500">
                    This field is required
                  </span>
                )}
              </div>

              <Button
                type="submit"
                variant="filled"
                color="blue"
                className="w-full mt-2"
              >
                {loading ? "Loading" : "Create Admin Role"}
              </Button>
            </form>
          </Fragment>
        </DialogContent>

        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            Cancel
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default CreateOfficer;

import { Link, useNavigate } from "react-router-dom";
import adminHomeImage from "../Assets/images/kat/IMG_4229.jpg";
import { Spinner } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { autoLogin } from "../state/app.state";
import { IStore } from "../state/app.reducer";
import { Bars3Icon } from "@heroicons/react/24/solid";
import KatCareLogo from "../Assets/images/KATCare-logo.png"

const LandingPage = () => {
  const naviate = useNavigate();
  const dispatch = useDispatch();
  const {loading}=useSelector((reducer:IStore)=>reducer.state);
  const [openNav, setOpenNav] = useState(false);

  const onClick = () => {
    naviate("/login");
  };

  useEffect(() => {
    dispatch(autoLogin());
  }, []);

  return (
    <div className='main'>
      <div className="relative max-w-full min-h-screen overflow-y-hidden">
        <header
          className={`  bg-transparent fixed w-full z-10 transition-colors duration-300 `}
        >
          <nav className={`border-gray-200 px-4 lg:px-6 py-4 `}>
            <div className="flex flex-wrap justify-between items-center ">
              <Link to="/" className="flex items-center">
                <img src={KatCareLogo} className=" h-16 conver sm:h-20" alt="Katcare Logo" />
              </Link>
              <div className="flex gap-4 items-center lg:order-2">
                {/* <span
                   onClick={onClick}
                  className={` block py-2 pr-4 pl-3 mx-5  text-lg font-medium hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0`}
                >
              { loading ? <Spinner />: <span>Login</span>}
                </span> */}
                <button
                  onClick={() => setOpenNav(!openNav)}
                  data-collapse-toggle="mobile-menu-2"
                  type="button"
                  className={` inline-flex items-center p-2 ml-1 text-lg rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200`}
                  aria-controls="mobile-menu-2"
                  aria-expanded="false"
                >
                  <Bars3Icon className="h-7 w-7" aria-hidden="true" />
                </button>
              </div>
            </div>
          </nav>
        </header>
        <section className="relative pt-48 h-screen md:pt-0">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="px-10  lg:px-24  mr-auto place-self-center">
              <h1 className="max-w-2xl  mb-8 text-4xl font-bold leading-10 md:text-5xl xl:text-6xl md:leading-[6rem]">
              Welcome to the Katcare Dashboard
              </h1>
              <p className="max-w-2xl  mb-8 font-medium text-gray-500 leading-8 lg:mb-8 md:text-lg lg:text-xl">
              In order to access the platform, kindly log in with your login information.
              </p>

              <button
                onClick={onClick}
                className="text-white  bg-blue-700 hover:bg-primary-800  font-medium rounded-lg text-md px-4 lg:px-8 py-2.5 lg:py-3 mr-2 "
              >
            { loading ? <Spinner />: <span>Login</span>}
              </button>
            </div>
            <div className="bg-gray-900 h-screen relative hidden md:flex">
              <div className="bg-gray-900 h-screen absolute top-0 sm:hidden"></div>
              <img
                src={adminHomeImage}
                alt="mockup"
                className="h-screen w-full object-cover"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LandingPage;

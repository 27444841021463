import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  Progress,
} from '@material-tailwind/react';
import Avatar from 'react-avatar';
import { useMaterialTailwindController } from '../context';
import AddDevicetoCustomerModal from '../component/element/customs/AddDevicetoCustomerModal';
import DetailPageSkeleton from '../component/layout/DetailPageSkeleton';
import { NoSymbolIcon } from '@heroicons/react/24/solid';
import {
  ICustomer,
  ICustomerDevices,
  IJobcards,
  IWarranty,
} from '../models/app.model';
import $service from '../services/app.service';
import { useSelector } from 'react-redux';
import WithFunctionGuard from '../guards/FunctionGuard';
import DashboardLayout from '../component/layout/DashboardLayout';
import { toast } from 'react-toastify';

const CustomerDetailsPage = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { discharge } = useMaterialTailwindController();
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [devices, setDevices] = useState<ICustomerDevices[] | any>([]);
  const [jobcards, setJobcards] = useState<IJobcards[]>([]);
  const [warranty, setWarranties] = useState<IWarranty[]>([]);
  const { categorys } = useSelector((reducer: any) => reducer.state);
  const { customerId } = useParams<string>();


  const foundCategory =categorys.find((d: any) => d.id === devices?.deviceId?.category);


  useEffect(() => {
    getCustomer();
  }, []);

  const getCustomer = async (): Promise<void> => {
    try {
      if (!customerId || customerId === '') return;
      setLoading(true);
      const response = await $service.getCustomer!(customerId);
      if (!response.success) {
        throw Error(response.message);
      }
      const c = response.data as ICustomer;
      setLoading(false);
      setCustomer(c);
      const [devices, jobcards, warranties] = await Promise.all([
        $service.getCustomerDevices!(c._id),
        $service.getCustomerJobcards!(c._id),
        $service.warrantyCards!(c._id),
      ]);
      switch (true) {
        //@ts-ignore
        case devices.success:
          setDevices(devices.data);
        //@ts-ignore
        case jobcards.success:
          setJobcards(jobcards.data);
        case warranties.success:
          setWarranties(warranties.data);
          break;
        default:
          break;
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const suspend = async (blocked: boolean = true) => {
    try {
      const data = { id: customerId!, blocked };
      setLoading(true);
      const response = await $service.suspend(data);
      if (!response.success) {
        throw Error(response.message);
      }
      getCustomer();
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const AddDeviceButton = WithFunctionGuard(() => (
    <Button
      color='green'
      onClick={() =>
        discharge({
          type: 'CUSTOMER_MODAL',
          customerdeviceModa: true,
        })
      }
      key={'add-device-customer'}
    >
      {' '}
      Add Device to Customer{' '}
    </Button>
  ));

  const SuspendCustomerButton = WithFunctionGuard(() => (
    <Button
      className={`${customer?.blocked ? 'bg-red-800' : 'bg-green-800'}`}
      onClick={() => suspend(!customer?.blocked!)}
      key={'suspend-customer'}
    >
      Account Suspension
    </Button>
  ));

  return (
    <DashboardLayout>
      <div className='my-12'>
        <AddDevicetoCustomerModal />

        {/* <DisabledModal
            isEnabled={isItEnabled}
            openTheModal={openTheModal}
            customerId={user?.customerId}
            setTheOpenModal={setOpenTheModal}
            email={user?.email}
            setChipValue={setChipValue}
            chipValue={chipValue}
            setIsEnabled={setIsEnabled}
          /> */}
        {isLoading ? (
          <DetailPageSkeleton />
        ) : (
          <>
            {/* Personal Information */}
            <Card
              className='w-full h-56 relative mb-24'
              color='blue'
              variant='gradient'
              key={customer?._id}
            >
              <CardHeader className='h-32 relative left-0 top-40'>
                <div className='flex flex-row justify-between p-3 items-center'>
                  <div className='flex flex-row gap-5 items-center'>
                    <div className=''>
                      <Avatar
                        name={`${customer?.firstName} ${customer?.lastName}`}
                        size='100'
                        round={true}
                      />
                    </div>
                    <div className='mt-3'>
                      <Typography
                        variant='h5'
                        color='blue-gray'
                        className='mb-2 '
                      >
                        {customer?.firstName} {customer?.lastName}
                      </Typography>
                    </div>
                  </div>
                  <div className='flex justify-between gap-3'>
                    <AddDeviceButton />
                    {/* <Button color="purple"> Add Warranty Card </Button> */}

                    {/* <SuspendCustomerButton /> */}
                  </div>
                </div>
              </CardHeader>
            </Card>

            <div className='grid grid-cols-2 my-8 gap-8'>
              {/* Personal Information */}
              <Card className='w-full' shadow={false}>
                <CardBody>
                  <div>
                    <div className='flex justify-between'>
                      <Typography
                        variant='h5'
                        className='text-xl font-bold leading-none mb-5 text-gray-900 dark:text-white'
                      >
                        Personal Information
                      </Typography>
                      <Chip
                        value={customer?.blocked ? 'not active' : 'active'}
                        color={customer?.blocked ? 'red' : 'green'}
                        className='h-8'
                      />
                    </div>
                    <ul className='list-none'>
                      <li className='py-3 sm:py-4'>
                        <div className='grid grid-cols-2'>
                          <h2 className='text-gray-500 truncate '>
                            First Name:
                          </h2>
                          <p className='inline-flex items-center text-sm font-semibold text-gray-900'>
                            {customer?.firstName}
                          </p>
                        </div>
                      </li>

                      <li className='py-3 sm:py-4 grid grid-cols-2'>
                        <h2 className='text-gray-500 truncate '>Last Name:</h2>
                        <p className='inline-flex items-center text-sm font-semibold text-gray-900'>
                          {customer?.lastName}
                        </p>
                      </li>
                      <li className='py-3 sm:py-4 grid grid-cols-2 '>
                        <h2 className='text-gray-500 truncate '>Email:</h2>
                        <p className=' items-center text-sm font-semibold text-gray-900  truncate '>
                          {customer?.email}
                        </p>
                      </li>
                      <li className='py-3 sm:py-4 grid grid-cols-2'>
                        <h2 className='text-gray-500 truncate '>
                          Phone Number:
                        </h2>
                        <p className='inline-flex items-center text-sm font-semibold text-gray-900'>
                          {customer?.phone}
                        </p>
                      </li>
                      <li className='py-3 sm:py-4  grid grid-cols-2'>
                        <h2 className='text-gray-500 truncate '>Region:</h2>
                        <p className='inline-flex items-center text-sm font-semibold text-gray-900'>
                          {customer?.address}
                        </p>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
              {/* Customer Device */}
              <>
                {devices.length === 0 ? (
                  <div className='flex flex-col items-center justify-center'>
                    {/* <img src={noData} alt="not available" className="w-36" /> */}
                    <p className='text-lg mt-4 text-gray-600'>
                      No customer device available
                    </p>
                  </div>
                ) : (
                  <Card shadow={false}>
                    <CardBody>
                      <div className=''>
                        <div className='flex items-center justify-between mb-4'>
                          <h5 className='text-lg font-bold leading-none text-gray-900 dark:text-white'>
                            Customer Devices
                          </h5>
                          {/* <span
                              className="text-sm font-medium text-blue-600 hover:underline"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setViewAll((viewAll) => !viewAll);
                              }}
                            >
                              View all
                            </span> */}
                        </div>
                        <div className='flow-root '>
                          <ul className='max-w-md divide-y-3 divide-gray-500 dark:divide-gray-700'>
                            {devices.map((device : ICustomerDevices) => (
                              <li
                                className='py-3 sm:py-4  border-b-2 border-gray-200'
                                key={device?._id}
                              >
                                <div className='flex flex-col  items-start  '>
                                  {/* DEVICE NAME  */}

                                  <div className='flex gap-5 min-w-0 my-2'>
                                    <p className='text-md font-semibold text-gray-800 truncate dark:text-white'>
                                      Device Name:
                                    </p>
                                    <p className='text-md  text-gray-500 truncate dark:text-gray-400'>
                                      {device?.deviceId?.category}
                                    </p>
                                  </div>

                                  {/* SERIAL NUMBER  */}

                                  <div className='flex gap-5 min-w-0 my-2'>
                                    <p className='text-md font-semibold text-gray-800 truncate dark:text-white'>
                                      Serial Number:
                                    </p>
                                    <p className='text-md  text-gray-500 truncate dark:text-gray-400'>
                                      {device.deviceId?.serialNumber}
                                    </p>
                                  </div>

                                  <div className='flex gap-5 min-w-0 my-2'>
                                    <p className='text-md font-semibold text-gray-800 truncate dark:text-white'>
                                      Device Type:
                                    </p>
                                    <p className='text-md  text-gray-500 truncate dark:text-gray-400'>
                                      {device.deviceId?.model}
                                    </p>
                                  </div>

                                  {/* PRICE */}

                                  <div className='flex gap-5 min-w-0 my-2'>
                                    <p className='text-md font-semibold text-gray-800 truncate dark:text-white'>
                                      Year:
                                    </p>
                                    <p className='text-md  text-gray-500 truncate dark:text-gray-400'>
                                      {device?.deviceId?.year}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </>
            </div>
            {/* Customer Job Cards */}
            <>
              {jobcards.length === 0 ? (
                <div className='flex flex-col items-center py-4 justify-center'>
                  <NoSymbolIcon className='w-8 h-8 text-gray-600' />
                  <p className='text-lg mt-4 text-gray-600'>
                    No customer job cards available
                  </p>
                </div>
              ) : (
                <Card shadow={false}>
                  <CardBody>
                    <div className='py-2 pb-3'>
                      <h5 className='font-bold text-lg  text-gray-900'>
                        Customer Job Cards
                      </h5>
                    </div>
                    <div className='relative overflow-x-auto shadow-sm sm:rounded-lg'>
                      <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
                        <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                          <tr>
                            <th scope='col' className='px-6 py-3'>
                              Job Card Name
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Device Progress
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Status
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Created At
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              <span className='sr-only'>Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobcards.map((jc) => (
                            <tr className='bg-white border-b' key={jc?._id}>
                              <th
                                scope='row'
                                className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap'
                              >
                                #{jc?.jobcardNumber}
                              </th>
                              <td className='px-6 py-4'>
                                <Progress
                                  value={jc.progress}
                                  color={jc.progress > 50 ? 'green' : 'red'}
                                />
                              </td>
                              <td className='px-6 py-4 text-teal-600'>
                                {jc.status}
                              </td>
                              <td className='px-6 py-4'>
                                {new Date(jc?.createdAt).toDateString()}
                              </td>
                              {/* <td px-6 py-4 text-right>
                                {jc && (
                                  <Link
                                    to={`/jobcard-details/${jc?._id}`}
                                    className='font-medium text-blue-600 dark:text-blue-500 hover:underline'
                                  >
                                    View
                                  </Link>
                                )}
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              )}
            </>

            {/* Warranty Card */}
            {/* <>
              {warranty.length === 0 ? (
                <div className='flex flex-col items-center py-4 justify-center'>
                  <NoSymbolIcon className='w-8 h-8 text-gray-600' />
                  <div className='text-lg mt-4 text-gray-600'>
                    No customer warranty card available
                  </div>
                </div>
              ) : (
                <Card className='my-5' shadow={false}>
                  <CardBody>
                    <div className='py-2 pb-3'>
                      <h5 className='font-bold text-lg  text-gray-900'>
                        Customer Warranty Cards
                      </h5>
                    </div>
                    <div className='relative overflow-x-auto shadow-sm  sm:rounded-lg'>
                      <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
                        <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                          <tr>
                            <th scope='col' className='px-6 py-3'>
                              Model Number
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Date Initiated
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Date Expiring
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Years
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Address
                            </th>
                            <th scope='col' className='px-6 py-3'>
                              Contact Number
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              )}
            </> */}
          </>
        )}
      </div>
    </DashboardLayout>
  );
};
export default CustomerDetailsPage;

import {
  Button,
  DialogFooter,
  DialogHeader,
  Input,
  Textarea,
  Typography,
} from '@material-tailwind/react';
import React, { Fragment, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useMaterialTailwindController } from '../../../context';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { IStore } from '../../../state/app.reducer';
import $service from '../../../services/app.service';
import { toast } from 'react-toastify';

interface IForm extends Partial<ICreateAccessoryRequest> {
  quantity: number;
  accessoryId: string;
}

interface ICreateAccessoryRequest {
  jobcardId: string;
  reason: string;
  adminId: string;
  accessories: Array<{
    quantity: number;
    accessoryId: any;
  }>;
}

function CreateAccessoryRequest({ loadCategory }: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [accessory, setAccessory] = useState<any>([]);
  const [selected, setSelected] = useState<string>('');
  const { discharge, controller } = useMaterialTailwindController();
  const { accessoryModal } = controller;
  const { jobcards, user, categorys } = useSelector(
    (reducer: IStore) => reducer.state
  );
  const [form, setForm] = useState<any>({
    adminId: '',
    jobcardId: '',
    reason: '',
   quantity: 0,
   accessoryId: '',
  });

  useEffect(() => {
    getAccessoryId(selected);
  }, [selected]);

  useEffect(() => {
    loadCategory();
  }, []);

  const getAccessoryId = async (selected: any) => {
    try {
      const response = await $service.accessoryListById(selected);
      if (!response.success) {
        throw new Error(response.message);
      }

      const a = response.data;
      setLoading(false);
      setAccessory(a);
    } catch (err) {
      setLoading(false);
    }
  };

  const setFormValue = (key: string, value: string | number) =>
    setForm({ ...form, [key]: value });
    
    const setAccessoryFormValue = (key: string, value: any) => {
      let acc = form.accessories;
      acc[0] = { ...acc[0], [key]: value?.id || '' };
      setForm({ ...form, accessories: acc });
    };

    

  const handleOpen = () =>
    discharge({ type: 'ACCESSORY_MODAL', accessoryModal: false });

  const submit = async () => {
    try {
      const { reason, jobcardId,  quantity, accessoryId } = form;
      // if (accessories.length <= 0) {
      //   throw Error(' Please choose an accessory and quantity ');
      // }
      if (reason === '' || jobcardId === '' || quantity === 0 || accessoryId === '') {
        throw Error('please complete form');
      }

      const data: any = {
        adminId: user._id!,
        jobcardId: jobcardId!,
        reason: reason!,
        accessories: [{ quantity: quantity!, accessoryId: accessoryId! }],
      };
      setLoading(true);
      const response = await $service.newAccessoryRequest!(data);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast('Request created successfully');
      discharge({ type: 'ACCESSORY_MODAL', accessoryModal: false });
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  return (
    <Dialog
      open={accessoryModal}
      onClose={handleOpen}
      className=' relative w-screen'
      sx={{ width: '100%' }}
    >
      <DialogHeader className='text-lg'>Accessory Request</DialogHeader>
      <DialogContent sx={{ width: '500px' }}>
        <Fragment>
          <form>
            <div className='mb-2 '>
              <Typography className='text-sm font-semibold'>
                Select device
              </Typography>
              <select
              defaultValue=''
                value={selected}
                onChange={(e) => {
                  const b = e.target.value;
                  setSelected(b);
                }}
                className='bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
              >
                <option value=''></option>
                {categorys.map((category: any) => (
                  <option value={category?._id}>{category?.name}</option>
                ))}
              </select>
            </div>
            <div className='mb-2 '>
              <Typography className='text-sm font-semibold'>
                Select Accessory
              </Typography>
              <Autocomplete
                  // multiple
                id='tags-standard'
                sx={{ width: '100%' }}
                options={accessory?.map((d: any) => ({
                  label: `${d?.name} GHS${d?.price} `,
                  id: d?._id,
                }))}
                onChange={(_, v: any) => setFormValue('accessoryId',  v!.id)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div className='mb-2'>
              <Typography className='text-sm font-semibold'>
                Quantity
              </Typography>
              <Input
                onChange={(e) =>
                  setFormValue('quantity', parseInt(e.target.value))
                }
              />
            </div>
            <div className='mb-2 '>
              <Typography className='text-sm font-semibold'>
                Select jobcard
              </Typography>
              <Autocomplete
                disablePortal
                id='combo-box-demo'
                sx={{ width: '100%' }}
                options={jobcards?.map((jc: any) => ({
                  label: `#${jc.jobcardNumber} ${jc.customerId.firstName} ${
                    jc.customerId.lastName
                  } ${new Date(jc.createdAt).toDateString()}  `,
                  id: jc._id,
                }))}
                onChange={(_, v) => setFormValue('jobcardId', v!.id)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div className='mb-2'>
              <Typography className='text-sm font-semibold'>
                Reason for accessory
              </Typography>
              <Textarea
                onChange={(e) => setFormValue('reason', e.target.value.trim())}
              />
            </div>
            <Button
              variant='filled'
              color='blue'
              className='w-full mt-2'
              onClick={submit}
            >
              Create Request
            </Button>
          </form>
        </Fragment>
      </DialogContent>

      <DialogFooter>
        <Button
          variant='text'
          color='red'
          className='mr-1'
          onClick={handleOpen}
        >
          Cancel
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default CreateAccessoryRequest;

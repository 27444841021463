import { Fragment, useState } from "react";
import {
  Button,
  DialogFooter,
  Typography,
  Textarea,
  DialogHeader
} from "@material-tailwind/react";
import { useMaterialTailwindController } from "../../../context";
import { useSelector } from "react-redux";
import { IStore } from "../../../state/app.reducer";
import { useParams } from "react-router-dom";
import $service from "../../../services/app.service";
import { toast } from "react-toastify";
import { Dialog, DialogContent } from "@mui/material";

const RejectModal = ({ opened, setOpened }: any) => {
  const { _id } = useParams<string>();
  const { controller, discharge } = useMaterialTailwindController();
  const { rejectModal } = controller;
  const [remarks, setRemarks] = useState<string>("");
  const { user } = useSelector((reducer: IStore) => reducer.state);
  const [loading, setLoading] = useState<boolean>(false);

  const submit = async () => {
    try {
      if (remarks.length <= 0 || remarks === "") {
        throw Error("please enter remarks");
      }
      const payload: any = {
        jobcardId: _id,
        approver: user._id,
        remarks,
      };
      setLoading(true);
      const response = await $service.addDiagnosis(payload);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      toast(response.message);
      handleOpen();
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const handleOpen = () =>
    discharge({ type: "REJECT_MODAL", rejectModal: false });

  return (
    <Fragment>
      <Dialog open={rejectModal} onClose={handleOpen}>
        <DialogContent>
          <>
            <form>
              <div className="mb-2 ">
                <div className="mb-3">
                <Typography className="text-md font-semibold">
                  Record the repaired item's quality control remarks.
                </Typography>
                </div>
                <Textarea
                  disabled={loading}
                  onChange={(e) => setRemarks(e.target.value)}
                  name=""
                  label="reason"
                />
              </div>
              <Button
                disabled={loading}
                variant="filled"
                color="blue"
                className="w-full mt-2"
                onClick={submit}
              >
                Message
              </Button>
            </form>
          </>
        </DialogContent>

        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            Cancel
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default RejectModal;

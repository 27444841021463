import React from 'react';
import { Option, Typography, Select } from '@material-tailwind/react';

interface IPowerForm {
  setFormValue: (k: string, v: string) => void;
}

const PowerONForm = ({ setFormValue }: IPowerForm) => {
  return (
    <div className=''>
      <Typography variant='lead' className='my-1'>
        Power ON
      </Typography>
      <div className='my-5 w-screen max-w-screen-md'>
        <Typography variant='lead' className='my-1 text-md font-bold'>
          LCD LCD display:
        </Typography>
        <div className='flex gap-5 my-2'>
          <Typography variant='small' className='text-gray-700 text-sm w-full'>
            {' '}
            Check whether LCD display normal, <br />
            it is not acceptable with unstable display, line on screen, etc.{' '}
          </Typography>
          <Select
            label='Defects Severity'
            onChange={(e: any) => {
              setFormValue('PowerOnCheckWhetherLCDDisplayIsNormal', e);
            }}
          >
            <Option value='ACC'>ACC</Option>
            <Option value='Min'>Min</Option>
            <Option value='Maj'>Maj</Option>
            <Option value='Cri'>Cri</Option>
          </Select>
        </div>
        <div className='flex gap-5 my-2'>
          <Typography variant='small' className='text-gray-700 text-sm w-full'>
            {' '}
            The logo when power on and animation must display{' '}
          </Typography>
          <Select
            label='Defects Severity'
            onChange={(e: any) => {
              setFormValue('TheLogoWhenPowerOnAndAnimationMustDisplay', e);
            }}
          >
            <Option value='ACC'>ACC</Option>
            <Option value='Min'>Min</Option>
            <Option value='Maj'>Maj</Option>
            <Option value='Cri'>Cri</Option>
          </Select>
        </div>
        <div className='flex gap-5 my-2'>
          <Typography variant='small' className='text-gray-700 text-sm w-full'>
            The LCD display contrast quality is bad and uneven brightness of
            base color{' '}
          </Typography>
          <Select
            label='Defects Severity'
            onChange={(e: any) => {
              setFormValue('TheLCDDisplayContrastQualityIsBad', e);
            }}
          >
            <Option value='ACC'>ACC</Option>
            <Option value='Min'>Min</Option>
            <Option value='Maj'>Maj</Option>
            <Option value='Cri'>Cri</Option>
          </Select>
        </div>
      </div>
      <div className='my-5'>
        <Typography variant='lead' className='my-1 text-md  font-bold'>
          Fliping:
        </Typography>
        <div className='flex gap-3 my-2'>
          <Typography variant='small' className='text-gray-700 text-sm w-full'>
            {' '}
            check flip by fliping three times{' '}
          </Typography>
          <Select
            label='Defects Severity'
            onChange={(e: any) => {
              setFormValue('CheckFlipByFlipingThreeTimes', e);
            }}
          >
            <Option value='ACC'>ACC</Option>
            <Option value='Min'>Min</Option>
            <Option value='Maj'>Maj</Option>
            <Option value='Cri'>Cri</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default PowerONForm;

import { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import BgImage from "../Assets/images/undraw_safe_re_kiil.svg";

export default function NotFound() {
  const [openNav, setOpenNav] = useState(false);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
  return (
    <div className="">
      <Navbar className="mx-auto max-w-screen-xl py-2 px-4 lg:px-8 lg:py-4">
        <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
          <Typography
            as="a"
            href="#"
            variant="small"
            className="mr-4 cursor-pointer py-1.5 font-normal"
          >
            <span>Katcare</span>
          </Typography>
          <Button
            variant="gradient"
            size="sm"
            className="hidden lg:inline-block"
          >
            <span>Logout</span>
          </Button>
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
        <MobileNav open={openNav}>
          <div className="container mx-auto">
            <Button variant="gradient" size="sm" fullWidth className="mb-2">
              <span>Login </span>
            </Button>
          </div>
        </MobileNav>
      </Navbar>
      <div className="mt-12 max-w-auto max-w-screen-auto  p-10 px-20">
        <div className="grid grid-cols-2 content-center gap-5">
          <div className="px-3 mt-20">
            <Typography
              variant="h2"
              color="red"
              className="text-6xl uppercase font-bold "
            >
              User Not Found
            </Typography>
            <Typography
              variant="small"
              className="text-xl mt-4 text-gray-500 font-semibold"
            >
              We advise you to visit the login page and use your role
              credentials to log in.
            </Typography>
          </div>
          <div className="w-full">
            <img src={BgImage} className="w-full h-full" alt="secure" />
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect, Fragment, useState, Ref, useRef } from "react";
import CustomSkeleton from "../component/layout/CustomSkeleton";
import {
  Card,
  CardHeader,
  Button,
  Typography,
  CardBody,
} from "@material-tailwind/react";
import { useMaterialTailwindController } from "../context";
import CustomerTable from "../component/element/tables/CustomerTable";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";
import { customersTableDataSelector, getCustomers } from "../state/app.state";
import { useReactToPrint } from "react-to-print";
import AddCustomerModal from "../component/element/customs/AddCustomerModal";
import WithFunctionGuard from "../guards/FunctionGuard";

const CustomerScreen = () => {
  let filteredData:Array<any> = [];
  const dispatch = useDispatch();
  const state = useSelector((reducer: IStore) => reducer.state);
  const customers = customersTableDataSelector(state);
  const componentToPrint = useRef(null);
  const { discharge } = useMaterialTailwindController();

  const generatePDFtoPrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  const onRef = (ref: any) => (componentToPrint.current = ref);

   try { 
    filteredData = customers?.map((customer) => {
    return {
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      phone: customer.phone,
      region: customer.address,
    };
  }) } catch (err) {}

  const AddCustomerButton = WithFunctionGuard(() => (
    <Button
      variant="gradient"
      color="blue"
      key="add-customer"
      onClick={() => discharge({ type: "OPEN_MODAL", openModal: true })}
    >
      Add Customers
    </Button>
  ));

  return (
    <Fragment>
      <div className="mt-12">
        <AddCustomerModal />

        <div>
          {state.loading ? (
            <CustomSkeleton />
          ) : (
            <>
              <div className="mt-12">
                <div className="mb-20 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
                  <div> </div>
                  <div className="flex justify-end gap-10">
                    <AddCustomerButton />
                    {/* <Button
                      variant="gradient"
                      color="blue"
                      key="add-customer"
                      onClick={() =>
                        discharge({ type: "OPEN_MODAL", openModal: true })
                      }
                    >
                      Add Customers
                    </Button> */}
                    <CSVLink data={filteredData} filename={"customers.csv"}>
                      <Button variant="gradient" color="blue">
                        Export CSV
                      </Button>
                    </CSVLink>
                    <Button
                      variant="outlined"
                      color="blue"
                      onClick={generatePDFtoPrint}
                    >
                      Print
                    </Button>
                  </div>
                </div>
                <>
                  {/* <CustomerUpdateModal
                    refetch={()=>{}}
                    customerId={"customerId"}
                    firstName={""}
                  /> */}
                </>

                <Card shadow={false}>
                  <CardHeader
                    variant="gradient"
                    color="blue"
                    className="mb-8 p-4"
                  >
                    <Typography variant="h6" color="white">
                      All Customers
                    </Typography>
                  </CardHeader>
                  <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                    {customers && customers.length > 0 ? (
                      <CustomerTable tableData={customers} tableRef={onRef} />
                    ) : (
                      <div className="">
                        <p>There is currently no data available for customer</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerScreen;

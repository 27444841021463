import React, { useState } from "react";
import Sidenav from "../component/layout/Sidenav";
import DashboardNavbar from "../component/layout/DashboardNavbar";
import Footer from "../component/layout/Footer";
import routes, { routex } from "../Routes/routes";
import { Routes, Route, useParams, useNavigate, } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import Logo from "../Assets/images/logo.png";
import { useSelector } from "react-redux";
import { IStore } from "../state/app.reducer";
import $service from "../services/app.service";
import { toast } from "react-toastify";

interface ITechnicianDiagnosisForm {
  questionaire?: { HasDeviceBeenTamperedWith: boolean; MissingParts: string };
  jobcardId?: string;
  approver?: string;
  remarks: string;
  HasDeviceBeenTamperedWith: boolean;
  MissingParts: string;
}

function TechnicianDiagnosis() {
  const { _id } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((reducer: IStore) => reducer.state);
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<ITechnicianDiagnosisForm>({
    remarks: "",
    HasDeviceBeenTamperedWith: false,
    MissingParts: "",
  });

  const setFormValue = (key: string, value: any) => {
    setForm({ ...form, [key]: value });
  };

  const submit = async () => {
    try {
      const { remarks, MissingParts, HasDeviceBeenTamperedWith } = form;
      if (MissingParts === "") {
        throw Error("Please anwer missing device parts");
      }
      const payload: Partial<ITechnicianDiagnosisForm> = {
        jobcardId: _id,
        remarks,
        approver: user._id,
        questionaire: { HasDeviceBeenTamperedWith, MissingParts },
      };
      setLoading(true);
      const response = await $service.addDiagnosis!(payload);
      if (!response.success) {
        throw Error(response.message);
      }
      setLoading(false);
      navigate(-1);
      toast(response.message);
    } catch (err) {
      setLoading(false);
      toast((err as Error)?.message);
    }
  };

  const { roles }=useSelector((reducer: any)=>reducer.state);
  const getMenu = () => {
    try {
      return roles.menu ?  routex[roles.menu] : []
    }
    catch (err) {
      return []
    }
  }

  return (
    <div className="min-h-screen py-5 bg-blue-gray-50/50">
      <Sidenav routex={getMenu()} brandImg={Logo} />
      <div className="p-4 xl:ml-80">
        <DashboardNavbar />
        <Routes>
          {getMenu().map(
            ({ pages, layout }:any) =>
              layout === "katcare-developer" &&
              pages.map(({ path, element }:any) => (
                <Route path={path} element={element} key={path} />
              ))
          )}
        </Routes>
        <div className="mt-12">
          <div>
            <Card className="mx-10 my-5">
              <CardBody>
                <div className="my-5">
                  <Typography variant="h4">
                    Technical Diagnosis Check{" "}
                  </Typography>
                </div>
                <form>
                  <div className="mb-2 ">
                    <Typography className="text-sm font-semibold">
                      Has the device been tampered with? (see warranty seal or
                      physically )
                    </Typography>
                    <>
                      <Checkbox
                        disabled={loading}
                        label="Yes"
                        checked={
                          form.HasDeviceBeenTamperedWith === true ? true : false
                        }
                        onChange={(e) => {
                          setFormValue("HasDeviceBeenTamperedWith", true);
                        }}
                      />
                      <Checkbox
                        disabled={loading}
                        label="No"
                        checked={
                          form.HasDeviceBeenTamperedWith === false
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setFormValue("HasDeviceBeenTamperedWith", false);
                        }}
                      />
                    </>
                  </div>
                  <div className="mb-2">
                    <Typography className="text-sm font-semibold">
                      Missing Parts
                    </Typography>
                    <Textarea
                      disabled={loading}
                      onChange={(e) =>
                        setFormValue("MissingParts", e.target.value)
                      }
                      name="Overall assessment"
                    />
                  </div>
                  <div className="mb-2">
                    <Typography className="text-sm font-semibold">
                      Technician Assessment
                    </Typography>
                    <Textarea
                      disabled={loading}
                      name="Overall assessment"
                      onChange={(e) => setFormValue("remarks", e.target.value)}
                    />
                  </div>
                  <Button
                    disabled={loading}
                    variant="filled"
                    color="blue"
                    className="w-full mt-2"
                    onClick={submit}
                  >
                    Save
                  </Button>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="text-blue-gray-600">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default TechnicianDiagnosis;
